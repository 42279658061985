import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useTheme } from '@emotion/react';
import { Typography, Grid, Button, TextField } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

export default function CheckoutForm(props) {
const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState("");
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState("");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const styles = {
    titleContainerTop: {
        fontWeight: "bold",
        fontSize: '20px',
        marginBottom: "1rem",
    },
    titleContainer: {
        fontWeight: "bold",
        fontSize: '20px',
        marginBottom: "1rem",
        marginTop: "2rem"
    },
    resumeContainer: {
        boxShadow: "0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07)",
        borderRadius: "7px",
        padding: "2rem"
    },
    orderButton: {
      color: "white",
      padding: "10px 25px",
      backgroundColor: theme.palette.colors.green,
      borderRadius: "25px!important",
      fontWeight: "bold",
      marginTop: "1rem",
      "&:hover": {
          backgroundColor: "black",
          color: theme.palette.colors.green
      }
    },
    buyContainer: {
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "white",
      boxShadow: "0 0 10px 0 rgba(0,0,0,0.25)",
      padding: "1rem",
      zIndex: "10"
    },
    paymentForm: {
      width: "100%",
      [theme.breakpoints.up('md')]: {
        minWidth: "500px",
        alignSelf: "center",
        boxShadow: "0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07)",
        borderRadius: "7px",
        padding: "40px",
      },
    },
    checkoutInput: {
      marginBottom: "1rem",
      "& .MuiInputBase-input": {
        fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
        padding: "14px",
        fontSize: "16px",
      },
      "& #outlined-basic-helper-text": {
        fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
        fontSize: "16px!important",
        fontWeight: "normal",
        color: "#df1b41",
        marginLeft: "0!important",
      }
    },
    securedPaymentContainer: {
      marginTop: "1rem",
  },
  securedPayment: {
      fontSize: "12px",
      color: "black",
      display: "inline"
  },
  securedPaymentGreen: {
      fontSize: "12px",
      display: "inline",
      color: theme.palette.colors.green
  },
  lockSecure: {
      height: "13px",
  },
  withdrayContainer: {
      marginTop: "1rem"
  },
  withdrayText: {
      fontSize: "14px"
  },
  withdrayIconInfo: {
      height: "14px"
  }
  }


  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    let valueEmtpy = false;

    if (name === "") {
      setNameError("Ce champ est nécessaire");
      valueEmtpy = true;
    }

    if (email === "") {
      setEmailError("Ce champ est nécessaire");
      valueEmtpy = true;
    }

    if (valueEmtpy) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: (process.env.REACT_APP_ENV === "dev" ? "http" : "https") + "://boutique." + process.env.REACT_APP_DOMAIN + "/orderSucces",
        payment_method_data : {
          billing_details : {
            name : name,
            email: email,
          }
        }
      }
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      console.log(error);
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: {
        type: "accordion",
        radios: false
    }
  }

  function checkInput(type, value) {
    const mailRegex = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$");
    if (type === "email") {
      value === "" ? setEmailError("Ce champ est nécessaire") : (!mailRegex.test(email)) ? setEmailError("Veuillez saisir une adresse e-mail valide.") : setEmailError("");
    } else if (type === "name") {
      value === "" ? setNameError("Ce champ est nécessaire") : setNameError("");
    }
  }

  return (
    <Grid container item justifyContent={"center"}>
      <form id="payment-form" style={styles.paymentForm} onSubmit={handleSubmit}>
        <Grid container item justifyContent={props.dimensions <= 900 ? "center" : "space-between"} alignItems={"flex-start"}>
          <Grid container item xs={10} sm={7} md={6} direction={"column"}>
            <Typography sx={styles.titleContainerTop}>Contact Information</Typography>
            <TextField sx={styles.checkoutInput} id="outlined-basic" autoComplete="name" label="Nom complet" variant="outlined" helperText={nameError} onChange={(e) => {setName(e.target.value)}} onBlur={(e) => {checkInput("name", e.target.value)}}/>
            <TextField sx={styles.checkoutInput} id="outlined-basic" autoComplete="email" label="E-mail" variant="outlined" helperText={emailError} onChange={(e) => {setEmail(e.target.value)}} onBlur={(e) => {checkInput("email", e.target.value)}}/>

            <Typography sx={styles.titleContainer}>Moyen de paiement</Typography>
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            {message && <div id="payment-message">{message}</div>}
          </Grid>

          { props.dimensions > 900 &&
          <Grid container item direction={"column"} xs={4} alignItems={"center"}>
            <Grid container item direction={"column"} xs={4} sx={styles.resumeContainer} justifyContent={"flex-start"} alignItems={"center"}>
              <Grid container item justifyContent={"space-between"}>
                <Grid item>
                  <Typography sx={styles.titleContainerTop}>Total</Typography>
                </Grid>
                <Grid item>
                  <Typography sx={styles.titleContainerTop}>{parseFloat(props.shopSession.total).toFixed(2)} €</Typography>
                </Grid>
              </Grid>

              <Grid item sx={styles.withdrayContainer}>
                <Typography sx={styles.withdrayText}><InfoIcon sx={styles.withdrayIconInfo}/>Retrait au complexe sportif les dimanches lors des matchs à domicile</Typography>
              </Grid>

              <Grid container item justifyContent={"center"}>
                <Button fullWidth sx={styles.orderButton} disabled={isLoading || !stripe || !elements} type={"submit"}>
                  {isLoading ? <div className="spinner" id="spinner"></div> : "Payer"}
                </Button>
              </Grid>
            </Grid>
            <Grid item sx={styles.securedPaymentContainer}>
              <Typography sx={styles.securedPayment}><img style={styles.lockSecure} alt={"secure-payment"} src={process.env.PUBLIC_URL + "/images/safe.png"} /> Paiement <Typography sx={styles.securedPaymentGreen}>sécurisé</Typography></Typography>
            </Grid>
          </Grid>
          }
        </Grid>
        
        { props.dimensions <= 900 &&
        <Grid container item direction={"column"} alignItems={"center"}>
          <Grid container sx={styles.buyContainer} direction={"column"}>
            <Grid container item justifyContent={"space-between"} alignItems={"center"}>
              <Grid container item direction={"column"} xs={6}>
                <Grid item>
                  <Typography sx={styles.detailItem}>Total:</Typography>
                </Grid>
                <Grid item>
                  <Typography sx={styles.priceProductSmall}>{parseFloat(props.shopSession.total).toFixed(2)} €</Typography>
                </Grid>
              </Grid>

              <Grid item sx={styles.withdrayContainer}>
                <Typography sx={styles.withdrayText}><InfoIcon sx={styles.withdrayIconInfo}/>Retrait au complexe sportif les dimanches lors des matchs à domicile</Typography>
              </Grid>

              <Grid item>
                <Button fullWidth sx={styles.orderButton} disabled={isLoading || !stripe || !elements} type={"submit"}>
                  {isLoading ? <div className="spinner" id="spinner"></div> : "Payer"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={styles.securedPaymentContainer}>
            <Typography sx={styles.securedPayment}><img style={styles.lockSecure} alt={"secure-payment"} src={process.env.PUBLIC_URL + "/images/safe.png"} /> Paiement <Typography sx={styles.securedPaymentGreen}>sécurisé</Typography></Typography>
          </Grid>
        </Grid>
        }
        </form>
    </Grid>

  );
}

