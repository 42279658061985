import React from 'react';
import { Fade } from 'react-slideshow-image';

import "./Article.scss"
import "../../assets/LastArticle.scss";
import { Grid, Typography, useTheme } from "@mui/material";
import moment from "moment";

const fadeProperties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
};

export default function Article(props) {
    const theme = useTheme();
    const date = moment(props.article.date).format("DD MMMM YYYY");
    const hour = moment(props.article.date).format("HH:mm");

    const styles = {        
        article: {
          fontSize: "1rem",
        },
    };

    function buildSlide() {
        let listSlide = [];
        for (let i = 0; i < props.articlePic.length; i++){
            listSlide.push(
                <div className="each-fade" key={i}>
                    <div className="image-container">
                        <img src={process.env.REACT_APP_API_URL + props.articlePic[i].picture}/>
                        <h2>{props.articlePic[i].description}</h2>
                    </div>
                </div>);
        }
        return (listSlide);
    }

    function slideShow() {
        if (props.articlePic.length > 1){
            return (
                <div style={{maxWidth: "100%"}}>
                <Fade {...fadeProperties}>
                    {buildSlide()}
                </Fade>
                </div>);
        }
        else if (props.articlePic.length !== 0){
            return (
                <div className="image-container solo">
                    <img src={process.env.REACT_APP_API_URL + props.articlePic[0].picture} alt={"lastArticle picture"}/>
                    <h2>{props.articlePic[0].description}</h2>
                </div>)
        }
    }

    function buildArticleContent(article, articleNb) {
        let tmp = [];
        if (article.article !== null)
            tmp = article.article.split('\n').map((item, i) => {
                return <p style={styles.article} key={i}>{item}</p>;
            })
        return tmp;
    }

    return (
        <Grid container className={"article-container"} sx={{backgroundColor: theme.palette.colors.whiteBox }} direction={"column"}>
            <Grid item sx={{marginBottom: "1rem"}}>
                <Typography className={"title"} variant={"h4"}>{props.article.title}</Typography>
            </Grid>
            <Grid container item direction={"row"} justifyContent={"center"} alignItems={"center"}>
                <Grid item xs={8}>
                    {slideShow()}
                </Grid>
            </Grid>
            <Grid item>
                {buildArticleContent(props.article)}
            </Grid>
            <Grid item container justifyContent={"flex-end"}>
                <Grid item className={"date"} sx={{ color: theme.palette.colors.green }}>Publié le {date} à {hour}</Grid>
            </Grid>
        </Grid>
     );
}
