import { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Grid, Typography, Box, TextField, InputAdornment, Button } from "@mui/material";
import { useTheme } from '@emotion/react';
import ReactGA from "react-ga4";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import Spinner from "../../component/Spinner/Spinner";
import InfoIcon from '@mui/icons-material/Info';

import ApiClient from "../../service/ApiClient";

export default function Cart(props) {
    const theme = useTheme();
    const [cart, setCart] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const styles = {
        title: {
            fontSize: "50px",
            fontWeight: "bold",
            fontFamily: "Helvetica",
            marginBottom: "2rem"
        },
        boldText: {
            fontWeight: "bold",
            fontSize: "14px"
        },
        boldTextResume: {
            fontWeight: "bold",
            fontSize: "16px"
        },
        greyText: {
            color: "#878787",
            fontSize: "14px"
        },
        removeCart: {
            color: "#878787",
            fontSize: "14px",
            cursor: "pointer",
            textDecoration: "underline",
            "&:hover": {
                textDecoration: "none"
            }
        },
        cartContainer: {
            backgroundColor: "#F7F7F7",
            padding: "30px 20px",
            marginBottom: "1rem"
        },
        resumeContainer: {
            backgroundColor: "#F7F7F7",
            padding: "20px 30px",
        },
        titleItem: {
            fontSize: "14px",
            color: "black",
            textDecoration: "none",
            "&:hover": {
                color: "black"
            }
        },
        detailItem: {
            fontSize: "12px",
            color: "#878787",
        },
        priceItem: {
            fontSize: "14px",
            color: "#878787",
            fontWeight: "bold",
            margin: "1rem 0",
            [theme.breakpoints.up('md')]: {
                margin: 0,
            }
        },
        totalPrice: {
            color: theme.palette.colors.green,
            fontWeight: "bold",
            fontSize: "20px",
        },
        enabledIcon: {
            color: theme.palette.colors.green,
            cursor: "pointer",
        },
        removeIcon: {
            color: theme.palette.colors.green,
            cursor: "pointer",
            "&:hover": {
                color: "red"
            }
        },
        overlayContainer: {
            position: "fixed",
            top: 0,
            left: 0,
            right:0,
            bottom:0,
        },
        overlay: {
            width: "100%",
            height: "100%",
            backgroundColor: "#878787",
            opacity: "50%",
            
        },
        spinner: {
            position: "fixed",
            top: 0,
            left: 0,
            right:0,
            bottom:0,
        },
        orderButton: {
            color: "white",
            padding: "10px 25px",
            backgroundColor: theme.palette.colors.green,
            borderRadius: "25px!important",
            fontWeight: "bold",
            marginTop: "1rem",
            "&:hover": {
                backgroundColor: "black",
                color: theme.palette.colors.green
            }
        },
        emptyCartIcon: {
            height: "45px",
            margin: "1rem"
        },
        emptyCartText: {
            fontSize: "18px",
            fontWeight: "bold",
            margin: "1rem"
        },
        continueShopText: {
            textDecoration: "underline",
            cursor: "pointer",
            color: "black",
            "&:hover": {
                color: "black",
                textDecoration: "none",
            }
        },
        imageContainer: {
            height: "80px", 
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        buyContainer: {
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "white",
            boxShadow: "0 0 10px 0 rgba(0,0,0,0.25)",
            padding: "1rem",
            zIndex: "10"
        },
        priceProductSmall: {
            fontWeight: "bold",
            color: theme.palette.colors.green
        },
        securedPaymentContainer: {
            marginTop: "1rem",
        },
        securedPayment: {
            fontSize: "12px",
            color: "black",
            display: "inline"
        },
        securedPaymentGreen: {
            fontSize: "12px",
            display: "inline",
            color: theme.palette.colors.green
        },
        lockSecure: {
            height: "13px",
        },
        withdrayContainer: {
            marginTop: "1rem"
        },
        withdrayText: {
            fontSize: "14px"
        },
        withdrayIconInfo: {
            height: "14px"
        }
    }

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        (async () => {
            try {
                setIsLoading(true);
                await loadData();
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    async function loadData() {
        try {
            if (localStorage.getItem('shop_session') !== null) {
                let result = await ApiClient.instance.getShopSession(localStorage.getItem('shop_session'));

                if (result.data !== null) {
                    setCart(result.data);
                    props.handleCartIcon(result.data.products.length);
                } else {
                    setCart(null);
                    localStorage.removeItem('shop_session');
                    props.handleCartIcon(0);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function handleRemoveItem(index, removeAll=false) {
        try {
            setIsLoading(true);
            let tmpCart = {...cart};

            if (tmpCart.products[index].quantity === 1 || removeAll) {
                tmpCart.products.splice(index, 1);
            } else {
                tmpCart.products[index].quantity -= 1;
            }
            if (tmpCart.products.length === 0) {
                await ApiClient.instance.deleteShopSession(cart.id);
            } else {
                await ApiClient.instance.updateShopSession(cart.id, tmpCart);
            }
            await loadData();
            setIsLoading(false);
        } catch (e) {
            console.log(e);
        }
    }

    async function handleAddItem(index) {
        try {
            setIsLoading(true);
            let tmpCart = {...cart};
            tmpCart.products[index].quantity += 1;
            let result = await ApiClient.instance.updateShopSession(cart.id, tmpCart);
            await loadData();
            setIsLoading(false);
        } catch (e) {
            console.log(e);
        }
    }

    async function handleChangeQuantity(index, e) {
        try {
            setIsLoading(true);
            let tmpCart = {...cart};
            tmpCart.products[index].quantity = parseInt(e.target.value === "" || e.target.value === "0"  ? 1 : e.target.value);
            await ApiClient.instance.updateShopSession(cart.id, tmpCart);
            await loadData();
            setIsLoading(false);
        } catch (e) {
            console.log(e);
        }
    }

    function buildProductCart() {
        let row = [];

        for (let i = 0; i < cart.products.length; i++) {
            row.push(
                <Grid container item sx={styles.cartContainer} justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item xs={1.5} sx={styles.imageContainer} to={"/produit/" +  cart.products[i].path} component={RouterLink}>
                        <img style={{maxHeight: "100%", maxWidth: "100%"}} alt="article" src={process.env.REACT_APP_API_URL + cart.products[i].firstPicture}/>
                    </Grid>

                    <Grid container item direction={"column"} xs={10} md={3}>
                        <Grid item>
                            <Typography sx={styles.titleItem} to={"/produit/" +  cart.products[i].path} component={RouterLink}>{cart.products[i].name}</Typography>
                        </Grid>

                        <Grid item>
                            <Typography sx={styles.detailItem}>Taille: {cart.products[i].size}</Typography>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} md={2} justifyContent={props.dimensions <= 900 ? "flex-start" : "center"}>
                        <Grid item>
                            <Typography sx={styles.priceItem}>{parseFloat(cart.products[i].price).toFixed(2)} €</Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={5} sm={3}>
                    <TextField
                        fullWidth
                        sx={{width: "100%!important", input: {textAlign: "center", fontWeight: "bold", color: theme.palette.colors.green, fontSize: "14px"}}}
                        id="standard-start-adornment"
                        value={cart.products[i].quantity}
                        InputLabelProps={{style: {color: theme.palette.colors.green}}}
                        InputProps={{
                            startAdornment: <InputAdornment position="start" sx={styles.enabledIcon}><RemoveCircleIcon sx={{fontSize: "20px"}} onClick={() => {handleRemoveItem(i)}}/></InputAdornment>,
                            endAdornment: <InputAdornment position="end" sx={styles.enabledIcon}><AddCircleIcon sx={{fontSize: "20px"}} onClick={() => {handleAddItem(i)}}/></InputAdornment>,
                        }}
                        onChange={(event) => {handleChangeQuantity(i, event)}}
                    />
                    </Grid>

                    <Grid container item xs md={2} justifyContent={props.dimensions <= 900 ? "flex-start" : "center"}>
                        <Grid sx={{marginLeft: "1rem"}} item>
                            <Typography sx={styles.priceItem}>{parseFloat(cart.products[i].price * cart.products[i].quantity).toFixed(2)} €</Typography>
                        </Grid>
                    </Grid>

                    <Grid container item xs={0.5} justifyContent="center">
                        <Grid item>
                            <CloseIcon sx={styles.removeIcon} onClick={() => {handleRemoveItem(i, true)}}/>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }

        return (row);
    }

    return (
        <Grid container item direction={"column"} alignItems={"center"}>
            {cart !== null && 
            <Grid item>
                <Typography sx={styles.title}>Votre panier</Typography>
            </Grid>
            }

            { cart === null && !isLoading && 
            <Grid container item justifyContent={"center"}>
                <Grid container item direction={"column"} sx={styles.emptyCartContainer} xs={6} alignItems={"center"}>
                    <Grid item >
                        <img style={styles.emptyCartIcon} alt={"emptyCart"} src={process.env.PUBLIC_URL + "/images/emptyCart.png"} />
                    </Grid>
                    <Grid item>
                        <Typography sx={styles.emptyCartText}>Votre panier est vide</Typography>
                    </Grid>

                    <Grid item>
                        <Typography sx={styles.continueShopText} to={"/"} component={RouterLink}>Continuer mes achats</Typography>
                    </Grid>
                </Grid>
            </Grid>
            }

            {cart !== null && 
            <Grid container item justifyContent={props.dimensions <= 900 ? "center" : "space-evenly"} alignItems={"flex-start"} >
                <Grid container item direction={"column"} xs={11.5} md={8.5}>
                    <Grid container item justifyContent={"space-between"} sx={{marginBottom: "1rem"}}>
                        <Grid item>
                            <Typography sx={styles.boldText}>{cart.products.length} { cart.products.length > 1 ? "produits" : "produit"} {props.dimensions > 900 && <Typography sx={styles.greyText} display={"inline"}> dans votre panier</Typography>}</Typography>
                        </Grid>

                        <Grid item>
                            <Typography sx={styles.removeCart} onClick={async () => {
                                setIsLoading(true);
                                await ApiClient.instance.deleteShopSession(cart.id);
                                await loadData();
                                setIsLoading(false);
                                }}>Vider le panier panier</Typography>
                        </Grid>
                    </Grid>

                    {buildProductCart()}
                </Grid>

                { props.dimensions > 900 &&
                <Grid container item direction={"column"} xs={3} alignItems={"center"}>
                    <Grid container item sx={styles.resumeContainer} direction={"column"} xs={3} alignItems={"center"}>
                        <Grid item sx={{marginBottom: "1rem"}}>
                            <Typography sx={styles.boldTextResume}>Résumé</Typography>
                        </Grid>

                        <Grid item>
                            <Typography sx={styles.detailItem}>Total: <Typography display={"inline"} sx={styles.totalPrice}>{parseFloat(cart.total).toFixed(2)} €</Typography></Typography>
                        </Grid>

                        <Grid item sx={styles.withdrayContainer}>
                            <Typography sx={styles.withdrayText}><InfoIcon sx={styles.withdrayIconInfo}/>Retrait au complexe sportif les dimanches lors des matchs à domicile</Typography>
                        </Grid>

                        <Grid item>
                            <Button fullWidth sx={styles.orderButton} onClick={() =>  {navigate('/checkout')}}>
                                Commander
                            </Button>
                        </Grid>                        
                    </Grid>

                    <Grid item sx={styles.securedPaymentContainer}>
                        <Typography sx={styles.securedPayment}><img style={styles.lockSecure} alt={"secure-payment"} src={process.env.PUBLIC_URL + "/images/safe.png"} /> Paiement <Typography sx={styles.securedPaymentGreen}>sécurisé</Typography></Typography>
                    </Grid>
                </Grid>
                }

                { props.dimensions <= 900 &&
                <Grid container item direction={"column"} alignItems={"center"}>
                    <Grid container sx={styles.buyContainer} direction={"column"}>
                        <Grid container item justifyContent={"space-between"} alignItems={"center"}>
                            <Grid container item direction={"column"} xs={6}>
                                <Grid item>
                                    <Typography sx={styles.detailItem}>Total:</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography sx={styles.priceProductSmall}>{parseFloat(cart.total).toFixed(2)} €</Typography>
                                </Grid>
                            </Grid>

                            <Grid item sx={styles.withdrayContainer}>
                                <Typography sx={styles.withdrayText}><InfoIcon sx={styles.withdrayIconInfo}/>Retrait au complexe sportif les dimanches lors des matchs à domicile</Typography>
                            </Grid>

                            <Grid item>
                                <Button fullWidth sx={styles.orderButton} onClick={() =>  {navigate('/checkout')}}>
                                    Commander
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sx={styles.securedPaymentContainer} xs>
                        <Typography sx={styles.securedPayment}><img style={styles.lockSecure} alt={"secure-payment"} src={process.env.PUBLIC_URL + "/images/safe.png"} /> Paiement <Typography sx={styles.securedPaymentGreen}>sécurisé</Typography></Typography>
                    </Grid>
                </Grid>
                }
            </Grid>
            }

            { isLoading && 
            <Box sx={styles.overlayContainer}>
                <Box sx={styles.overlay}>

                </Box>
                <Grid container sx={styles.spinner} justifyContent={"center"} alignItems={"center"}>
                    <Grid item>
                        <Spinner />
                    </Grid>
                </Grid>
                
            </Box>
            }
        </Grid>
    )
}