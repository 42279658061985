import React, { useEffect, useState } from 'react';

import { Grid, Typography, Box, useTheme } from "@mui/material";

import AddPartenairePopup from "./addPartenairePopup";
import { Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle } from "../../component/Popup/Popup";
import UpdatePartenairePopup from "./updatePartenairePopup";
import ApiClient from "../../service/ApiClient";

import ReactGA from "react-ga4";

export default function Partenaire(props) {
    const theme = useTheme();
    const [partners, setPartners] = useState(null);
    const [actualPartner, setActualPartner] = useState("");
    const [actualId, setActualId] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [confirmPopup, setConfirmPopup] = useState(false);

    const styles = {
        addPartner: {
            position: "fixed",
            bottom: "75px",
            right: "0px",
            alignItems: "center",
            display: "block",
            '& button': {
                backgroundColor: "transparent",
                border: "none"
            },
            '& i': {
                color: theme.palette.colors.green,
                fontSize: "60px"
            }
        },
        actionButton: {
            color: theme.palette.colors.green,
            border: "none",
            backgroundColor: "rgba(255, 255, 128, 0)",
            paddingTop: "5px",
            '&:hover': {
                color: "white"
            },
        },
    };

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        (async () => {
            try {
                await loadData();
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    async function loadData() {
        let result = await ApiClient.instance.getPartner();
        setPartners(result.data);
    }

    function closePopup(type) {
        if (type === "PopupModify")
            setActualPartner("");
        else
            setShowPopup(false);
    }

    async function addPartner(partner) {
         try {
             await ApiClient.instance.postPartner(partner);
             props.createNotification("success", {title: "Succès", message: "Partenaire créé", timeout: 3000});
             await loadData();
             closePopup();
         } catch (e) {
             console.log(e);
             props.createNotification("error", {title: "Erreur", message: "Partenaire non créé", timeout: 3000});
         }

    }

    async function modifyPartner(id, partner) {
        try {
            await ApiClient.instance.updatePartner(id, partner);
            props.createNotification("success", {title: "Succès", message: "Partenaire mis à jour", timeout: 3000});
            await loadData();
            closePopup("PopupModify");
        } catch (e) {
            props.createNotification("error", {title: "Erreur", message: "Partenaire non mis à jour", timeout: 3000});
            console.log(e);
        }

    }

    function buildPartnerCard(partner, index) {
        let encodedUri = encodeURI(process.env.REACT_APP_API_URL + partner.Picture).replaceAll("'", "\\'").replaceAll("(", "\\(").replaceAll(")", "\\)");
        if (window.innerWidth <= 960)
            return (
                <Grid container direction={"column"} item xs={4} style={{margin: "1rem"}} alignItems={"center"}>
                    {props.isAdmin &&
                    <Grid item>
                        <button style={styles.actionButton}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setConfirmPopup(true);
                                    setActualId(partner.Id);
                                }}><i className="material-icons">delete</i></button>
                        <button style={styles.actionButton}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setActualPartner(partner);
                                }}><i className="material-icons">edit</i></button>
                    </Grid>}
                    <Grid style={{
                        background: "url(" + encodedUri + ")", backgroundPosition: 'center',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat', height: "100px", width: "100%"}}/>
                        <Grid item>
                            <Typography variant={"subtitle1"}>{partner.Title}</Typography>
                        </Grid>
                </Grid>
            )
        else
            return (
                <Grid container direction={"column"} item xs={3} style={{margin: "10px"}} alignItems={"center"} key={partner.Id}>
                    {props.isAdmin &&
                    <Grid item>
                        <button style={styles.actionButton}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setConfirmPopup(true);
                                    setActualId(partner.Id);
                                }}><i className="material-icons">delete</i></button>
                        <button style={styles.actionButton}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setActualPartner(partner);
                                }}><i className="material-icons">edit</i></button>
                    </Grid>}
                    <Grid style={{
                        background: "url(" + encodedUri + ")", backgroundPosition: 'center',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat', height: "175px", width: "100%", marginBottom: "10px"}}/>
                    <Grid item>
                        <Typography variant={"subtitle1"}>{partner.Title}</Typography>
                    </Grid>
                </Grid>
            )
    }

    function buildRow() {
        let row = [];
        let tabPartner = [];
        for (let i = 0; i < partners.length; i++) {
            tabPartner.push(buildPartnerCard(partners[i], i));
        }
        row.push(<Grid container justifyContent={"space-between"}>{tabPartner}</Grid>);
        return row;
    }

    if (partners == null)
        return (<Box/>)
    return (
        <Grid container direction={"column"} alignItems={"center"} justifyContent={"space-between"}>
            {buildRow()}
            {showPopup && <AddPartenairePopup close={closePopup} add={addPartner}/>}
            {actualPartner !== "" && <UpdatePartenairePopup partner={actualPartner} close={closePopup} modify={modifyPartner}/>}
            {confirmPopup &&
            <Popup close={() => {
                setConfirmPopup(false);
            }}>
                <PopupHeader>
                    <PopupTitle>Supprimer un partenaire</PopupTitle>
                </PopupHeader>
                <PopupBody>
                    <p>Voulez-vous vraiment supprimer ce partenaire ?</p>
                </PopupBody>
                <PopupFooter dismiss={"Non"} close={() => {
                    setConfirmPopup(false);
                }}>
                    <button className={"btn btn-primary"} onClick={async () => {
                        try {
                            await ApiClient.instance.deletePartner(actualId);
                            props.createNotification("success", {title: "Succès", message: "Partenaire supprimé", timeout: 3000});
                            setConfirmPopup(false);
                            await loadData();
                        } catch (e) {
                            console.log(e);
                            props.createNotification("error", {title: "Erreur", message: "Partenaire non supprimé", timeout: 3000});
                        }
                    }}>Oui
                    </button>
                </PopupFooter>
            </Popup>
            }
            {props.isAdmin &&
            <Box sx={styles.addPartner}>
                <button className={"no-button"} onClick={() => {
                    setShowPopup(true);
                }}>
                    <i className="material-icons">add_circle</i>
                </button>
            </Box>}
        </Grid>)

}
