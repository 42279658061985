import React, { useState, useEffect } from 'react';
import {
    FilledInput,
    FormControl, FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Typography
 } from "@mui/material";

import ApiClient from "../../service/ApiClient";
import ReactGA from "react-ga4";

import '../../assets/Inscription.scss'
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useParams} from "react-router-dom";

export default function ChangePassword(props) {
    const params = useParams();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [visibilityPassword, setVisibilityPassword] = useState(false);
    const [visibilityConfirmPassword, setVisibilityConfirmPassword] = useState(false);
    const [isPasswordLong, setIsPasswordLong] = useState(false);
    const [isPasswordUppercase, setIsPasswordUppercase] = useState(false);
    const [isPasswordNumber, setIsPasswordNumber] = useState(false);
    const [isPasswordChanged, setIsPasswordChanged] = useState(false);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    async function handleSubmitChangePassword() {
        try {
            !isPasswordLong || !isPasswordNumber || !isPasswordUppercase ? setPasswordError("Votre mot de passe ne répond pas au critère de sécurité") : setPasswordError("");
            password !== confirmPassword ? setConfirmPasswordError("Les mots de passes ne sont pas identique") : setConfirmPasswordError("");
            if (password !== "" && passwordError === "" && confirmPasswordError === "") {
                let data = {
                    Token: params.token,
                    Password: password
                }
                await ApiClient.instance.changePassword(data);
                props.createNotification("success", {title: "Succès", message: "Votre compte a bien été créé", timeout: 3000})
               setIsPasswordChanged(true);
            }
        } catch (err) {
            props.createNotification("error", {title: "Erreur", message: "Lien de changement de mot de passe incorrect", timeout: 3000});
        }
    }

    function handleChangePassword(value) {
        value.length >= 8 ? setIsPasswordLong(true) : setIsPasswordLong(false);
        /\d/.test(value) ? setIsPasswordNumber(true) : setIsPasswordNumber(false);
        /[A-Z]/.test(value) ? setIsPasswordUppercase(true) : setIsPasswordUppercase(false);
        setPassword(value);
    }

    if (isPasswordChanged) {
        return (
            <Grid container direction={"row"} justifyContent={"center"} alignItems={"center"} style={{height: "100%"}}>
                <Grid className={"register-container"} container item direction={"column"} justifyContent={"space-between"} alignItems={"center"} xs={11} md={9} lg={6}>
                    <Grid className={"register-title"} item><Typography variant={"h4"} style={{marginLeft: "0"}}>Nouveau mot de passe</Typography></Grid>
                    <Grid className={"auth-register"} item>
                        <Typography variant={"h5"}>
                            Votre mot de passe a bien été changé. <a href={"/connexion"}>Revenir à la page de connexion</a>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid container direction={"row"} justifyContent={"center"} alignItems={"center"} style={{height: "100%"}}>
                <Grid className={"register-container"} container item direction={"column"} justifyContent={"space-between"} alignItems={"center"} xs={11} md={9} lg={6}>
                    <Grid className={"register-title"} item><Typography variant={"h4"} style={{marginLeft: "0"}}>Nouveau mot de passe</Typography></Grid>
                    <Grid className={"register-input"} container item justifyContent={"center"}>
                        <Grid item xs={6}>
                            <FormControl variant="filled" fullWidth helperText={passwordError}>
                                <InputLabel required htmlFor="filled-adornment-password">Nouveau mot de passe</InputLabel>
                                <FilledInput
                                    id="filled-adornment-password"
                                    type={visibilityPassword ? 'text' : 'password'}
                                    value={password}
                                    helperText={passwordError}
                                    onChange={(event) => { handleChangePassword(event.target.value) }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => { setVisibilityPassword(!visibilityPassword)}}
                                                onMouseDown={(event) => { event.preventDefault() }}
                                            >
                                                {visibilityPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>{passwordError}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <ul className={"password-requirement-container"}>
                        <li className={"password-requirement-title"}>Votre mot de passe doit contenir :</li>
                        <li className={"password-requirement-item-" + (isPasswordLong ? "valid" : "invalid")}>
                        <span className={"check-container"}>
                            <svg role="presentation" width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            { isPasswordLong ?
                                <path className="check-valid" d="M10.27.317l1.46 1.366-7.21 7.702L.346 5.777l1.308-1.513 2.72 2.35L10.27.317z" stroke-width="2"></path>
                                :
                                <path className="check-invalid" d="M1 1l10 10M11 1L1 11" stroke-width="2"></path> }
                            </svg>
                        </span>
                            Au minimum 8 caractères</li>
                        <li className={"password-requirement-item-" + (isPasswordUppercase ? "valid" : "invalid")}>
                        <span className={"check-container"}>
                        <svg role="presentation" width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            { isPasswordUppercase ?
                                <path className="check-valid" d="M10.27.317l1.46 1.366-7.21 7.702L.346 5.777l1.308-1.513 2.72 2.35L10.27.317z" stroke-width="2"></path>
                                :
                                <path className="check-invalid" d="M1 1l10 10M11 1L1 11" stroke-width="2"></path> }
                        </svg>
                    </span>
                            Une majuscule</li>
                        <li className={"password-requirement-item-" + (isPasswordNumber ? "valid" : "invalid")}>
                        <span className={"check-container"}>
                        <svg role="presentation" width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            { isPasswordNumber ?
                                <path className="check-valid" d="M10.27.317l1.46 1.366-7.21 7.702L.346 5.777l1.308-1.513 2.72 2.35L10.27.317z" stroke-width="2"></path>
                                :
                                <path className="check-invalid" d="M1 1l10 10M11 1L1 11" stroke-width="2"></path> }
                        </svg>
                    </span>
                            Un nombre</li>
                    </ul>
                    <Grid className={"register-input"} container item justifyContent={"center"}>
                        <Grid item xs={6}>
                            <FormControl variant="filled" fullWidth helperText={confirmPasswordError}>
                                <InputLabel htmlFor="filled-adornment-password" required>Confirmer nouveau mot de passe</InputLabel>
                                <FilledInput
                                    id="filled-adornment-password"
                                    type={visibilityConfirmPassword ? 'text' : 'password'}
                                    value={confirmPassword}
                                    onChange={(event) => { setConfirmPassword(event.target.value) }}
                                    helperText={confirmPasswordError}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => { setVisibilityConfirmPassword(!visibilityConfirmPassword)}}
                                                onMouseDown={(event) => { event.preventDefault() }}
                                            >
                                                {visibilityConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>{confirmPasswordError}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid className={"register-button"} item>
                        <button onClick={handleSubmitChangePassword} className="btn btn-primary"><Typography variant={"h5"}>Changer mot de passe</Typography></button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
