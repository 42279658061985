import React, {useState} from "react";
import 'moment/locale/fr';
import { useTheme } from '@emotion/react';
import { TextField, Grid  } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle } from "../../component/Popup/Popup";

export default function AddUpdateProductPopup(props) {
    const theme = useTheme();
    const [article, setArticle] = useState(props.article ?
        {
            id: props.article.id,
            name: props.article.name,
            ref: props.article.ref,
            category: props.article.category,
            description: props.article.description,
            size: props.article.size,
            price: props.article.price,
            picture: props.article.picture
        } : {
            name: "",
            ref: "",
            category: props.category ? props.category[0].id : null,
            description: "",
            size: [],
            price: "",
            picture: []
    });
    const [selectedPicture, setSelectedPicture] = useState([]);
    const [sizeField, setSizeField] = useState("");
    const [size, setSize] = useState(props.article ? props.article.size : []);
    const [error, setError] = useState(false);

    const styles = {
        iconAction: {
            color: theme.palette.colors.green,
            fontSize: "30px",
            cursor: "pointer",
            '&:hover': {
                color: "black"
            },
        }
    }

    function handleSubmit() {
        let tmpArticle = article;
        tmpArticle.size = size;
        tmpArticle.picture = selectedPicture;
        tmpArticle.price = parseFloat(tmpArticle.price).toFixed(2);

        if (tmpArticle.name === "" || tmpArticle.description === "" || tmpArticle.price === "" || tmpArticle.size.length === 0 || tmpArticle.ref === "") {
            setError(true);
            return;
        }
       props.submit(tmpArticle);
    }

    function buildCard(index) {
        return (<div className={"col-4 pt-3"} key={index}>
            <div className={"card text-center preview-image" + (selectedPicture.includes(article.picture[index].file) ? " selected" : "")}>
                { selectedPicture.includes(article.picture[index].file) &&
                    <span className={"span-order"}>{selectedPicture.indexOf(article.picture[index].file) + 1}</span>
                }
                <img src={URL.createObjectURL(article.picture[index].file)} onClick={() => {
                    let tmp = selectedPicture;
                    if (tmp.includes(article.picture[index].file))
                        tmp.splice(tmp.indexOf(article.picture[index].file), 1);
                    else {
                        tmp.push(article.picture[index].file);
                    }
                    setSelectedPicture([...tmp]);
                }} className={"img-thumbnail cursor-pointer "} alt="..."/>

            </div>
        </div>);
    }

    function buildImagePreview() {
        let list = [];
        let row = [];

        for (let i = 0; i < article.picture.length; i++) {
            for (let count = 0; count < 3 && i < article.picture.length; i++, count++){
                row.push(buildCard(i));
            }
            i--;
            list.push(<div className={"row"} key={i}>{row}</div>);
            row = [];
        }
        return list;
    }

    function buildSize() {
        let row = [];

        for (let i = 0; i < size.length; i++) {
            row.push(
                <ListItem secondaryAction={
                    <IconButton aria-label="comment">
                      <DeleteIcon onClick={() => {
                          let tmp = size;
                          
                          tmp.splice(i, 1);
                          setSize([...tmp]);
                      }}/>
                    </IconButton>
                  }>
                  <ListItemText
                    primary={size[i]}
                  />
                </ListItem>)
        }
        return (row);
    }

    function buildCategory() {
        let row = [];

        if (props.category !== null) {
            for (let i = 0; i < props.category.length; i++) {
                row.push(<MenuItem value={props.category[i].id}>{props.category[i].name}</MenuItem>)
            }
        }
        return row;
    }

    return (
        <Popup size={"large"} close={props.close}>
            <PopupHeader>
                <PopupTitle>{props.article !== null ? "Modifier un article" : "Créer un nouvel article"}</PopupTitle>
            </PopupHeader>

            <PopupBody>
                <Grid container direction={"column"} justifyContent={"space-between"} alignItems={"center"}>
                    <Grid container item justifyContent={"space-evenly"} sx={{marginBottom: "1rem"}}>
                        <Grid item xs={5}>
                            <TextField sx={{width: "100%"}} inputProps={{min: 0, style: { textAlign: 'center' }}} type={"text"} label={"Nom de l'article"} defaultValue={article.name} onChange={(event) => {
                                let tmp = article;
                                tmp.name = event.target.value;
                                setArticle(tmp);
                            }}/>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField  sx={{width: "100%"}} inputProps={{min: 0, style: { textAlign: 'center' }, inputMode: 'numeric', pattern: '[0-9]*'}} type={"number"} label={"Prix"} defaultValue={article.price} onChange={(event) => {
                                let tmp = article;
                                tmp.price = event.target.value;
                                setArticle(tmp);
                            }}/>
                        </Grid>
                        
                    </Grid>
                    
                    <Grid container item justifyContent={"space-evenly"} sx={{marginBottom: "1rem"}}>
                        <Grid item xs={5}>
                            <TextField sx={{width: "100%"}} inputProps={{min: 0, style: { textAlign: 'center' }}} type={"text"} label={"Référence de l'article"} defaultValue={article.ref} onChange={(event) => {
                                let tmp = article;
                                tmp.ref = event.target.value;
                                setArticle(tmp);
                            }}/>
                        </Grid>

                        <Grid item xs={5}/>
                    </Grid>

                    <Grid container item justifyContent={"space-evenly"} sx={{marginBottom: "1rem"}}>
                        <Grid item xs={11}>
                        <TextField sx={{width: "100%"}} inputProps={{min: 0, style: { textAlign: 'center' }}} multiline rows={4} type={"text"} label={"Description"} defaultValue={article.description} onChange={(event) => {
                            let tmp = article;
                            tmp.description = event.target.value;
                            setArticle(tmp);
                        }}/>
                        </Grid>
                    </Grid>

                    <Grid container item justifyContent={"space-evenly"} sx={{marginBottom: "1rem"}}>
                        <Grid container item xs={5} alignItems={"center"}>
                            <Grid item xs={10}>
                                <TextField sx={{width: "100%"}} inputProps={{min: 0, style: { textAlign: 'center' }}} type={"text"} label={"Tailles"} value={sizeField} onChange={(event) => {
                                    setSizeField(event.target.value);
                                }}/>
                            </Grid>

                            <Grid item xs>
                                <DoneIcon sx={styles.iconAction} onClick={() => {
                                    if (sizeField !== "") {
                                        let tmp = size;
                                        tmp.push(sizeField);
                                        setSize([...tmp]);
                                        setSizeField("");
                                    }
                                }}/>
                            </Grid>
                        </Grid>

                        <Grid item xs={5}>
                            <List>
                                {buildSize()}
                            </List>
                        </Grid>
                    </Grid>

                    <Grid container item justifyContent={"space-evenly"} sx={{marginBottom: "1rem"}}>
                        <Grid container item xs={5} alignItems={"center"}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Catégorie</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={article.category}
                                        label="Categorie"
                                        onChange={(event) => {
                                            let tmp = article;
                                            tmp.category = event.target.value;
                                            setArticle({...tmp});
                                        }}
                                    >
                                        {buildCategory()}
                                    </Select>
                            </FormControl>
                        </Grid>
                        <Grid container item xs={5} alignItems={"center"}/>
                    </Grid>

                    <Grid  item>
                    <div className={"w-100 text-center row horizontal-top-line-grey-dark mt-4"}>
                            <h6 className={"w-100"}>Photo</h6>
                            <input accept=".png, .jpg" type={"file"} multiple className={"input-image col text-center"}
                                   onChange={(event) => {
                                       let tmp = article;
                                       for (let i = 0; i < event.target.files.length; i++) {
                                           tmp.picture.push({
                                               pictureID: i,
                                               file: event.target.files[i],
                                           });
                                       }
                                       setArticle({...article, tmp});
                                   }}/>
                        </div>
                    {article.picture.length !== 0 &&
                        buildImagePreview()
                    }
                    </Grid>
                </Grid>

                {error && props.article == null &&<small className={"text-danger col-12"}>Veuillez remplir tous les champs</small>}
                {error && props.article && <small className={"text-danger col-12"}>Le champ du nom d'équipe est obligatoire</small>}
            </PopupBody>

            <PopupFooter dismiss={"Annuler"} close={props.close}>
                <input className={"btn btn-primary"} type={"submit"} value={"Valider"}
                       onClick={() => {handleSubmit()}}/>
            </PopupFooter>
        </Popup>
    );
}