import { useState, useEffect } from "react";
import { useParams, Link as RouterLink, Link } from 'react-router-dom';
import { Grid, Typography, Box, Button } from "@mui/material";
import { useTheme } from '@emotion/react';
import ReactGA from "react-ga4";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

import AddUpdateCategoryPopup from "./AddUpdateCategoriePopup";
import AddUpdateProductPopup from "./AddUpdateProductPopup";
import { Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle } from "../../component/Popup/Popup";
import ApiClient from "../../service/ApiClient";

export default function Boutique(props) {
    const theme = useTheme();
    let { category } = useParams();
    const [showCategoryPopup, setShowCategoryPopup] = useState("");
    const [showArticlePopup, setShowArticlePopup] = useState("");
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [confirmDeleteArticlePopup, setConfirmDeleteArticlePopup] = useState(false);
    const [actualEditCategory, setActualEditCategory] = useState(null);
    const [actualArticle, setActualArticle] = useState(null);
    const [categoryList, setCategoryList] = useState(null);
    const [actualCategory, setActualCategory] = useState(null);
    const [article, setArticle] = useState([]);
    const [openCategory, setOpenCategory] = useState(false);

    const styles = {
        title: {
            fontWeight: "bold",
            fontSize: '20px',
            [theme.breakpoints.up('md')]: {
                fontSize: '25px',
            },
        },
        titleSmall: {
            marginBottom: "2rem",
            fontWeight: "bold",
            fontSize: '20px',
            [theme.breakpoints.up('md')]: {
                fontSize: '25px',
            },
        },
        addCategoryButton: {
            marginLeft: "1rem",
            fontSize: "25px",
            color: "white",
            borderRadius: "50%",
            backgroundColor: theme.palette.colors.green,
            cursor: "pointer"
        },
        line: {
            borderBottom: "1px solid #c7c7c7",
            height: "1rem"
        },
        closeContainer: {
            color: theme.palette.colors.green,
            marginTop: "1rem",
            fontWeight: "bold",
            fontSize: "14px"
        },
        addArticle: {
            position: "fixed",
            bottom: "75px",
            right: "0px",
            alignItems: "center",
            display: "block",
            '& button': {
                backgroundColor: "transparent",
                border: "none"
            },
            '& i': {
                color: theme.palette.colors.green,
                fontSize: "60px"
            }
        },
        buttonContainer: {
            marginLeft: "2rem"
        },
        categoryContent: {
            fontWeight: "normal",
            color: "#878787",
            cursor: "pointer",
            '&:hover': {
                color: theme.palette.colors.green
            },
        },
        categoryContentFocus: {
            fontWeight: "bold",
            color: theme.palette.colors.green,
            cursor: "pointer",
            '&:hover': {
                color: theme.palette.colors.green
            },
        },
        iconAction: {
            color: theme.palette.colors.green,
            fontSize: "20px",
            marginLeft: "10px",
            cursor: "pointer",
            '&:hover': {
                color: "black"
            },
        },
        cardArticle: {
            marginTop: "1rem",
            marginRight: "0.5rem",
            marginLeft: "0.5rem",
            cursor: "pointer",
            '&:hover': {
                '& img': {
                    transform: "scale(1.05)"
                }
            }
        },
        topCard: {
            backgroundColor: "#f6f6f6",
            height: "250px!important",
            padding: "10px",
            [theme.breakpoints.up('sm')]: {
                height: "400px!important",
            },
           
        },
        imageContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "230px!important",
            [theme.breakpoints.up('sm')]: {
                height: "380px!important",
            },
        },
        imageArticle: {
            maxWidth: "100%", 
            maxHeight: "100%",
        },
        titleArticle: { 
            color: "black",
            margin: "0.5rem 0",
            fontSize: "15px",
            fontWeight: "bold"
        },
        priceArticle: {
            fontSize: "15px",
            fontWeight: "bold",
            color: theme.palette.colors.green
        }
    }

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        (async () => {
            try {
                await loadData();
                setOpenCategory(false);
                if(category !== undefined) {
                    props.handleLastPath(category);
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [category]);

    async function loadData() {
        try {
            let result = await ApiClient.instance.getShopCategory();
            let categoryId = null;
            setCategoryList(result.data);
            for (let i = 0; i < result.data.length; i++) {
                if (result.data[i].name === category) {
                    categoryId = result.data[i].id
                }
            }
            setActualCategory(categoryId !== null ? categoryId : result.data[0].id);
            result = await ApiClient.instance.getShopArticle(categoryId !== null ? categoryId : result.data[0].id);
            setArticle(result.data);

            if (localStorage.getItem('shop_session') !== null) {
                result = await ApiClient.instance.getShopSession(localStorage.getItem('shop_session'));
                if (result.data !== null) {
                    props.handleCartIcon(result.data.products.length);
                } else {
                    localStorage.removeItem('shop_session');
                    props.handleCartIcon(0);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    function buildCategory(format) {
        let row = [];

        if (categoryList !== null) {
            for(let i = 0; i < categoryList.length; i++) {
                row.push(<Grid container item sx={{marginBottom: "10px"}} justifyContent={"flex-start"} alignItems={"center"} key={i}>
                            <Grid item>
                                <Link component={RouterLink} to={"/" + categoryList[i].name}>
                                    <Typography sx={actualCategory === categoryList[i].id ? styles.categoryContentFocus : styles.categoryContent}>
                                        {categoryList[i].name}
                                    </Typography>
                                </Link>
                            </Grid>
                            { props.isAdmin &&
                            <Grid item>
                                <EditIcon sx={styles.iconAction} onClick={() => {
                                    setShowCategoryPopup("update");
                                    setActualEditCategory(categoryList[i]);
                                    setOpenCategory(false);
                                }}/>
                                <DeleteIcon sx={styles.iconAction} onClick={() => {
                                    setConfirmPopup(true);
                                    setOpenCategory(false);
                                }}/>
                            </Grid>
                            }
                            
                        </Grid>)
            }
        }
        return (row);
    }

    async function addArticle(data) {
        try {
            await ApiClient.instance.createShopArticle(data);
            props.createNotification("success", {title: "Succès", message: "Article créé", timeout: 3000});
            setShowArticlePopup("");
            await loadData();
        } catch (e) {
            props.createNotification("error", {title: "Erreur", message: "Article non créé", timeout: 3000});
            console.log(e);
        }
    }

    async function addCategory(data) {
        try {
            await ApiClient.instance.createShopCategory(data);
            props.createNotification("success", {title: "Succès", message: "Catégorie créée", timeout: 3000});
            setShowCategoryPopup("");
            await loadData();
        } catch (e) {
            props.createNotification("error", {title: "Erreur", message: "Catégorie non créée", timeout: 3000});
            console.log(e);
        }
    }

    async function updateCategory(data) {
        try {
            await ApiClient.instance.updateShopCategory(data);
            props.createNotification("success", {title: "Succès", message: "Catégorie créée", timeout: 3000});
            setShowCategoryPopup("");
            await loadData();
        } catch (e) {
            props.createNotification("error", {title: "Erreur", message: "Catégorie non créée", timeout: 3000});
            console.log(e);
        }
    }

    function buildArticle() {
        let element = [];

        if (article.length !== 0) {
            for (let i = 0; i < article.length; i++) {
                element.push(
                <Grid container item direction={"column"} xs={5} sm={5.5} md={4.5} lg={3.5} sx={styles.cardArticle}>
                    <Link to={"/produit/" +  article[i].path} sx={{textDecoration: "none!important"}}>
                    <Grid container item xs justifyContent={"center"} alignItems={"center"}>
                        <Grid container item sx={styles.topCard} justifyContent={"center"} alignItems={"center"}>
                            <Grid item sx={styles.imageContainer}>
                                <img style={styles.imageArticle} alt="article" src={process.env.REACT_APP_API_URL + article[i].picture[0].link}/>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Typography sx={styles.titleArticle}>{article[i].name}</Typography>
                    </Grid>
                    <Grid container item justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item>
                            <Typography sx={styles.priceArticle}>{parseFloat(article[i].price).toFixed(2) + " €"}</Typography>
                        </Grid>
                        { props.isAdmin &&
                        <Grid item>
                            <DeleteIcon sx={styles.iconAction} onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setConfirmDeleteArticlePopup(true);
                                setActualArticle(article[i]);
                            }}/>
                        </Grid>
                        }
                    </Grid>
                    </Link>
                </Grid>
               );
            }
        }
        return element;
    }

    if (openCategory && props.dimensions <= 900) {
        return (
            <Grid container item justifyContent={"center"}>
                <Grid container item direction={"column"} justifyContent={"flex-start"} xs={6}>
                    <Grid container item>
                        <Grid item>
                            <Typography sx={styles.titleSmall}>Catégories</Typography>
                        </Grid>
                        { props.isAdmin && 
                        <Grid item>
                            <AddIcon sx={styles.addCategoryButton} onClick={() => {
                                setShowCategoryPopup("create");
                                setOpenCategory(false);
                            }}/>
                        </Grid>
                        }
                    </Grid>
                    
                        
                    {buildCategory("small")}

                    <Grid container item justifyContent={"center"}>
                        <Grid sx={styles.line} item xs/>
                    </Grid>

                    <Grid container item>
                        <Grid sx={styles.closeContainer} container item xs justifyContent={"center"} alignItems={"center"} onClick={() => setOpenCategory(false)}>
                            <Grid item>
                                <CloseIcon/>
                            </Grid>
                            <Grid item>
                                <Typography>Fermer</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Grid container item direction={"column"}>
                { props.dimensions <= 900 &&
                <Grid container item>
                    <Grid sx={styles.buttonContainer} item>
                        <Button variant="contained" color="secondary" onClick={() => setOpenCategory(!openCategory)}>Categories</Button>
                    </Grid>
                </Grid>}
                
                <Grid container item justifyContent={"center"}>
                { props.dimensions > 900 &&
                <Grid container item direction={"column"} alignItems={"flex-start"} xs={3}>
                    <Grid container item alignItems={"center"} sx={{ marginBottom: '20px',}}>
                        <Grid item>
                            <Typography sx={styles.title}>Catégories</Typography>
                        </Grid>
                        { props.isAdmin && 
                        <Grid item>
                            <AddIcon sx={styles.addCategoryButton} onClick={() => {
                                    setShowCategoryPopup("create");
                                }}/>
                        </Grid>
                        }
                        
                    </Grid>
                    {buildCategory("large")}
                </Grid>
                }
                <Grid container item xs={12} md={9} justifyContent="space-evenly" alignContent={"space-evenly"}>
                    {buildArticle()}
                </Grid>
                {showCategoryPopup === "create" && <AddUpdateCategoryPopup close={() => setShowCategoryPopup("")} submit={addCategory} category={null}/>}
                {showCategoryPopup === "update" && <AddUpdateCategoryPopup close={() => setShowCategoryPopup("")} submit={updateCategory} category={actualEditCategory}/>}
                {showArticlePopup === "create" && <AddUpdateProductPopup close={() => setShowArticlePopup("")} submit={addArticle} category={categoryList} article={null}/>}
                {showArticlePopup === "update" && <AddUpdateProductPopup close={() => setShowArticlePopup("")} submit={updateCategory} category={actualEditCategory} article={"article"}/>}
                {confirmPopup &&
                    <Popup close={() => {
                        setConfirmPopup(false);
                    }}>
                        <PopupHeader>
                            <PopupTitle>Supprimer une catégorie</PopupTitle>
                        </PopupHeader>
                        <PopupBody>
                            <p>Voulez-vous vraiment supprimer cette catégorie ?</p>
                        </PopupBody>
                        <PopupFooter dismiss={"Non"} close={() => {
                                setConfirmPopup(false);
                             }}>
                            <button className={"btn btn-primary"} onClick={async() => {
                                    try {
                                        await ApiClient.instance.deleteShopCategory(actualEditCategory.id);
                                        props.createNotification("success", {title: "Succès", message: "Catégorie supprimé", timeout: 3000});
                                        setConfirmPopup(false);
                                        await loadData();
                                    } catch (e) {
                                        props.createNotification("error", {title: "Erreur", message: "Catégorie non supprimé", timeout: 3000});
                                    }
                            }}>Oui
                            </button>
                        </PopupFooter>
                </Popup>
            }
    
                {confirmDeleteArticlePopup &&
                    <Popup close={() => {
                        setConfirmDeleteArticlePopup(false);
                    }}>
                        <PopupHeader>
                            <PopupTitle>Supprimer un article</PopupTitle>
                        </PopupHeader>
                        <PopupBody>
                            <p>Voulez-vous vraiment supprimer cet article ?</p>
                        </PopupBody>
                        <PopupFooter dismiss={"Non"} close={() => {
                                setConfirmDeleteArticlePopup(false);
                             }}>
                            <button className={"btn btn-primary"} onClick={async() => {
                                    try {
                                        await ApiClient.instance.deleteShopArticle(actualArticle.id);
                                        props.createNotification("success", {title: "Succès", message: "Article supprimé", timeout: 3000});
                                        setConfirmDeleteArticlePopup(false);
                                        await loadData();
                                    } catch (e) {
                                        props.createNotification("error", {title: "Erreur", message: "Article non supprimé", timeout: 3000});
                                    }
                            }}>Oui
                            </button>
                        </PopupFooter>
                    </Popup>
                }
    
                { props.isAdmin &&
                    <Box sx={styles.addArticle}>
                        <button className={"no-button"} onClick={() => {
                            setShowArticlePopup("create");
                        }}>
                            <i className="material-icons">add_circle</i>
                        </button>
                    </Box>
                }
                </Grid>
            </Grid>
        );
    }
}