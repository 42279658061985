import React from 'react'
import { Grid  } from "@mui/material";
import { styled } from "@mui/system";
import "./ScrollText.scss"

export default function ScrollText(props) {
    return (
        <Grid item id={"rssBlock"}>
            <p className={props.dimensions < 960 ? "cnnContents-sm" : "cnnContents"}>
                <span className={"marqueeStyle"}><b>{props.title}</b> {props.text}&nbsp;</span>
                <span className={"marqueeStyle2"}><b>{props.title}</b> {props.text}&nbsp;</span>
            </p>
        </Grid>
    )
}