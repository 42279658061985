import React, {useState} from "react";
import { DayPicker } from "react-day-picker";
import fr from  'date-fns/locale/fr';
import 'react-day-picker/dist/style.css';

import { Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle } from "../../component/Popup/Popup";

export default function AddArticlePressePopup(props) {
    const [article, setArticle] = useState({
        Title: '',
        picture: [],
        Date: new Date()
    });
    const [date, setDate] = useState(new Date());

    function buildCard() {
        return (<div className={"col-4 pt-3"}>
            <div className={"card text-center"}>
                <img src={URL.createObjectURL(article.picture)}
                     className={"img-thumbnail cursor-pointer "} alt="..."/>
            </div>
        </div>);

    }

    function buildImagePreview() {
       return (<div className={"row"}>{buildCard()}</div>);
    }

    function handleSubmit() {
        let tmpArticle = article;

        tmpArticle.Date = date;
        if (tmpArticle.Title === "")
            return;
        props.add(tmpArticle);
    }

    return ( <Popup size={"large"} close={props.close}>
        <PopupHeader>
            <PopupTitle>Créer un nouvelle article</PopupTitle>
        </PopupHeader>

        <PopupBody>
            <div className={"form-container"}>
                <label className={"text-left"}>Titre<i
                    className={"text-danger"}> *</i></label>
                <input className="form-control" type={"text"} placeholder={"Ajouter un titre"}
                       value={article.Title} onChange={(event) => {
                    let tmp = article;
                    tmp.Title = event.target.value;
                    setArticle({...article, tmp});
                }}/>
                {!article.Title &&
                <small className={"text-danger col-12"}>Ce champ est obligatoire</small>}
            </div>

            <div className={"w-100 horizontal-top-line-grey-dark row dateTime"}>
                <h6 className={"w-100"}>Date</h6>
                <DayPicker className={"date-picker"} mode={"single"} selected={date} onSelect={setDate}
                           locale={fr}/>
            </div>

            <div className={"w-100 text-center row horizontal-top-line-grey-dark"}>
                <h6 className={"w-100"}>Photo</h6>
                <input accept=".png, .jpg" type={"file"} className={"input-image col text-center"}
                       onChange={(event) => {
                           let tmp = article;
                           tmp.picture = event.target.files[0];
                           setArticle(tmp);
                       }}/>
            </div>

            {article.picture.length !== 0 &&
            buildImagePreview()
            }
        </PopupBody>

        <PopupFooter dismiss={"Annuler"} close={props.close}>
            <input className={"btn btn-primary"} type={"submit"} value={"Créer"}
                   onClick={() => handleSubmit()}/>
        </PopupFooter>
    </Popup>);
}