import React from 'react';
import {InputLabel, MenuItem, FormControl, Select, Box} from '@mui/material';

import "./SimpleSelect.scss";

export default function SimpleSelect(props) {

    function buildMenuItem() {
        let tab = [];

        for (let i = 0; i < props.data.length; i++) {
            tab.push(<MenuItem value={props.data[i].value} key={i}>{props.data[i].name}</MenuItem>)
        }
        return tab;
    }

        return (
            <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel shrink id="simple-select-label">{props.textLabel}</InputLabel>
                <Select
                    sx={props.inputStyle !== undefined ? props.inputStyle : ""}
                    labelId="simple-select-label"
                    id="simple-select"
                    value={props.actualIndex}
                    label={props.textLabel}
                    onChange={(event) => props.onChange(event.target.value)}
                >
                    {buildMenuItem()}

                </Select>
            </FormControl>
            </Box>
        );
}
