import axios from "axios";

class ApiClient {
    constructor() {
        this.client = axios.create({
            withCredentials: true,
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 60000, //60s
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });

        this.clientFD = axios.create({
            withCredentials: true,
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 60000, //60s
            headers: {
                'Accept': 'multipart/form-data',
                'Content-Type': 'multipart/form-data'
            },
        });
    }

    ////////////////////////////////
    // USER
    ////////////////////////////////
    isPseudoAvailable(pseudo) {
        return (this.client.get("/user/isPseudoAvailable/" + pseudo ));
    }

    login(username, password, stayLog) {
        return (this.client.post("/auth/login", {Email: username, Password: password, stayLog: stayLog}));
    }

    authStatus() {
        return (this.client.get("/auth/auth-status"));
    }

    logout() {
        return (this.client.post("/auth/logout"));
    }

    register(data) {
        return (this.client.post("/user", data))
    }

    activateAccount(userId, token) {
        return (this.client.post("/user/activate/" + userId + "/" + token))
    }

    forgetPassword(data) {
        return (this.client.post("/passwordToken", data))
    }

    changePassword(data) {
        return (this.client.post("/passwordToken/use", data))
    }

    updateUser(data) {
        return (this.client.patch("user", data))
    }

    userAcceptPronoRules() {
        return (this.client.patch("/user/userAcceptPronoRules"))
    }

    deleteUser() {
        return (this.client.delete("/user"))
    }

    ////////////////////////////////
    // ARTICLES
    ////////////////////////////////

    getArticles(id, year, filter) {
        if (id === "")
            id = "all";
        return (this.client.get("/articles/" + year + "/" + id, {params: filter}));
    }

    getArticlesYear() {
        return (this.client.get("/articleYear"));
    }

    postArticle(article) {
        if (article.firstPicture === "")
            article.firstPicture = null;
        if (article.article === "")
            article.article = null;
        return this.client.post("/articles", article);
    }

    deleteArticle(id) {
        return this.client.delete("/articles/" + id);
    }

    updateArticle(id, article) {
        return this.client.patch("/articles/" + id, article);
    }

    getArticlesPic (id) {
        return this.client.get("/articlesPicture/" + id);
    }

    postArticlePic(picture, id) {
        let fd = new FormData();
        let index = 1;
        //Obligée de faire ça pour envoyer le file
        for (let i = 0; i < picture.length; i++) {
            fd.append("pictureTitle", picture[i].pictureTitle);
            fd.append("articleImage", picture[i].file);
            fd.append("orderPicture", index.toString());
            index++;
        }
        if (picture.length === 1)
            fd.append("articleImage", "OnePicOnly");
        fd.append("articleId", id);

        return this.clientFD.post("/articlesPicture", fd);
    }

    deleteArticlePic(id) {
        return this.client.delete("/articlesPicture/" + id);
    }

    ////////////////////////////////
    // TEAMS
    ////////////////////////////////

    getTeams() {
        return this.client.get("/team/");
    }

    createTeam(data) {
        let fd = new FormData();

        //Obligée de faire ça pour envoyer le file
        fd.append("Logo", data.Logo);
        fd.append("Name", data.Name);
        return this.clientFD.post("/team", fd);
    }

    updateTeam(data) {
        let fd = new FormData();

        if (data.Logo.name === undefined) {
            return this.client.patch("/team/" + data.Id, data)
        }
        //Obligée de faire ça pour envoyer le file
        fd.append("Logo", data.Logo);
        fd.append("Name", data.Name);
        return this.clientFD.patch("/team/" + data.Id, fd)
    }

    deleteTeam(id) {
        return this.client.delete("/team/" + id);
    }

    ////////////////////////////////
    // CATEGORY
    ////////////////////////////////

    getCategory() {
        return this.client.get("/category/");
    }

    createCategory(data) {
        return this.client.post("/category", data);
    }

    updateCategory(data) {
        return this.client.patch("/category/" + data.id, data);
    }

    updateOrderCategory(data) {
        return this.client.patch("/category/order", data);
    }

    deleteCategory(id) {
        return this.client.delete("/category/" + id);
    }

    ////////////////////////////////
    // EVENEMENT
    ////////////////////////////////

    getAllEvenements() {
        return this.client.get("/event/1");
    }

    getLastEvenement() {
        return this.client.get("/event/0");
    }

    postEvenement(data) {
        let fd = new FormData();

        //Obligée de faire ça pour envoyer le file
        fd.append("dateEnd", data.dateEnd);
        fd.append("saleLink", data.saleLink);
        fd.append("saleEnd", data.saleEnd);
        fd.append("evenementImage", data.picture);
        return this.clientFD.post("/event", fd);
    }

    updateEvent(data) {
        let fd = new FormData();

        if (data.picture.name === undefined) {
            return this.client.patch("/event/" + data.id, data)
        }
        //Obligée de faire ça pour envoyer le file
        fd.append("evenementImage", data.picture);
        fd.append("dateEnd", data.dateEnd);
        fd.append("saleLink", data.saleLink);
        fd.append("saleEnd", data.saleEnd);
        return this.clientFD.patch("/event/" + data.id, fd)
    }

    deleteEvenement(id) {
        return this.client.delete("/event/" + id);
    }

    ////////////////////////////////
    // CALENDAR
    ////////////////////////////////

    getCalendar(category, season) {
        return this.client.get("/calendar/" + category + "/" + season);
    }

    createMatch(category, match) {
        return this.client.post("/calendar/" + category + "/", match)
    }

    deleteMatch(category, id) {
        return this.client.delete("/calendar/" + category + "/" + id)
    }

    updateMatch(category, match) {
        return this.client.patch("/calendar/" + category + "/" + match.id, match)
    }

    ////////////////////////////////
    // RANK
    ////////////////////////////////

    getRank(category, season, phase) {
        return this.client.get("/rank/data/" + category + "/" + season + "/" + phase);
    }

    getPhaseRank(category, season) {
        return this.client.get("/rank/phase/" + category + "/" + season);
    }

    createRank(data, category, season) {
        return this.client.post("/rank/" + category + "/" + season, data);
    }

    updateRank(data, category, season, phase) {
        return this.client.patch("/rank/" + category + "/" + season + "/" + phase, data);
    }

    deleteRank(category, season, phase) {
        return this.client.delete("/rank/" + category + "/" + season + "/" + phase);
    }

    getLinkRank(data) {
        return this.client.get("/rankLink/" + data);
    }

    updateLinkRank(data) {
        return this.client.patch("/rankLink/", data);
    }

    ////////////////////////////////
    // RANK GOAL
    ////////////////////////////////

    getRankGoal(category, season) {
        return this.client.get("/rankGoal/" + category + "/" + season);
    }

    createPlayer(data, category, season) {
        return this.client.post("/rankGoal/" + category + "/" + season, data);
    }

    deletePlayer(category, id) {
        return this.client.delete("/rankGoal/" + category + "/" + id);
    }

    updatePlayer(data, category, id) {
        return this.client.patch("/rankGoal/" + category + "/" + id, data);
    }

    ////////////////////////////////
    // SEASON
    ////////////////////////////////

    getSeason() {
        return this.client.get("/season/");
    }

    createSeason(data) {
        return this.client.post("/season", data);
    }

    updateSeason(data) {
        return this.client.patch("/season/" + data.id, data);
    }

    deleteSeason(id) {
        return this.client.delete("/season/" + id);
    }

    ////////////////////////////////
    // ARTICLES PRESSE
    ////////////////////////////////

    getArticlesPresse(year) {
        return this.client.get("/pressReview/" + year);
    }

    postArticlePresse(article) {
        let fd = new FormData();

        //Obligée de faire ça pour envoyer le file
        fd.append("Title", article.Title);
        fd.append("Date", article.Date);
        fd.append("revuePresseImage", article.picture);

        return this.clientFD.post("/pressReview/", fd);
    }

    updateArticlePresse(id, article) {
        return this.client.patch("/pressReview/" + id, article);
    }

    deleteArticlePresse(id) {
        return this.client.delete("/pressReview/" + id);
    }

    ////////////////////////////////
    // ARTICLE YEAR
    ////////////////////////////////

    getRevuePresseYear() {
        return this.client.get("/pressReviewYear/");
    }

    ////////////////////////////////
    // LAST MATCH
    ////////////////////////////////

    getLastMatch() {
        return this.client.get("/lastMatch/");
    }

    ////////////////////////////////
    // NEXT MATCH
    ////////////////////////////////

    getNextMatch(category) {
        return this.client.get("/nextMatch/" + category);
    }



    ////////////////////////////////
    // LAST ARTICLE
    ////////////////////////////////

    getLastArticle() {
        return this.client.get("/lastArticle/");
    }

    ////////////////////////////////
    // PARTNER
    ////////////////////////////////

    getPartner(rand) {
        return this.client.get("/partner/" + rand);
    }

    postPartner(partner) {
        let fd = new FormData();

        //Obligée de faire ça pour envoyer le file
        fd.append("Title", partner.Title);
        if (partner.Site !== '')
            fd.append("Site", partner.Site);
        fd.append("partenaireImage", partner.picture);

        return this.clientFD.post("/partner/", fd);
    }

    updatePartner(id, partner) {
        return this.client.patch("/partner/" + id, partner);
    }

    deletePartner(id) {
        return this.client.delete("/partner/" + id);
    }

    ////////////////////////////////
    // CONTACT
    ////////////////////////////////

    sendMail(data) {
        return this.clientFD.post("/sendMail/", data);
    }

    ////////////////////////////////
    // PRONO
    ////////////////////////////////

    getRankProno(season) {
        return this.client.get("/rankProno/" + season);
    }

    createOrUpdateRankProno(category, season) {
        return this.client.post("/rankProno/" + category + "/" + season);
    }

    getAllProno(userId, season) {
        return this.client.get("/prono/allProno/" + userId + "/" + season);
    }

    getLastProno(userId, season) {
        return this.client.get("/prono/lastProno/" + userId + "/" + season);
    }

    createProno(data) {
        return this.client.post("/prono/", data);
    }

    updateProno(pronoId, data) {
        return this.client.patch("/prono/" + pronoId, data);
    }

    getPronoBonusInventory(userId) {
        return this.client.get("/pronoBonusInventory/" + userId);
    }

    // GET PAST MATCH
    getPastMatch(season) {
        return this.client.get("/pastMatch/" + season);
    }

    // PRONO REMINDER
    getPronoReminderStatus() {
        return this.client.get("/pronoRemind");
    }

    updatePronoReminder(action) {
        return this.client.patch("pronoRemind/" + action);
    }

    unsubscribePronoReminder(bearer) {
        let client = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 60000, //60s
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        client.defaults.headers["Authorization"] = "Bearer " + bearer;
        return client.patch("/pronoRemind/unsubscribe");
    }

    ////////////////////////////////
    // HISTORY
    ////////////////////////////////

    getHistory() {
        return this.client.get("/history/");
    }

    updateHistory(data) {
        return this.client.patch("/history/", data);
    }

    ////////////////////////////////
    // PICTURE
    ////////////////////////////////

    deletePicture(data) {
       return this.client.delete("/uploadPicture/" + data.split('/')[3])
    }

    ////////////////////////////////
    // SHOP CATEGORY
    ////////////////////////////////

    getShopCategory() {
        return this.client.get("/shopCategory/");
    }

    createShopCategory(data) {
        return this.client.post("/shopCategory", data);
    }

    updateShopCategory(data) {
        return this.client.patch("/shopCategory/" + data.id, data);
    }

    deleteShopCategory(id) {
        return this.client.delete("/shopCategory/" + id);
    }

    ////////////////////////////////
    // SHOP PRODUCT
    ////////////////////////////////

    getShopProductRand(id, nb) {
        return this.client.get("/shopProductRand/" + id + "/" + nb);
    }

    getShopAllArticle() {
        return this.client.get("/shopProduct/all/all");
    }

    getShopArticle(category) {
        return this.client.get("/shopProduct/" + category + "/all");
    }

    getShopArticleByPath(path) {
        return this.client.get("/shopProduct/all/" + path);
    }

    createShopArticle(data) {
        let fd = new FormData();

        //Obligée de faire ça pour envoyer le file
        fd.append("name", data.name);
        fd.append("ref", data.ref);
        fd.append("description", data.description);
        fd.append("price", data.price);
        fd.append("size", data.size);
        fd.append("category", data.category);
        for (let i = 0; i < data.picture.length; i++) {
            fd.append("articleImage", data.picture[i]);
        }
        return this.clientFD.post("/shopProduct", fd);
    }

    updateShopArticle(data) {
        return this.client.patch("/shopProduct/" + data.id, data);
    }

    deleteShopArticle(id) {
        return this.client.delete("/shopProduct/" + id);
    }

    ////////////////////////////////
    // SHOP SESSION
    ////////////////////////////////

    getShopSession(id) {
        return this.client.get("/shopSession/" + id);
    }

    createShopSession(data) {
        return this.client.post("/shopSession", data);
    }

    updateShopSession(id, data) {
        return this.client.patch("/shopSession/" + id, data);
    }

    deleteShopSession(id) {
        return this.client.delete("/shopSession/" + id);
    }

    ////////////////////////////////
    // SHOP ORDER
    ////////////////////////////////

    getShopOrder(id) {
        return this.client.get("/shopOrder/order?payment_intent=" + id);
    }

    getAllShopOrder(filterBy, searchCommand, sortBy, orderBy, limit, skip) {
        return this.client.get("/shopOrder/allOrder?filterBy=" + filterBy + "&search=" + searchCommand + "&sortBy=" + sortBy + "&orderBy=" + orderBy + "&limit=" + limit + "&skip=" + skip);
    }

    getShopOrderItem(id) {
        return this.client.get("/shopOrder/orderItem/" + id);
    }

    getShopOrderWithCode(code) {
        return this.client.get("/shopOrder/orderWithCode/" + code);
    }

    updateShopOrder(id, data) {
        return this.client.patch("/shopOrder/order/" + id, data);
    }

    confirmWithdrawShopOrder(id) {
        return this.client.patch("/shopOrder/order/" + id + "?confirmWithdraw=true");
    }

    createRefundOrder(id, data) {
        return this.client.post("/shopOrder/refund/" + id, data);
    }

    ////////////////////////////////
    // SHOP ORDER STATUS
    ////////////////////////////////

    getShopOrderStatus() {
        return this.client.get("/shopOrderStatus");
    }

    ////////////////////////////////
    // CHECKOUT
    ////////////////////////////////

    createPaymentIntent(data) {
        return this.client.post("/create-payment-intent", data);
    }
}

const instanceApi =  {
    instance: new ApiClient()
}

export default instanceApi;