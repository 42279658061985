import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Link } from "@mui/material";
import { Link as RouterLink, useLocation } from 'react-router-dom';
import moment from "moment";

import ApiClient from "../../service/ApiClient";
import '../../assets/LeftNavigation.scss'
import { useTheme } from '@emotion/react';

function BuildCalendarResult(props) {  
    if (props.dropDownCalendar)
        return (<Grid container item xs={12} justifyContent={"center"} sx={props.styles.subNav}>
                    <Grid item>
                        <Box onClick={() => props.handleChangeActiveMenu("dropDownCalendarType", props.type)}>
                            <Typography sx={props.styles.navItem}>{props.label}</Typography>
                        </Box>
                    </Grid>
                    { props.dropDownCalendarType === props.type &&
                        <Grid container item xs={12} alignItems={"center"} direction={"column"}>
                            <Grid item>
                                {props.buildSubLink("Calendrier/Résultats", props.calendarLink !== undefined ? props.calendarLink : "/calendrier/" + props.type, props.calendarLink !== undefined )}
                            </Grid>
                            <Grid item>
                                {props.buildSubLink("Classement", props.rankLink !== undefined ? props.rankLink : "/classement/general/" + props.type, props.calendarLink !== undefined )}
                            </Grid>
                        </Grid>
                    }
                </Grid>);
    else
        return (<Box/>)
}

function BuildContactAcces(props) {
    if (props.dropDownInfo)
        return (<Grid container item xs={12} justifyContent={"center"} sx={props.styles.subNav}>
            <Grid item>
                <Box onClick={() => props.handleChangeActiveMenu("dropDownInfoType", "contact_acces")}>
                    <Typography sx={props.styles.navItem}>Contacts & accès</Typography>
                </Box>
            </Grid>
            { props.dropDownInfoType === "contact_acces" &&
            <Grid container xs={12} alignItems={"center"} direction={"column"}>
                <Grid item>
                    {props.buildSubLink("Contacts", "/contacts")}
                </Grid>
                <Grid item>
                    {props.buildSubLink("Accès", "/acces")}
                </Grid>
            </Grid>
            }
        </Grid>);
    else
        return (<Box/>)
}

function BuildLinkFoot(props) {
    if (props.dropDownInfo)
        return (<Grid container item xs={12} justifyContent={"center"} sx={props.styles.subNav}>
            <Grid item>
                <Box onClick={() => props.handleChangeActiveMenu("dropDownInfoType", "link_foot")}>
                    <Typography sx={props.styles.navItem}>Liens utiles football</Typography>
                </Box>
            </Grid>
            { props.dropDownInfoType === "link_foot" &&
            <Grid container xs={12} alignItems={"center"} direction={"column"}>
                <Grid item>
                    <Link className={"link-useful"} href={"http://fr.fifa.com/"} target={"_blank"} rel={"noopener noreferrer"} onClick={() => props.handleChangeActiveMenu("menu")}> <Typography sx={props.styles.subNavItem}>Site de la FIFA</Typography></Link>
                </Grid>
                <Grid item>
                    <Link className={"link-useful"} href={"http://www.fff.fr/"} target={"_blank"} rel={"noopener noreferrer"} onClick={() => props.handleChangeActiveMenu("menu")}> <Typography sx={props.styles.subNavItem}>Site de la FFF</Typography></Link>
                </Grid>
                <Grid item>
                    <Link className={"link-useful"} href={"https://footbretagne.fff.fr/"} rel={"noopener noreferrer"} target={"_blank"} onClick={() => props.handleChangeActiveMenu("menu")}> <Typography sx={props.styles.subNavItem}>Ligue de Bretagne de Football</Typography></Link>
                </Grid>
                <Grid item>
                    <Link className={"link-useful"} href={"http://foot22.fff.fr/"} rel={"noopener noreferrer"} target={"_blank"} onClick={() => props.handleChangeActiveMenu("menu")}> <Typography sx={props.styles.subNavItem}>District 22</Typography></Link>
                </Grid>
            </Grid>
            }
        </Grid>);
    else
        return (<Box/>)
}

function BuildLinkHand(props) {
    if (props.dropDownInfo)
        return (<Grid container item xs={12} justifyContent={"center"} sx={props.styles.subNav}>
            <Grid item>
                <Box onClick={() => props.handleChangeActiveMenu("dropDownInfoType", "link_hand")}>
                    <Typography sx={props.styles.navItem}>Liens utiles handball</Typography>
                </Box>
            </Grid>
            { props.dropDownInfoType === "link_hand" &&
            <Grid container xs={12} alignItems={"center"} direction={"column"}>
                <Grid item>
                    <Link className={"link-useful"} href={"http://www.ff-handball.org/"} target={"_blank"} rel="noopener noreferrer" onClick={() => props.handleChangeActiveMenu("menu")}> <Typography sx={props.styles.subNavItem}>Site de la FFH</Typography></Link>
                </Grid>
                <Grid item>
                    <Link className={"link-useful"} href={"http://www.lbhb.com/"} target={"_blank"} rel="noopener noreferrer" onClick={() => props.handleChangeActiveMenu("menu")}> <Typography sx={props.styles.subNavItem}>Ligue de Bretagne de Handball</Typography></Link>
                </Grid>
            </Grid>
            }
        </Grid>);
    else
        return (<Box/>)
}

function BuildPartner(props) {
    if (props.dropDownInfo)
        return (<Grid container item xs={12} justifyContent={"center"} sx={props.styles.subNav}>
            <Grid item>
            {props.buildLink("Nos Partenaires", "/partenaires" )}
            </Grid>
        </Grid>);
    else
        return (<Box/>)
}

function BuildMedia(props) {
    if (props.dropDownMedia)
        return (<Grid container item xs={12} justifyContent={"center"} sx={props.styles.subNav}>
            <Grid container xs={12} alignItems={"center"} direction={"column"}>
                <Grid item>
                    {props.buildLink("Revue de Presse", "/revue-presse" )}
                </Grid>

                <Grid item>
                    {props.buildLink("Espace Vidéos", "/espace-videos" )}
                </Grid>
            </Grid>
        </Grid>);
    else
        return (<Box/>)
}

function BuildClub(props) {
    if (props.dropDownClub)
        return (<Grid container item xs={12} justifyContent={"center"} sx={props.styles.subNav}>
            <Grid container xs={12} alignItems={"center"} direction={"column"}>
                <Grid item>
                    {props.buildLink("Actualités", "/actu/" + new moment().format("YYYY").toString())}
                </Grid>

                <Grid item>
                    {props.buildLink("Histoire", "/histoire" )}
                </Grid>
            </Grid>
        </Grid>);
    else
        return (<Box/>)
}

function BuildProno(props) {
    if (props.dropDownProno)
        return (<Grid container item xs={12} justifyContent={"center"} sx={props.styles.subNav}>
            <Grid container item xs={12} alignItems={"center"} direction={"column"}>
                <Grid item>
                    {props.buildSubLink("Classement", "/monProno", false, true, "rankProno")}
                </Grid>
                <Grid item>
                    {props.buildSubLink("Mes pronostics", "/monProno", false, true, "myProno")}
                </Grid>

                <Grid item>
                    {props.buildSubLink("Reglement", "/monProno", false, true, "ruleProno")}
                </Grid>
            </Grid>
        </Grid>);
    else
        return (<Box/>)
}



export default function LeftNavigation (props) {
    const theme = useTheme();
    const location = useLocation();
    const [categories, setCategories] = useState(null);
    
    const styles = {
        navItem: {
            fontFamily: theme.typography.fontFamily,
            fontWeight: "bold",
            fontSize: "1.1rem",
            color: "white",
            margin: "0.5rem"
        },
        subNavItem: {
            fontFamily: theme.typography.fontFamily,
            fontSize: "1rem",
            color: "white",
            margin: "0.5rem"
        },
        subNav: {
            backgroundColor: "#04af00",
        }
    };
    
    useEffect(() => {
        (async () => {
            try {
                await loadData();
            } catch (error) {
                console.log(error);
            }
        })();
    }, [])


    async function loadData() {
        try {
            let result = await ApiClient.instance.getCategory();
            setCategories(result.data);
        } catch (error) {
            console.log(error);
        }
    }

    function buildLink(title, path) {
        if (props.isShop) {
            return (
                <Link href={(process.env.REACT_APP_ENV === "dev" ? "http" : "https") + "://" + process.env.REACT_APP_DOMAIN + path} onClick={() => props.handleChangeActiveMenu("menu")} state={{prevUrl: location.pathname}}>
                    <Typography sx={styles.navItem}>{title}</Typography>
                </Link>
            );
        } else {
            return (
                <Link  component={RouterLink} to={path} onClick={() => props.handleChangeActiveMenu("menu")} state={{prevUrl: location.pathname}}>
                    <Typography sx={styles.navItem}>{title}</Typography>
                </Link>
            );
        }
    }

    function buildSubLink(title, path, isLink=false, isProno=false, action) {
        if (isLink) {
            return (
                <Link href={path} target={"_blank"} rel="noopener noreferrer" onClick={() => props.handleChangeActiveMenu("menu")} state={{prevUrl: location.pathname}}>
                    <Typography sx={styles.subNavItem}>{title}</Typography>
                </Link>
            );
        }
        if (props.isShop) {
            if (isProno) {
                return (
                    <Link href={(process.env.REACT_APP_ENV === "dev" ? "http" : "https") + "://" + process.env.REACT_APP_DOMAIN + path} onClick={() => {
                        props.handleChangeActiveMenu("dropDownPronoType", action);
                        props.handleChangeActiveMenu("menu");
                    }} state={{prevUrl: location.pathname}}>
                        <Typography sx={styles.subNavItem}>{title}</Typography>
                    </Link>
                );
            } else {
                return (
                    <Link href={(process.env.REACT_APP_ENV === "dev" ? "http" : "https") + "://" + process.env.REACT_APP_DOMAIN + path} onClick={() => props.handleChangeActiveMenu("menu")} state={{prevUrl: location.pathname}}>
                        <Typography sx={styles.subNavItem}>{title}</Typography>
                    </Link>
                );
            }
        } else {
            if (isProno) {
                return (
                    <Link component={RouterLink} to={path} onClick={() => {
                        props.handleChangeActiveMenu("dropDownPronoType", action);
                        props.handleChangeActiveMenu("menu");
                    }} state={{prevUrl: location.pathname}}>
                        <Typography sx={styles.subNavItem}>{title}</Typography>
                    </Link>
                );
            } else {
                return (
                    <Link component={RouterLink} to={path} onClick={() => props.handleChangeActiveMenu("menu")} state={{prevUrl: location.pathname}}>
                        <Typography sx={styles.subNavItem}>{title}</Typography>
                    </Link>
                );
            }
        }
    }

    function BuildCategory() {
        let buildRow = []
        for (let i = 0; i < categories.length; i++) {
            buildRow.push(<BuildCalendarResult styles={styles} dropDownCalendar={props.dropDownCalendar} label={categories[i].name} dropDownCalendarType={props.dropDownCalendarType} handleChangeActiveMenu={props.handleChangeActiveMenu} type={categories[i].name} buildSubLink={buildSubLink} calendarLink={categories[i].calendarLink} rankLink={categories[i].rankLink} />)
        }
        return (buildRow);
    }

    function buildShopLink(title, path) {
        if (!props.isShop) {
            return (
                <Link href={(process.env.REACT_APP_ENV === "dev" ? "http" : "https") + "://boutique." + process.env.REACT_APP_DOMAIN + path} onClick={() => props.handleChangeActiveMenu("menu")} state={{prevUrl: location.pathname}}>
                    <Typography sx={styles.navItem}>{title}</Typography>
                </Link>
            );
        } else {
            return (
                <Link component={RouterLink} to={path} onClick={() => props.handleChangeActiveMenu("menu")} state={{prevUrl: location.pathname}}>
                    <Typography sx={styles.navItem}>{title}</Typography>
                </Link>
            );
        }
    }

    if (categories !== null) {
        return (
            <Box className={"left-navigation" + (props.menuActive === null ? "" : (props.menuActive ? " show" : " hide"))}>
                <Grid container direction={"column"} alignItems={"center"} justifyContent={"flex-end"}>
                    <Grid item>
                        <Box onClick={() => props.handleChangeActiveMenu("dropDownClub")}>
                            <Typography sx={styles.navItem}>Le Club</Typography>
                        </Box>
                    </Grid>

                    <BuildClub styles={styles} dropDownClub={props.dropDownClub} buildLink={buildLink} />
    
                    <Grid item>
                        <Box onClick={() => props.handleChangeActiveMenu("dropDownCalendar")}>
                            <Typography sx={styles.navItem}>Calendrier / Résultats</Typography>
                        </Box>
                    </Grid>
    
                    <BuildCalendarResult styles={styles} dropDownCalendar={props.dropDownCalendar} label={"Seniors A"} dropDownCalendarType={props.dropDownCalendarType} handleChangeActiveMenu={props.handleChangeActiveMenu} type={"seniorsA"} buildSubLink={buildSubLink} />
                    <BuildCalendarResult styles={styles} dropDownCalendar={props.dropDownCalendar} label={"Seniors B"} dropDownCalendarType={props.dropDownCalendarType} handleChangeActiveMenu={props.handleChangeActiveMenu} type={"seniorsB"} buildSubLink={buildSubLink} />
                    <BuildCalendarResult styles={styles} dropDownCalendar={props.dropDownCalendar} label={"Seniors C"} dropDownCalendarType={props.dropDownCalendarType} handleChangeActiveMenu={props.handleChangeActiveMenu} type={"seniorsC"} buildSubLink={buildSubLink} />
                    <BuildCategory/>


                    {props.isAuth && (props.user.Role === "TESTEUR" || props.isAdmin) &&
                    <Grid item>
                        {buildShopLink("Boutique", "/")}
                    </Grid>
                    }
    
                    <Grid item>
                        <Box onClick={() => props.handleChangeActiveMenu("dropDownMedia")}>
                            <Typography sx={styles.navItem}>Médias</Typography>
                        </Box>
                    </Grid>
    
                    <BuildMedia styles={styles} dropDownMedia={props.dropDownMedia} buildLink={buildLink} />
    
                    <Grid item>
                        <Box onClick={() => props.handleChangeActiveMenu("dropDownInfo")}>
                            <Typography sx={styles.navItem}>Infos Pratiques</Typography>
                        </Box>
                    </Grid>
    
                    <BuildContactAcces styles={styles} dropDownInfo={props.dropDownInfo} dropDownInfoType={props.dropDownInfoType} handleChangeActiveMenu={props.handleChangeActiveMenu} buildSubLink={buildSubLink} />
                    <BuildLinkFoot styles={styles} dropDownInfo={props.dropDownInfo} dropDownInfoType={props.dropDownInfoType} handleChangeActiveMenu={props.handleChangeActiveMenu} />
                    <BuildLinkHand styles={styles} dropDownInfo={props.dropDownInfo} dropDownInfoType={props.dropDownInfoType} handleChangeActiveMenu={props.handleChangeActiveMenu} />
                    <BuildPartner styles={styles} dropDownInfo={props.dropDownInfo} dropDownInfoType={props.dropDownInfoType} handleChangeActiveMenu={props.handleChangeActiveMenu} buildLink={buildLink} />
    
                   <Grid item>
                        <Box onClick={() => props.handleChangeActiveMenu("dropDownProno")}>
                            <Typography sx={styles.navItem}>Jeu Pronos</Typography>
                        </Box>
                    </Grid>
    
                    <BuildProno styles={styles} dropDownProno={props.dropDownProno} handleChangeActiveMenu={props.handleChangeActiveMenu} buildSubLink={buildSubLink} />
    
                    {props.isAdmin &&
                    <Grid item>
                        <Link to={"/categories"} component={RouterLink} onClick={() => props.handleChangeActiveMenu("menu")} state={{prevUrl: location.pathname}}>
                            <Typography sx={styles.navItem}>Categories</Typography>
                        </Link>
                    </Grid>}
    
                    {props.isAdmin &&
                    <Grid item>
                        <Link to={"/equipes"} component={RouterLink} onClick={() => props.handleChangeActiveMenu("menu")} state={{prevUrl: location.pathname}}>
                            <Typography sx={styles.navItem}>Equipes</Typography>
                        </Link>
                    </Grid>
                    }
    
                    {props.isAdmin &&
                    <Grid item>
                        <Link to={"/saisons"} component={RouterLink} onClick={() => props.handleChangeActiveMenu("menu")} state={{prevUrl: location.pathname}}>
                            <Typography sx={styles.navItem}>Saisons</Typography>
                        </Link>
                    </Grid>
                    }
    
                    {props.isAdmin &&
                    <Grid item>
                        <Link to={"/evenements"} component={RouterLink} onClick={() => props.handleChangeActiveMenu("menu")} state={{prevUrl: location.pathname}}>
                            <Typography sx={styles.navItem}>Événements</Typography>
                        </Link>
                    </Grid>}

                    {(props.isAdmin || props.isAdminShop) &&
                    <Grid item>
                        <Link to={"/boutique-commande"} component={RouterLink} onClick={() => props.handleChangeActiveMenu("menu")} state={{prevUrl: location.pathname}}>
                            <Typography sx={styles.navItem}>Gestion commande</Typography>
                        </Link>
                    </Grid>}
    
                    {props.isAuth &&
                    <Grid item>
                        <Link to={"/mon-compte"} component={RouterLink} onClick={() => props.handleChangeActiveMenu("menu")} state={{prevUrl: location.pathname}}>
                            <Typography sx={styles.navItem}>Mon compte</Typography>
                        </Link>
                    </Grid>
                    }
    
                    <Grid item>
                        {props.isAuth &&
                        <Box onClick={() => {
                            props.signOut();
                            props.handleChangeActiveMenu("menu");
                        }}>
                            <Typography sx={styles.navItem}>Déconnexion</Typography>
                        </Box>
                        }
                        {!props.isAuth &&
                        <Link to={"/connexion"} component={RouterLink} onClick={() => props.handleChangeActiveMenu("menu")} state={{prevUrl: location.pathname}}>
                            <Typography sx={styles.navItem}>Connexion</Typography>
                        </Link>
                        }
                    </Grid>
                </Grid>
            </Box>
        )
    } else {
        return(
            <Box/>
        );
    }
 
}