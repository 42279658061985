import React, {useState} from "react";
import 'moment/locale/fr';
import { TextField, Grid  } from "@mui/material";

import { Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle } from "../../component/Popup/Popup";

export default function AddUpdateCategoryPopup(props) {
    const [category, setCategory] = useState(props.category ?
        {
            id: props.category.id,
            name: props.category.name,
        } : {
            name: "",
    });
    const [error, setError] = useState(false);

    function handleSubmit() {
        let tmpCategory = category;

        if (category.name == null || category.name === "") {
            setError(true);
            return;
        }
       props.submit(tmpCategory);
    }

    return (
        <Popup size={"large"} close={props.close}>
            <PopupHeader>
                <PopupTitle>{props.category ? "Modifier une categorie" : "Créer une nouvelle categorie"}</PopupTitle>
            </PopupHeader>

            <PopupBody>
                <Grid container direction={"column"} justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item>
                        <TextField sx={{margin: "1rem"}} inputProps={{min: 0, style: { textAlign: 'center' }}} type={"text"} label={"Nom de la categorie"} defaultValue={category.name} onChange={(event) => {
                            let tmp = category;
                            tmp.name = event.target.value;
                            setCategory(tmp);
                        }}/>
                    </Grid>
                </Grid>

                {error && props.category == null &&<small className={"text-danger col-12"}>Veuillez remplir tous les champs</small>}
                {error && props.category && <small className={"text-danger col-12"}>Le champ du nom d'équipe est obligatoire</small>}
            </PopupBody>

            <PopupFooter dismiss={"Annuler"} close={props.close}>
                <input className={"btn btn-primary"} type={"submit"} value={"Valider"}
                       onClick={() => {handleSubmit()}}/>
            </PopupFooter>
        </Popup>
    );
}