
import React, {useEffect} from "react";

import ReactGA from "react-ga4";

export default function Acces(props) {
    const styles = {
        accesContainer: {
            height: "100vh"
        },
        accesIframe: {
            height: "100%",
            width: "100%"
        }
    };

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    return (
        <div style={styles.accesContainer}>
            <h3>Accès aux infrastructures sportives</h3>
            <iframe title={"Plan d'accès"} style={styles.accesIframe} src="https://www.google.com/maps/d/u/0/embed?mid=1QhIa1BJtV-d43wuchk7vKR21kcYjT36J"/>
        </div>
    )
}
