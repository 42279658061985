import React, {useEffect, useState} from 'react';
import {Box, Button, Grid, useTheme, Link } from "@mui/material";
import { Fade } from 'react-slideshow-image';
import moment from 'moment';

import 'react-slideshow-image/dist/styles.css'
import ApiClient from "../../service/ApiClient";
import Article from "../../component/Article/Article";
import UpdateScorePopup from "./UpdateScorePopup";
import ReactGA from "react-ga4";

import "./Accueil.scss"
import ScrollText from "../../component/ScrollText/ScrollText";

const fadeProperties = {
    duration: 2000,
    transitionDuration: 500,
    infinite: true,
    arrows: false,
};

export default function Accueil(props) {
    const theme = useTheme();
    const [article, setArticle] = useState(null);
    const [articlePic, setArticlePic] = useState([]);
    const [partner, setPartner] = useState(null);
    const [lastMatch, setLastMatch] = useState(null);
    const [nextMatch, setNextMatch] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [event, setEvent] = useState(null);
    const [showEvent, setShowEvent] = useState(true);
    const [showAgain] = useState(true);

    const styles = {
        leftTitle: {
            display: "inline-block"
        },
        lineTitle: {
            position: "relative",
            zIndex: "2",
            textTransform: "uppercase",
            marginBottom: "1rem",
            '&:after': {
                marginLeft: "1rem",
                position: "absolute",
                width: "50%",
                height: "2px",
                backgroundColor: "black",
                bottom: "12px",
                content: "''",
                zIndex: "2",
                borderBottom: "none!important",
                [theme.breakpoints.up('md')]: {
                    width: "45%",
                },
                [theme.breakpoints.up('lg')]: {
                    width: "60%",
                },
    
            },
        },
        lineTitleResult: {
            marginTop: "2rem",
            position: "relative",
            zIndex: "2",
            textTransform: "uppercase",
            marginBottom: "1rem",
            '&:after': {
                marginLeft: "1rem",
                position: "absolute",
                width: "43%",
                height: "2px",
                backgroundColor: "black",
                bottom: "12px",
                content: "''",
                zIndex: "2",
                borderBottom: "none!important",
                [theme.breakpoints.up('md')]: {
                    width: "38%",
                },
                [theme.breakpoints.up('lg')]: {
                    width: "53%",
                },
    
            },
        },
        lineTitleGreen: {
            color:  theme.palette.colors.green,
            fontWeight: "bold",
            textTransform: "uppercase",
            '&:after': {
                marginLeft: "1rem",
                position: "absolute",
                width: "50px",
                height: "2px",
                backgroundColor: theme.palette.colors.green,
                bottom: "12px",
                content: "''",
                zIndex: "3",
                borderBottom: "none!important"
            },
        },
        subtitleLeft: {
            fontWeight: "bold"
        },
        contentLeft: {
            '& span': {
                fontWeight: "bold"
            }
        },
        contactText: {
            display: "inline-flex",
            color: "black",
            '&:hover': {
                color: theme.palette.colors.green + "!important",
                textDecoration: "none!important"
            },
        },
    }
        

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        (async () => {
            try {
                await loadData();
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    async function loadData() {
        let result = await ApiClient.instance.getLastEvenement();
        if (result.data.length !== 0) {
            setEvent(result.data[0]);
            if (parseInt(localStorage.getItem('event')) === parseInt(result.data[0].Id) || parseInt(sessionStorage.getItem('event')) === parseInt(result.data[0].Id)) {
                setShowEvent(false);
            }
        }
        result = await ApiClient.instance.getPartner(true);
        setPartner(result.data);
        result = await ApiClient.instance.getLastArticle();
        setArticle(result.data[0]);
        result = await ApiClient.instance.getArticlesPic(result.data[0].id);
        setArticlePic(result.data);
        result = await ApiClient.instance.getLastMatch();
        setLastMatch(result.data);
        result = await ApiClient.instance.getNextMatch("all");
        setNextMatch(result.data);
    }

    function buildSlide() {
        let listSlide = [];

        for (let i = 0; i < partner.length; i++){
            listSlide.push(
                <div className="each-fade" key={i}>
                    <div className="image-container">
                        <img alt={"Article"} src={process.env.REACT_APP_API_URL + partner[i].Picture}/>
                    </div>
                </div>);
        }
        return (listSlide);
    }

    function slideShow() {
        return (
            <div className="slide-container">
                <Fade {...fadeProperties}>
                    {buildSlide()}
                </Fade>
            </div>
        )
    }

    function buildTextLastMacth(type) {
        let text = [];
        for (let i = 0; i < lastMatch.length; i++) {
            if (lastMatch[i].id !== null) {
                text += lastMatch[i].category + ": " + lastMatch[i].team1.name + " - " + lastMatch[i].team2.name  + " : " + lastMatch[i].team1.score  + " - " + lastMatch[i].team2.score + " | ";
            }
        }
        return text;
    }

    function handleCloseEvent(){
        if (!showAgain) {
            localStorage.setItem('event', event.Id);
        }
        sessionStorage.setItem('event', event.Id);
        setShowEvent(false);
    }

    if (partner !== null && lastMatch !== null) {
        return (
            <Box>
                { showEvent && event !== null &&
                <Box className={"eventPopup"}>
                    <Box className={"eventContainer"}>
                        <Box className={"eventImgContainer"}>
                            <img className={"eventImg"} alt={"Event"} src={encodeURI(process.env.REACT_APP_API_URL + event.picture)}/>
                        </Box>
                        <Box className={"eventActionContainer"}>
                            <Grid container justifyContent={"space-evenly"} alignItems={"center"}>
                            {  event !== null && moment(event.saleEnd) >= new moment() &&
                                <Grid item>
                                    <Button variant="contained" color="secondary">
                                        <Link sx={{textDecoration: "none!important", color: "white", '&:hover': {
                                            color: "white!important"}}} href={event.saleLink}  target={"_blank"} rel="noreferrer">
                                            Réserver
                                        </Link>
                                       
                                    </Button>
                                </Grid>
                            }
                                <Grid item>
                                    <Button variant="contained" color="secondary" onClick={() => handleCloseEvent()}>
                                        Fermer
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
                }
                <Grid container justifyContent={"center"} alignItems={"center"}>
                    <Grid item container height={1} className={"container"} style={props.dimensions < 960 && lastMatch.length !== 0 ? {marginTop: "2rem"} : {}} direction={props.dimensions > 960 ? "row" : "column"} justifyContent={"center"} spacing={1}>
                        {props.dimensions < 960 && lastMatch !== null && lastMatch.length !== 0 &&
                        <Box className={"lastMatch_sm"}>
                            <ScrollText text={buildTextLastMacth("sm")} title={"Derniers résultats: "} dimensions={props.dimensions}/>
                            {props.isAdmin &&
                            <button className={"actionButton"}
                                    onClick={() => {
                                        setShowPopup(true);
                                    }}><i className="material-icons">edit</i></button>
                            }
                        </Box>
                        }
                        <Grid item container md={9} sm={10} justifyContent={"center"}>
                            {article !== null && articlePic != null &&
                            <Article id={article.id} article={article} articlePic={articlePic}/>
                            }
                            {props.dimensions < 960 &&  event !== null && moment(event.saleEnd) >= new moment() &&
                                <Grid item style={{overflow: "hidden", height: "500px", marginTop: "1rem"}}>
                                    <iframe id={"haWidget"} allowTransparency={"true"} src={event.saleLink + (event.saleLink.substr(event.saleLink.length - 1) === "/" ? "widget-vignette" : "/widget-vignette")} style={{width: "350px", height: "500px", border: "none"}}/>
                                </Grid>
                            }
                        </Grid>
                        {props.dimensions > 960 &&
                        <Grid container direction={"column"} item md={3}>
                            { event !== null && moment(event.saleEnd) >= new moment() &&
                            <Grid item style={{overflow: "hidden", height: "500px"}}>
                                <iframe id={"haWidget"} allowTransparency={"true"} src={event.saleLink + (event.saleLink.substr(event.saleLink.length - 1) === "/" ? "widget-vignette" : "/widget-vignette")} style={{width: "350px", height: "500px", border: "none"}}/>
                            </Grid>
                            }
                           

                            <Grid item>
                                <Box sx={styles.lineTitle}>
                                    <h5 style={styles.leftTitle}>Nos <span style={styles.lineTitleGreen}>Partenaires</span></h5>
                                </Box>
                            </Grid>

                            <Grid container item>
                                <Grid item xs>
                                    {slideShow()}
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Box sx={styles.lineTitleResult}>
                                    <h5 style={styles.leftTitle}>Derniers <span style={styles.lineTitleGreen}>Résultats</span></h5>
                                    {props.isAdmin &&
                                    <button className={"actionButton"}
                                            onClick={() => {
                                                setShowPopup(true);
                                            }}><i className="material-icons">edit</i></button>
                                    }
                                </Box>
                            </Grid>

                            <Grid container item>
                                <Grid item xs>
                                    <ScrollText text={buildTextLastMacth()} title={""} dimensions={props.dimensions}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        }
                    </Grid>
                    {showPopup &&
                    <UpdateScorePopup close={async () =>{
                        setShowPopup(false);
                        await loadData();
                    }} lastMatch={lastMatch} nextMatch={nextMatch} createNotification={props.createNotification}/>
                    }
                </Grid>
            </Box>
        );
    } else {
        return <Box/>
    }
}
