import { useState, useEffect } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { useTheme } from '@emotion/react';
import ReactGA from "react-ga4";
import DoneIcon from '@mui/icons-material/Done';

import ApiClient from "../../service/ApiClient";
import Spinner from "../../component/Spinner/Spinner";

export default function OrderSucces(props) {
    const theme = useTheme();
    const [order, setOrder] = useState(null);

    const styles = {
        succesOrderIcon: {
            height: "50px",
            width: "50px",
            color: theme.palette.colors.green
        },
        succesOrderTitle: {
            fontSize: "20px",
            fontWeight: "bold",
            margin: "1rem"
        },
        succesOrderText: {
            color: "black",
            fontSize: "15px"
        },
        succesOrderInfoText: {
            color: "black",
            fontSize: "15px",
            marginTop: "1rem"
        },
        overlayContainer: {
            position: "fixed",
            top: 0,
            left: 0,
            right:0,
            bottom:0,
        },
        overlay: {
            width: "100%",
            height: "100%",
            backgroundColor: "#878787",
            opacity: "50%",
            
        },
        spinner: {
            position: "fixed",
            top: 0,
            left: 0,
            right:0,
            bottom:0,
        },
    }

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        (async () => {
            try {
                await loadData();
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    async function loadData() {
        try {
            const paymentIntent = new URLSearchParams(window.location.search).get(
                "payment_intent"
              );
            let result = await ApiClient.instance.getShopOrder(paymentIntent);
            setOrder(result.data[0]);
        } catch (error) {
            console.log(error);
        }
    }

    return (<Grid container item>
        {order !== null && order?.order_id !== undefined &&
        <Grid container item justifyContent={"center"}>
            <Grid container item direction={"column"} sx={styles.emptyCartContainer} xs={8} alignItems={"center"}>
                <Grid item>
                    <Typography sx={styles.succesOrderTitle}><DoneIcon sx={styles.succesOrderIcon}/> Merci pour votre commande N° {order.order_id}</Typography>
                </Grid>

                <Grid item>
                    <Typography sx={styles.succesOrderText}>Un email de confirmation sera envoyé à {order.email} </Typography>
                </Grid>

                <Grid item>
                    <Typography sx={styles.succesOrderInfoText}><b>Important</b>: Le retrait de votre commande se fera au complexe sportif, le dimanche, lors des matchs à domicile et dès réception du mail vous avertissant que votre commande est prête</Typography>
                </Grid>
            </Grid>
        </Grid>
        }

        { order === null && 
            <Box sx={styles.overlayContainer}>
                <Box sx={styles.overlay}>

                </Box>
                <Grid container sx={styles.spinner} justifyContent={"center"} alignItems={"center"}>
                    <Grid item>
                        <Spinner />
                    </Grid>
                </Grid>
                
            </Box>
            }
    </Grid>);
    
}