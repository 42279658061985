import React, {useEffect, useState} from 'react';
import moment from 'moment';
import { Grid, Typography, Box, useTheme } from "@mui/material";


import { Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle } from "../../component/Popup/Popup";
import AddArticlePopup from "./Modal/AddArticlePopup";
import UpdateArticlePopup from "./Modal/UpdateArticlePopup";
import SimpleSelect from "../../component/SelectDropDown/SimpleSelect";
import ApiClient from "../../service/ApiClient";
import {useParams} from "react-router-dom";
import ReactGA from "react-ga4";

export default function Actualite(props) {
    const params = useParams();
    const theme = useTheme();
    const [articleYear, setArticleYear] = useState(null);
    const [articles, setArticles] = useState(null);
    const [actualArticle, setActualArticle] = useState(null);
    const [actualYear, setActualYear] = useState(null);
    const [actualId, setActualId] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [confirmPopup, setConfirmPopup] = useState(false);

    const styles = {
        addArticle: {
            position: "fixed",
            bottom: "75px",
            right: "0px",
            alignItems: "center",
            display: "block",
            '& button': {
                backgroundColor: "transparent",
                border: "none"
            },
            '& i': {
                color: theme.palette.colors.green,
                fontSize: "60px"
            }
        },
        articleContainer: {
            cursor: "pointer",
            height: "350px",
            margin: "1rem",
            backgroundColor: theme.palette.colors.whiteBox,
            '&:hover': {
                background: "black",
                color: "white"
            },
        },
        articleImageContainer: {
            cursor: "pointer",
            height: "350px",
            margin: "1rem",
            backgroundColor: theme.palette.colors.whiteBox,
            '&:hover': {
                background: "black",
                color: "white"
            },
        },
        articleDate: {
            backgroundColor: theme.palette.colors.green,
            color: "white",
            padding: "5px",
            fontSize: "15px",
            fontWeight: "600"
        },
        actionButton: {
            color: theme.palette.colors.green,
            border: "none",
            backgroundColor: "rgba(255, 255, 128, 0)",
            paddingTop: "5px",
            '&:hover': {
                color: "white"
            },
        },
        article: {
            overflow: "hidden",
            height: "150px",
            fontSize: '1rem'
        },
        seeMore: {
            color: theme.palette.colors.green,
            fontWeight: "bold",
            textDecoration: "underline",
            textDecorationThickness: "2px",
        }
    };

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        (async () => {
            try {
                await loadData();
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    async function loadData() {
        let result = await ApiClient.instance.getArticlesYear();
        setArticleYear(result.data);
        for (let i = 0; i < result.data.length; i++) {
            if (result.data[i].name === params.year)
                setActualYear(result.data[i].value);
        }
        if (props.isAdmin) {
            result = await ApiClient.instance.getArticles("", params.year, {programedArticle: true});
            setArticles(result.data);
        } else {
            result = await ApiClient.instance.getArticles("", params.year, {programedArticle: false});
            setArticles(result.data);
        }
    }

    function handleYear(idYear) {
            setActualYear(idYear);
            for (let i = 0; i < articleYear.length; i++)
                if (articleYear[i].value === idYear) {
                    window.location.href = '/actu/' + articleYear[i].name;
                }
    }

    async function closePopup(type) {
        if (type === "PopupModify")
            setActualArticle(null);
        else
            setShowPopup(false);
        await loadData();
    }

    async function modifyArticle(id, article, selectedDay) {
        try {
            article.date = new moment(selectedDay).format("YYYY-MM-DD HH:mm:ss");
            article.year = new moment(selectedDay).format("YYYY").toString();
            await ApiClient.instance.updateArticle(id, article);
            props.createNotification("success", {title: "Succès", message: "Article mis à jour", timeout: 3000})
            await closePopup("PopupModify");
            await loadData();
        } catch (e) {
            props.createNotification("error", {title: "Erreur", message: "Article non mis à jour", timeout: 3000})
            console.log(e);
        }
    }

    function buildArticleImage(article) {
        let encodedUri = encodeURI(process.env.REACT_APP_API_URL + article.firstPicture).replaceAll("'", "\\'");
        let date = moment(article.date).format("D/MM/YY");
        return (
            <Box sx={styles.articleImageContainer} style={{
                background: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(" + encodedUri + ")", backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat'}} onClick={() => {
                for (let i = 0; i < articleYear.length; i++)
                    if (articleYear[i].value === actualYear) {
                        window.location.href = '/actu/' + articleYear[i].name + '/' + article.id;
                    }
            }}>
                <Grid container direction={"column"} alignItems={"center"} justifyContent={"space-between"} style={{height: "350px"}}>
                    <Grid container item direction={"row"} justifyContent={"space-between"}>
                        <Grid item><span style={styles.articleDate}>{date}</span></Grid>
                        {props.isAdmin &&
                        <Grid item>
                            <button style={styles.actionButton}
                                    onClick={(event) => {
                                        setConfirmPopup(true);
                                        setActualId(article.id);
                                        event.stopPropagation();
                                    }}><i className="material-icons">delete</i></button>
                            <button style={styles.actionButton}
                                    onClick={(event) => {
                                        setActualArticle(article);
                                        event.stopPropagation();
                                    }}><i className="material-icons">edit</i></button>
                        </Grid>}
                    </Grid>
                    <Grid container item justifyContent={"flex-start"} alignItems={"center"}>
                        <Grid item>
                            <Typography variant={"h4"} style={{color: "white", marginBottom: "1rem"}}>{article.title}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    function buildArticle(article) {
        let dateTmp = moment(article.date);
        let date = dateTmp.format("D/MM/YY");
        return (
            <Box sx={styles.articleContainer} onClick={() => {
                for (let i = 0; i < articleYear.length; i++)
                    if (articleYear[i].value === actualYear) {
                        window.location.href = '/actu/' + articleYear[i].name + '/' + article.id;
                    }
            }}>
                <Grid container direction={"column"} alignItems={"center"} justifyContent={"space-between"} style={{height: "350px"}}>
                    <Grid container item direction={"row"} justifyContent={"space-between"}>
                        <Grid item><span style={styles.articleDate}>{date}</span></Grid>
                        {props.isAdmin &&
                        <Grid item>
                            <button style={styles.actionButton}
                                    onClick={(event) => {
                                        setConfirmPopup(true);
                                        setActualId(article.id);
                                        event.stopPropagation();
                                    }}><i className="material-icons">delete</i></button>
                            <button style={styles.actionButton}
                                    onClick={(event) => {
                                        setActualArticle({...article});
                                        event.stopPropagation();
                                    }}><i className="material-icons">edit</i></button>
                        </Grid>}
                    </Grid>
                    <Grid container item direction={"column"} alignItems={"center"} justifyContent={"flex-start"} style={{height: "300px"}}>
                        <Grid container item direction={"row"} justifyContent={"center"} alignItems={"flex-start"}>
                            <Grid container direction={"column"} justifyContent={"space-between"} alignItems={"flex-start"} item xs={11} style={{height: "250px"}}>
                                <Grid item><Typography variant={"h6"}>{article.title}</Typography></Grid>
                                <Grid item sx={styles.article}>{article.article}</Grid>
                                <Grid item><Typography sx={styles.seeMore} variant={"h5"}>Voir plus...</Typography></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    function buildRow(nbArticlePerRow) {
        let row = [];
        let tabArticle = [];
        for (let i = 0; i < articles.length; i++) {
            for (let count = 0; count < nbArticlePerRow &&  i < articles.length; count++) {
                tabArticle.push(<Grid item xs key={articles[i].id}>{articles[i].article === null ? buildArticleImage(articles[i], nbArticlePerRow, i) : buildArticle(articles[i], nbArticlePerRow, i)}</Grid>);
                i++
                if (i === articles.length && count + 1 !== nbArticlePerRow && nbArticlePerRow !== 1) {
                    tabArticle.push(<Grid item xs key={-1}/>);
                }
            }
            row.push(<Grid container item direction={"row"} justifyContent={"space-between"} key={i}>{tabArticle}</Grid>);
            tabArticle = [];
            i--;
        }
        return (<Grid container direction={"column"}>{row}</Grid>);
    }

    if (articles === null)
        return (<Box/>)
    return (
        <Grid container justifyContent={"center"} alignItems={"stretch"} direction={"column"}>
            <Grid item container direction={"row"} justifyContent={"flex-end"}>
                <Grid item>
                    <SimpleSelect data={articleYear} onChange={handleYear} actualIndex={actualYear} textLabel={"Année"}/>
                </Grid>
            </Grid>
            <Grid item>
                {buildRow((window.innerWidth >= 768 ? 2 : 1))}
            </Grid>
            {showPopup && <AddArticlePopup close={closePopup} createNotification={props.createNotification}/>}
            {actualArticle !== null && <UpdateArticlePopup article={actualArticle} close={closePopup} modify={modifyArticle}/>}
            {confirmPopup &&
            <Popup close={() => {
                setConfirmPopup(false);
            }}>
                <PopupHeader>
                    <PopupTitle>Supprimer un article</PopupTitle>
                </PopupHeader>
                <PopupBody>
                    <p>Voulez-vous vraiment supprimer cet article ?</p>
                </PopupBody>
                <PopupFooter dismiss={"Non"} close={() => {
                    setConfirmPopup(false);
                }}>
                    <button className={"btn btn-primary"} onClick={async() => {
                        try {
                            await ApiClient.instance.deleteArticlePic(actualId);
                            await ApiClient.instance.deleteArticle(actualId);
                            props.createNotification("success", {title: "Succès", message: "Article supprimé", timeout: 3000})
                            setConfirmPopup(false);
                            await loadData();
                        } catch (e) {
                            props.createNotification("error", {title: "Erreur", message: "Article non supprimé", timeout: 3000})
                            console.log(e);
                        }
                    }}>Oui
                    </button>
                </PopupFooter>
            </Popup>}
            {props.isAdmin &&
            <Box sx={styles.addArticle}>
                <button className={"no-button"} onClick={() => {
                    setShowPopup(true);
                }}>
                    <i className="material-icons">add_circle</i>
                </button>
            </Box>}
        </Grid>);
}
