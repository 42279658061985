import React, { useState, useEffect } from 'react';
import {
    FilledInput,
    FormControl, FormControlLabel, FormHelperText, FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel, RadioGroup,
    TextField, Tooltip,
    Typography,
    useTheme
 } from "@mui/material";

import ApiClient from "../../service/ApiClient";
import ReactGA from "react-ga4";

import '../../assets/Inscription.scss'
import {Visibility, VisibilityOff} from "@mui/icons-material";
import HelpIcon from '@mui/icons-material/Help';
import Radio from '@mui/material/Radio';

export default function Inscription(props) {
    const theme = useTheme();
    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [pseudo, setPseudo] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [pseudoError, setPseudoError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [visibilityPassword, setVisibilityPassword] = useState(false);
    const [visibilityConfirmPassword, setVisibilityConfirmPassword] = useState(false);
    const [isPasswordLong, setIsPasswordLong] = useState(false);
    const [isPasswordUppercase, setIsPasswordUppercase] = useState(false);
    const [isPasswordNumber, setIsPasswordNumber] = useState(false);
    const [isRegister, setIsRegister] = useState(false);
    const [notificationMail, setNotificationMail] = useState(null);
    const [notificationMailError, setNotificationMailError] = useState("");

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    async function getPseudoAvailable() {
        try {
            if (pseudo === "") {
                return true;
            }
            let result = await ApiClient.instance.isPseudoAvailable(pseudo);
            result.data.isPseudoAvailable ? setPseudoError("") : setPseudoError("Pseudo non disponible");
            return result.data.isPseudoAvailable;
        } catch (e) {
            console.log(e);
        }
    }

    async function handleSubmitRegister() {
        try {
            const mailRegex = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$");
            let isPseudoAvailable = await getPseudoAvailable();
            let error = false;

            firstName === "" ? setFirstNameError("Ce champ est nécessaire") : setFirstNameError("");
            if (firstName === "") {
                error = true;
            }
            lastName === "" ? setLastNameError("Ce champ est nécessaire") : setLastNameError("");
            if (lastName === "") {
                error = true;
            }
            if (isPseudoAvailable) {
                pseudo === "" ? setPseudoError("Ce champ est nécessaire") : setPseudoError("");
            }

            if (!isPseudoAvailable || pseudo === "") {
                error = true;
            }
            email === "" ? setEmailError("Ce champ est nécessaire") : (!mailRegex.test(email)) ? setEmailError("Veuillez saisir une adresse e-mail valide.") : setEmailError("");
            if (email === "" || !mailRegex.test(email)) {
                error = true;
            }
            !isPasswordLong || !isPasswordNumber || !isPasswordUppercase ? setPasswordError("Votre mot de passe ne répond pas au critère de sécurité") : setPasswordError("");
           if (!isPasswordLong || !isPasswordNumber || !isPasswordUppercase) {
               error = true;
           }
            password !== confirmPassword ? setConfirmPasswordError("Les mots de passes ne sont pas identique") : setConfirmPasswordError("");
            if (password !== confirmPassword) {
                error = true;
            }
            notificationMail === null ? setNotificationMailError("Veuillez indiquer vos préférences pour l'envoie de mail") : setNotificationMailError("");
            if (notificationMail === null) {
                error = true;
            }
            if (!error) {
                let data = {
                    LastName: lastName,
                    FirstName: firstName,
                    Email: email,
                    Pseudo: pseudo,
                    Password: password,
                    IsNotificationMail: notificationMail
                }
                await ApiClient.instance.register(data);
                props.createNotification("success", {title: "Succès", message: "Votre compte a bien été créé", timeout: 3000});
                setIsRegister(true);
            }
        } catch (err) {
            props.createNotification("error", {title: "Erreur", message: "Email ou mot de passe incorrect", timeout: 3000});
        }
    }

    function handleChangePassword(value) {
        value.length >= 8 ? setIsPasswordLong(true) : setIsPasswordLong(false);
        /\d/.test(value) ? setIsPasswordNumber(true) : setIsPasswordNumber(false);
        /[A-Z]/.test(value) ? setIsPasswordUppercase(true) : setIsPasswordUppercase(false);
        setPassword(value);
    }

    if (isRegister) {
        return (
            <Grid container direction={"row"} justifyContent={"center"} alignItems={"center"} style={{height: "100%"}}>
                <Grid className={"register-container"} container item direction={"column"} justifyContent={"space-between"} alignItems={"center"} xs={11} md={9} lg={6}>
                    <Grid className={"register-title"} item><Typography variant={"h4"} style={{marginLeft: "0"}}>Inscription</Typography></Grid>
                    <Grid className={"auth-register"} item>
                        <Typography variant={"h5"}>
                            Afin de finaliser votre inscription, veuillez vérifier votre email. Si vous ne recevez pas le mail, pensez à vérifier dans vos courriers indésirables.<br/>
                            <a href={"/connexion"}>Revenir à la page de connexion</a>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Grid container direction={"row"} justifyContent={"center"} alignItems={"center"} style={{height: "100%"}}>
                <Grid className={"register-container"} container item direction={"column"} justifyContent={"space-between"} alignItems={"center"} xs={11} md={9} lg={6}>
                    <Grid className={"register-title"} item><Typography variant={"h4"} style={{marginLeft: "0"}}>Créez votre compte</Typography></Grid>
                    <Grid className={"register-input"} container item justifyContent={"center"}>
                        <Grid item xs={6}>
                            <TextField
                                autoComplete="family-name"
                                fullWidth
                                required
                                id="lastName"
                                label="Nom"
                                variant="filled"
                                value={lastName}
                                helperText={lastNameError}
                                onChange={event => {
                                    setLastName(event.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid className={"register-input"} container item justifyContent={"center"}>
                        <Grid item xs={6}>
                            <TextField
                                autoComplete="given-name"
                                fullWidth
                                required
                                id="firstName"
                                label="Prénom"
                                variant="filled"
                                value={firstName}
                                helperText={firstNameError}
                                onChange={event => {
                                    setFirstName(event.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid className={"register-input"} container item justifyContent={"center"} alignItems={"center"}>
                        <Grid item xs={6}>
                            <FormControl variant="filled" fullWidth helperText={pseudoError}>
                                <InputLabel required htmlFor="filled-adornment-pseudo">Pseudo</InputLabel>
                                <FilledInput
                                    id="pseudo"
                                    type={'text'}
                                    value={pseudo}
                                    helperText={pseudoError}
                                    onChange={(event) => { setPseudo(event.target.value); }}
                                    onBlur={getPseudoAvailable}
                                    endAdornment={
                                        <InputAdornment position="end" id={"pseudo"}>
                                            <Tooltip title="Le pseudo sera visible par tout le monde." enterTouchDelay={0}>
                                            <IconButton>
                                                <HelpIcon />
                                            </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>{pseudoError}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid className={"register-input"} container item justifyContent={"center"}>
                        <Grid item xs={6}>
                            <TextField
                                autoComplete="email"
                                fullWidth
                                required
                                id="email"
                                label="Email"
                                variant="filled"
                                value={email}
                                helperText={emailError}
                                onChange={event => {
                                    setEmail(event.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid className={"register-input"} container item justifyContent={"center"}>
                        <Grid item xs={6}>
                            <FormControl variant="filled" fullWidth helperText={passwordError}>
                                <InputLabel required htmlFor="filled-adornment-password">Mot de passe</InputLabel>
                                <FilledInput
                                    id="password"
                                    type={visibilityPassword ? 'text' : 'password'}
                                    value={password}
                                    helperText={passwordError}
                                    onChange={(event) => { handleChangePassword(event.target.value) }}
                                    endAdornment={
                                        <InputAdornment position="end" id={"password"}>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => { setVisibilityPassword(!visibilityPassword)}}
                                                onMouseDown={(event) => { event.preventDefault() }}
                                            >
                                                {visibilityPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>{passwordError}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <ul className={"password-requirement-container"}>
                        <li className={"password-requirement-title"}>Votre mot de passe doit contenir :</li>
                        <li className={"password-requirement-item-" + (isPasswordLong ? "valid" : "invalid")}>
                        <span className={"check-container"}>
                            <svg role="presentation" width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            { isPasswordLong ?
                                <path className="check-valid" d="M10.27.317l1.46 1.366-7.21 7.702L.346 5.777l1.308-1.513 2.72 2.35L10.27.317z" stroke-width="2"></path>
                                :
                                <path className="check-invalid" d="M1 1l10 10M11 1L1 11" stroke-width="2"></path> }
                            </svg>
                        </span>
                            Au minimum 8 caractères</li>
                        <li className={"password-requirement-item-" + (isPasswordUppercase ? "valid" : "invalid")}>
                        <span className={"check-container"}>
                        <svg role="presentation" width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            { isPasswordUppercase ?
                                <path className="check-valid" d="M10.27.317l1.46 1.366-7.21 7.702L.346 5.777l1.308-1.513 2.72 2.35L10.27.317z" stroke-width="2"></path>
                                :
                                <path className="check-invalid" d="M1 1l10 10M11 1L1 11" stroke-width="2"></path> }
                        </svg>
                    </span>
                            Une majuscule</li>
                        <li className={"password-requirement-item-" + (isPasswordNumber ? "valid" : "invalid")}>
                        <span className={"check-container"}>
                        <svg role="presentation" width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            { isPasswordNumber ?
                                <path className="check-valid" d="M10.27.317l1.46 1.366-7.21 7.702L.346 5.777l1.308-1.513 2.72 2.35L10.27.317z" stroke-width="2"></path>
                                :
                                <path className="check-invalid" d="M1 1l10 10M11 1L1 11" stroke-width="2"></path> }
                        </svg>
                    </span>
                            Un nombre</li>
                    </ul>
                    <Grid className={"register-input"} container item justifyContent={"center"}>
                        <Grid item xs={6}>
                            <FormControl variant="filled" fullWidth helperText={confirmPasswordError}>
                                <InputLabel htmlFor="filled-adornment-password" required>Confirmer mot de passe</InputLabel>
                                <FilledInput
                                    id="confirmPassword"
                                    type={visibilityConfirmPassword ? 'text' : 'password'}
                                    value={confirmPassword}
                                    onChange={(event) => { setConfirmPassword(event.target.value) }}
                                    helperText={confirmPasswordError}
                                    endAdornment={
                                        <InputAdornment position="end" id={"confirmPassword"}>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => { setVisibilityConfirmPassword(!visibilityConfirmPassword)}}
                                                onMouseDown={(event) => { event.preventDefault() }}
                                            >
                                                {visibilityConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>{confirmPasswordError}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid className={"register-input"} container item justifyContent={"center"}>
                            <Grid item xs={6}>
                            <FormControl>
                                <FormLabel id="demo-controlled-radio-buttons-group" sx={{'&.Mui-focused': {
                                        color: "#565656",
                                    }}}>J'accepte de recevoir les communications de l'US Méné Bré Louargat <span style={{color: theme.palette.colors.green, fontWeight: "bold"}}>*</span></FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    onChange={(event) => {
                                        setNotificationMail(event.target.value === "true");
                                    }}
                                    value={notificationMail}
                                >
                                    <FormControlLabel value={true} control={<Radio sx={{
                                        '&.Mui-checked': {
                                            color: theme.palette.colors.green,
                                        },
                                    }} />} label="Oui" />
                                    <FormControlLabel value={false} control={<Radio sx={{
                                        '&.Mui-checked': {
                                            color: theme.palette.colors.green,
                                        },
                                    }} />} label="Non" />
                                </RadioGroup>
                                <FormHelperText>{notificationMailError}</FormHelperText>
                            </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={"register-button"} item>
                        <button onClick={handleSubmitRegister} className="btn btn-primary"><Typography variant={"h5"}>Créer mon compte</Typography></button>
                    </Grid>
                    <Grid className={"auth-register"} item>
                        <Typography variant={"h5"}>
                            Déjà un compte ? <a href={"/connexion"}>Connectez vous</a>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
