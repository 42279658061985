import React, {useEffect, useState} from 'react';

import '../../assets/Profile.scss'
import {
    FilledInput,
    FormControl, FormControlLabel, FormHelperText, FormLabel,
    Grid, IconButton,
    InputAdornment,
    InputLabel, RadioGroup,
    TextField,
    Tooltip,
    Typography
 } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';
import {Visibility, VisibilityOff} from "@mui/icons-material";
import Radio from "@mui/material/Radio";
import {useTheme} from "@mui/system";
import ApiClient from "../../service/ApiClient";
import ReactGA from "react-ga4";
import {Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle} from "../../component/Popup/Popup";

export default function Profile(props) {
    const theme = useTheme();
    const [lastName, setLastName] = useState(props.user.LastName);
    const [firstName, setFirstName] = useState(props.user.FirstName);
    const [pseudo, setPseudo] = useState(props.user.Pseudo);
    const [email, setEmail] = useState(props.user.Email);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [newsletter, setNewsletter] = useState(props.user.newsletter === 1);
    const [pronoRemind, setPronoRemind] = useState(props.user.isPronoRemind === 1);
    const [lastNameError, setLastNameError] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [pseudoError, setPseudoError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [visibilityPassword, setVisibilityPassword] = useState(false);
    const [visibilityConfirmPassword, setVisibilityConfirmPassword] = useState(false);
    const [isPasswordLong, setIsPasswordLong] = useState(false);
    const [isPasswordUppercase, setIsPasswordUppercase] = useState(false);
    const [isPasswordNumber, setIsPasswordNumber] = useState(false);
    const [isChangePassword, setIsChangePassword] = useState(false);
    const [confirmPopup, setConfirmPopup] = useState(false);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    function handleChangePassword(value) {
        value.length >= 8 ? setIsPasswordLong(true) : setIsPasswordLong(false);
        /\d/.test(value) ? setIsPasswordNumber(true) : setIsPasswordNumber(false);
        /[A-Z]/.test(value) ? setIsPasswordUppercase(true) : setIsPasswordUppercase(false);
        setPassword(value);
    }

    async function getPseudoAvailable() {
        try {
            if (pseudo === "") {
                return true;
            }
            let result = await ApiClient.instance.isPseudoAvailable(pseudo);
            result.data.isPseudoAvailable || props.user.Pseudo === pseudo ? setPseudoError("") : setPseudoError("Pseudo non disponible");
            return result.data.isPseudoAvailable || props.user.Pseudo === pseudo;
        } catch (e) {
            console.log(e);
        }
    }

    async function handleSubmitChangeProfile() {
        try {
            const mailRegex = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$");
            let isPseudoAvailable = await getPseudoAvailable();
            let error = false;

            firstName === "" ? setFirstNameError("Ce champ est nécessaire") : setFirstNameError("");
            if (firstName === "") {
                error = true;
            }
            lastName === "" ? setLastNameError("Ce champ est nécessaire") : setLastNameError("");
            if (lastName === "") {
                error = true;
            }

            if (isPseudoAvailable) {
                pseudo === "" ? setPseudoError("Ce champ est nécessaire") : setPseudoError("");
            }

            if (!isPseudoAvailable || pseudo === "") {
                error = true;
            }
            email === "" ? setEmailError("Ce champ est nécessaire") : (!mailRegex.test(email)) ? setEmailError("Veuillez saisir une adresse e-mail valide.") : setEmailError("");
            if (email === "" || !mailRegex.test(email)) {
                error = true;
            }
            isChangePassword && (!isPasswordLong || !isPasswordNumber || !isPasswordUppercase) ? setPasswordError("Votre mot de passe ne répond pas au critère de sécurité") : setPasswordError("");
            if (isChangePassword && (!isPasswordLong || !isPasswordNumber || !isPasswordUppercase)) {
                error = true;
            }
            password !== confirmPassword ? setConfirmPasswordError("Les mots de passes ne sont pas identique") : setConfirmPasswordError("");
            if (password !== confirmPassword) {
                error = true;
            }

            if (!error) {
                let data = {
                    LastName: lastName,
                    FirstName: firstName,
                    Email: email,
                    Pseudo: pseudo,
                    Password: password !== "" ? password : undefined,
                    Newsletter: newsletter,
                    PronoRemind: pronoRemind
                }
                let result = await ApiClient.instance.updateUser(data);
                props.updateUserData(result.data.token, result.data.user);
                props.createNotification("success", {title: "Succès", message: "Votre profil a bien été mis à jour", timeout: 3000});

            }
        } catch (err) {
            props.createNotification("error", {title: "Erreur", message: "Votre profil n'a pas été mis à jour", timeout: 3000});
        }
    }

    async function deleteUser() {
        try {
            await ApiClient.instance.deleteUser();
            props.createNotification("success", {title: "Succès", message: "Votre profil a bien été supprimé", timeout: 3000});
            props.signout();
        } catch (e) {
            props.createNotification("error", {title: "Erreur", message: "Votre profil n'a pas été supprimé", timeout: 3000});
        }
    }

    return (
        <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid className={"profile-container"} container item direction={"column"} justifyContent={"center"} alignItems={"center"} xs={12} sm={10} md={6}>
                <Grid className={"profile-title"} container item justifyContent={"center"} alignItems={"center"}>
                    <Grid item>
                        <Typography variant={"h4"}>{props.user.FirstName + " " + props.user.LastName}</Typography>
                    </Grid>

                    <Grid item className={"delete-user"}>
                        <Tooltip title="Supprimer mon compte" enterTouchDelay={0}>
                            <IconButton onClick={() => setConfirmPopup(true)}>
                                <DeleteIcon className={"delete-user-icon"}/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>

                <Grid className={"profile-myInformation"} container item direction={"column"} justifyContent={"center"} alignItems={"center"} xs={10}>
                    <Grid item>
                        <Typography variant={"h6"}>Mes informations</Typography>
                    </Grid>

                    <Grid className={"profile-input"} container item justifyContent={"center"}>
                        <Grid item xs={8} lg={6}>
                            <TextField
                                autoComplete="family-name"
                                fullWidth
                                required
                                id="standard-error-helper-text"
                                label="Nom"
                                variant="filled"
                                value={lastName}
                                helperText={lastNameError}
                                onChange={event => {
                                    setLastName(event.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid className={"profile-input"} container item justifyContent={"center"}>
                        <Grid item xs={8} lg={6}>
                            <TextField
                                autoComplete="given-name"
                                fullWidth
                                required
                                id="standard-error-helper-text"
                                label="Prénom"
                                variant="filled"
                                value={firstName}
                                helperText={firstNameError}
                                onChange={event => {
                                    setFirstName(event.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid className={"profile-input"} container item justifyContent={"center"} alignItems={"center"}>
                        <Grid item xs={8} lg={6}>
                            <FormControl variant="filled" fullWidth helperText={pseudoError}>
                                <InputLabel required htmlFor="filled-adornment-pseudo">Pseudo</InputLabel>
                                <FilledInput
                                    id="filled-adornment-pseudo"
                                    type={'text'}
                                    value={pseudo}
                                    onChange={(event) => { setPseudo(event.target.value); }}
                                    onBlur={getPseudoAvailable}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Tooltip title="Le pseudo sera visible par tout le monde." enterTouchDelay={0}>
                                                <IconButton>
                                                    <HelpIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>{pseudoError}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid className={"profile-input"} container item justifyContent={"center"}>
                        <Grid item xs={8} lg={6}>
                            <TextField
                                autoComplete="email"
                                fullWidth
                                required
                                id="standard-error-helper-text"
                                label="Email"
                                variant="filled"
                                value={email}
                                helperText={emailError}
                                onChange={event => {
                                    setEmail(event.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item justifyContent={"center"} alignItems={"center"}>
                        <Grid className={"change-password-container"} container item xs={8} lg={6}>
                            <Grid className={"btn-change-password"} container item
                                                       justifyContent={"space-between"} onClick={() => {
                            setIsChangePassword(!isChangePassword);
                            setPassword("");
                            setConfirmPassword("");
                        }}>
                                <Grid item>
                                    <Typography variant={"h7"}>Modifier mon mot de passe</Typography>
                                </Grid>
                                <Grid item>
                                    { isChangePassword ?
                                        <ExpandLessIcon/> :
                                        <ExpandMoreIcon/> }
                                </Grid>
                            </Grid>

                            {isChangePassword &&
                            <Grid className={"profile-input"} container item justifyContent={"center"}>
                                <Grid item xs>
                                    <FormControl variant="filled" fullWidth helperText={passwordError}>
                                        <InputLabel required htmlFor="filled-adornment-password">Mot de passe</InputLabel>
                                        <FilledInput
                                            id="filled-adornment-password"
                                            type={visibilityPassword ? 'text' : 'password'}
                                            value={password}
                                            onChange={(event) => {
                                                handleChangePassword(event.target.value)
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => {
                                                            setVisibilityPassword(!visibilityPassword)
                                                        }}
                                                        onMouseDown={(event) => {
                                                            event.preventDefault()
                                                        }}
                                                    >
                                                        {visibilityPassword ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <FormHelperText>{passwordError}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            }
                            {isChangePassword &&
                            <ul className={"password-requirement-container"}>
                                <li className={"password-requirement-title"}>Votre mot de passe doit contenir :</li>
                                <li className={"password-requirement-item-" + (isPasswordLong ? "valid" : "invalid")}>
                        <span className={"check-container"}>
                            <svg role="presentation" width="10" height="10" viewBox="0 0 12 12" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                            {isPasswordLong ?
                                <path className="check-valid"
                                      d="M10.27.317l1.46 1.366-7.21 7.702L.346 5.777l1.308-1.513 2.72 2.35L10.27.317z"
                                      stroke-width="2"></path>
                                :
                                <path className="check-invalid" d="M1 1l10 10M11 1L1 11" stroke-width="2"></path>}
                            </svg>
                        </span>
                                    Au minimum 8 caractères
                                </li>
                                <li className={"password-requirement-item-" + (isPasswordUppercase ? "valid" : "invalid")}>
                        <span className={"check-container"}>
                        <svg role="presentation" width="10" height="10" viewBox="0 0 12 12" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            {isPasswordUppercase ?
                                <path className="check-valid"
                                      d="M10.27.317l1.46 1.366-7.21 7.702L.346 5.777l1.308-1.513 2.72 2.35L10.27.317z"
                                      stroke-width="2"></path>
                                :
                                <path className="check-invalid" d="M1 1l10 10M11 1L1 11" stroke-width="2"></path>}
                        </svg>
                    </span>
                                    Une majuscule
                                </li>
                                <li className={"password-requirement-item-" + (isPasswordNumber ? "valid" : "invalid")}>
                        <span className={"check-container"}>
                        <svg role="presentation" width="10" height="10" viewBox="0 0 12 12" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            {isPasswordNumber ?
                                <path className="check-valid"
                                      d="M10.27.317l1.46 1.366-7.21 7.702L.346 5.777l1.308-1.513 2.72 2.35L10.27.317z"
                                      stroke-width="2"></path>
                                :
                                <path className="check-invalid" d="M1 1l10 10M11 1L1 11" stroke-width="2"></path>}
                        </svg>
                    </span>
                                    Un nombre
                                </li>
                            </ul>
                            }
                            {isChangePassword &&
                            <Grid className={"profile-input"} container item justifyContent={"center"}>
                                <Grid item xs>
                                    <FormControl variant="filled" fullWidth helperText={confirmPasswordError}>
                                        <InputLabel htmlFor="filled-adornment-password" required>Confirmer mot de
                                            passe</InputLabel>
                                        <FilledInput
                                            id="filled-adornment-password"
                                            type={visibilityConfirmPassword ? 'text' : 'password'}
                                            value={confirmPassword}
                                            onChange={(event) => {
                                                setConfirmPassword(event.target.value)
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => {
                                                            setVisibilityConfirmPassword(!visibilityConfirmPassword)
                                                        }}
                                                        onMouseDown={(event) => {
                                                            event.preventDefault()
                                                        }}
                                                    >
                                                        {visibilityConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <FormHelperText>{confirmPasswordError}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            }
                        </Grid>



                    </Grid>


                    <Grid className={"profile-input"} container item justifyContent={"center"}>
                        <Grid item xs={8} lg={6}>
                            <FormControl>
                                <FormLabel id="demo-controlled-radio-buttons-group" sx={{'&.Mui-focused': {
                                        color: "#565656",
                                    }}}>J'accepte de recevoir la newsletter de l'US Méné Bré Louargat <span style={{color: theme.palette.colors.green, fontWeight: "bold"}}>*</span></FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    onChange={(event) => {
                                        setNewsletter(event.target.value === "true");
                                    }}
                                    value={newsletter}
                                >
                                    <FormControlLabel value={true} control={<Radio sx={{
                                        '&.Mui-checked': {
                                            color: theme.palette.colors.green,
                                        },
                                    }} />} label="Oui" />
                                    <FormControlLabel value={false} control={<Radio sx={{
                                        '&.Mui-checked': {
                                            color: theme.palette.colors.green,
                                        },
                                    }} />} label="Non" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid className={"profile-input"} container item justifyContent={"center"}>
                        <Grid item xs={8} lg={6}>
                            <FormControl>
                                <FormLabel id="demo-controlled-radio-buttons-group" sx={{'&.Mui-focused': {
                                        color: "#565656",
                                    }}}>J'accepte de recevoir des notifications pour le jeu de pronostic <span style={{color: theme.palette.colors.green, fontWeight: "bold"}}>*</span></FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    onChange={(event) => {
                                        setPronoRemind(event.target.value === "true");
                                    }}
                                    value={pronoRemind}
                                >
                                    <FormControlLabel value={true} control={<Radio sx={{
                                        '&.Mui-checked': {
                                            color: theme.palette.colors.green,
                                        },
                                    }} />} label="Oui" />
                                    <FormControlLabel value={false} control={<Radio sx={{
                                        '&.Mui-checked': {
                                            color: theme.palette.colors.green,
                                        },
                                    }} />} label="Non" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid className={"profile-button"} item>
                        <button onClick={handleSubmitChangeProfile} className="btn btn-primary"><Typography variant={"h5"}>Enregistrer</Typography></button>
                    </Grid>
                </Grid>
            </Grid>
            {confirmPopup &&
            <Popup close={() => {
                setConfirmPopup(false);
            }}>
                <PopupHeader>
                    <PopupTitle>Supprimer mon compte</PopupTitle>
                </PopupHeader>
                <PopupBody>
                    <p>Voulez-vous vraiment supprimer votre compte ?</p>
                </PopupBody>
                <PopupFooter dismiss={"Non"} close={() => {
                    setConfirmPopup(false);
                }}>
                    <button className={"btn btn-primary"} onClick={() => deleteUser()}>Oui
                    </button>
                </PopupFooter>
            </Popup>
            }
        </Grid>
    )
}
