import React from "react";
import "./Popup.scss";

export const PopupTitle = (props) => {
    return (
        <h5 className="modal-title text-center" id={props.id}>
            {props.children}
        </h5>
    )
};

export const PopupHeader = (props) => {
    return (
        <div className="modal-header">
            {props.children}
        </div>
    )
};

export const PopupBody = (props) => {
    return (
        <div className={"modal-body " + props.className}>
            {props.children}
        </div>
    );
};

export const PopupFooter = (props) => {
    return (
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={props.close}>{props.dismiss}</button>
            {props.children}
        </div>
    )
};

export const Popup = (props) => {
    var dialog = "";
    switch (props.size) {
        case "large":
            dialog = "modal-lg";
            break;
        case "extraLarge":
            dialog = "modal-xl";
            break;
        case "small":
            dialog = "modal-sm";
            break;
        default:
            dialog = "";
            break;
    }

    return (
        <div className={"modal d-block"} onClick={props.close}>
            <div className={"modal-dialog modal-dialog-centered " + dialog} role="document" onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={"modal-content text-center overflow-auto max-modal-height"}>
                    {props.children}
                </div>
            </div>
        </div>
    );
};