import React, {useRef} from 'react'
import { useDrag, useDrop } from 'react-dnd'
import {Box, Grid, Typography } from "@mui/material";
import ActionMenu from "../../component/ActionMenu/ActionMenu";

const ItemTypes = {
    CARD: 'card',
}
const style = {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
}

export default function Card(props) {
    const ref = useRef(null);
    const styles = {
        header: {
            backgroundColor: "black",
            color: "white",
            padding: "0.5rem"
        },
        rows: {
            padding: "0.5rem",
            height: "60px",
            paddingTop: "18px",
            overflow: "hidden",
            whiteSpace: "nowrap"
        },
        img: {
            height: "100%"
        },
        cssLabel: {
            "&.Mui-focused": {
                color: "#048f00"
            }
        },
    };
    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = props.index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            props.moveCard(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id: props.id, index: props.index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })
    const opacity = isDragging ? 0 : 1
    drag(drop(ref))

    function editAction() {
        props.setActualCategory(props.category);
        props.setShowPopup("update");
    }

    function removeAction() {
        props.setActualCategory(props.category);
        props.setConfirmPopup(true);
    }

    function buildAction() {
        if (window.innerWidth > 960) {
            return (<Grid item xs={2}><Box sx={styles.rows}>
                <button className={"btn btn-sm remove-category"}
                        onClick={removeAction}><i className="material-icons">delete</i></button>
                <button className={"btn btn-sm update-category"}
                        onClick={editAction}><i className="material-icons">edit</i></button>
            </Box>
            </Grid>);
        } else {
            return (<Grid item xs={1}>
                <ActionMenu edit={editAction} remove={removeAction}/>
            </Grid>)
        }
    }

    return (
            <Grid item container direction={"row"} justifyContent={"space-between"} alignItems={"center"} key={props.category.id} ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
                <Grid item xs={2}>
                    <Box sx={styles.rows}><Typography
                        variant={"body1"}>{props.category.name}</Typography></Box>
                </Grid>
                <Grid item xs={3}>
                    <Box sx={styles.rows}><Typography
                        variant={"body1"}>
                        { props.category.calendarLink !== null ? <a className={"category-link"} href={props.category.calendarLink} target={"_blank"} rel="noreferrer">{props.category.calendarLink}</a> : "Non défini"}
                    </Typography></Box>
                </Grid>

                <Grid item xs={3}>
                    <Box sx={styles.rows}><Typography
                        variant={"body1"}>
                        { props.category.rankLink !== null ? <a className={"category-link"} href={props.category.rankLink} target={"_blank"} rel="noreferrer">{props.category.rankLink}</a> : "Lien non défini" }
                    </Typography></Box>
                </Grid>
                {buildAction()}
            </Grid>
    )
}
