import React, {useState} from "react";
import 'moment/locale/fr';
import { Input, InputAdornment, InputLabel, TextField, FormControl  } from "@mui/material";
import { styled } from "@mui/system";

import { Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle } from "../../component/Popup/Popup";

export default function AddUpdateTeamPopup(props) {
    const [team, setTeam] = useState(props.team ? {Id: props.team.value, Logo: props.team.photo, Name: props.team.name} : {})
    const [error, setError] = useState(false);

    function handleAddSubmit() {
        if (team.Logo == null || team.Name == null || team.Name === "") {
            setError(true);
            return;
        }
        props.submit(team);
    }

    function handleUpdateSubmit() {
        if (team.Name == null || team.Name === "") {
            setError(true);
            return;
        }
       props.submit(team);
    }

    return (
        <Popup size={"large"} close={props.close}>
            <PopupHeader>
                <PopupTitle>{props.team ? "Modifier une équipe" : "Créer une nouvelle équipe"}</PopupTitle>
            </PopupHeader>

            <PopupBody>
                <div className={"row justify-content-center"}>
                    <TextField inputProps={{min: 0, style: { textAlign: 'center' }}} type={"text"} label={"Nom de l'équipe"} defaultValue={team.Name} onChange={(event) => {
                        let tmp = team;
                        tmp.Name = event.target.value;
                        setTeam(tmp);
                    }}/>
                </div>
                <div className={"row justify-content-center mt-4"}>
                    <FormControl>
                        <InputLabel htmlFor="input-with-icon-adornment">Logo de l'équipe</InputLabel>
                        <Input type={"file"} accept=".png, .jpg"
                               onChange={(event) => {
                                   let tmp = team;
                                   tmp.Logo = event.target.files[0];
                                   setTeam(tmp);
                               }}
                            id="input-with-icon-adornment"
                            startAdornment={
                                <InputAdornment position="start">
                                    { props.team ? <img style={{width: "20px"}} alt={"team-logo"}
                                          src={process.env.REACT_APP_API_URL + team.Logo}/> : ""}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>
                {error && props.team == null &&<small className={"text-danger col-12"}>Veuillez remplir tous les champs</small>}
                {error && props.team && <small className={"text-danger col-12"}>Le champ du nom d'équipe est obligatoire</small>}
            </PopupBody>

            <PopupFooter dismiss={"Annuler"} close={props.close}>
                <input className={"btn btn-primary"} type={"submit"} value={"Valider"}
                       onClick={() => props.team ? handleUpdateSubmit() : handleAddSubmit()}/>
            </PopupFooter>
        </Popup>
    );
}