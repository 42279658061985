import React, { useEffect, useState } from 'react';
import { Grid, Box  } from "@mui/material";
import {useParams} from "react-router-dom";

import ApiClient from "../../service/ApiClient";
import Article from "../../component/Article/Article";

export default function ActualiteShowMore(props) {
    const params = useParams();
    const [article, setArticle] = useState(null);
    const [articlePic, setArticlePic] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                await loadData();
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    async function loadData() {
        let result = await ApiClient.instance.getArticles(params.id);
        setArticle(result.data[0]);
        result = await ApiClient.instance.getArticlesPic(params.id);
        setArticlePic(result.data);
    }

    if (article === null || articlePic === null)
        return (<Box/>)
    return (<Grid container justifyContent={"center"} alignItems={"center"}>
        <Article id={article.id} article={article} articlePic={articlePic}/>
    </Grid>);
}