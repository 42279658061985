import React, { useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    Typography
 } from "@mui/material";

import ApiClient from "../../service/ApiClient";
import ReactGA from "react-ga4";

import '../../assets/Inscription.scss'

export default function ForgetPassword(props) {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    async function handleSubmitForgetPassword() {
        try {
            const mailRegex = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$");

            email === "" ? setEmailError("Ce champ est nécessaire") : (!mailRegex.test(email)) ? setEmailError("Veuillez saisir une adresse e-mail valide.") : setEmailError("");
           if (emailError === "") {
                let data = {
                    Email: email
                }
                await ApiClient.instance.forgetPassword(data);
                props.createNotification("success", {title: "Succès", message: "Le mail pour changer votre mot de passe a bien été envoyé", timeout: 3000})
               setEmail("");
           }
        } catch (err) {
            props.createNotification("error", {title: "Erreur", message: "Email non envoyé", timeout: 3000});
        }
    }

    return (
        <Grid container direction={"row"} justifyContent={"center"} alignItems={"center"} style={{height: "100%"}}>
            <Grid className={"register-container"} container item direction={"column"} justifyContent={"space-between"} alignItems={"center"} xs={11} md={9} lg={6}>
                <Grid className={"register-title"} item><Typography variant={"h4"} style={{marginLeft: "0"}}>Mot de passe oublié?</Typography></Grid>
                <Grid className={"register-input"} container item justifyContent={"center"}>
                    <Grid item xs={6}>
                        <TextField
                            autoComplete="email"
                            fullWidth
                            required
                            id="standard-error-helper-text"
                            label="Email"
                            variant="filled"
                            value={email}
                            helperText={emailError}
                            onChange={event => {
                                setEmail(event.target.value);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid className={"register-button"} item>
                    <button onClick={handleSubmitForgetPassword} className="btn btn-primary"><Typography variant={"h5"}>Changer de mot de passe</Typography></button>
                </Grid>
            </Grid>
        </Grid>
    );
}
