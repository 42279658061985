import { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import { Grid, Typography, TextField, InputAdornment, Button, Box  } from "@mui/material";
import { useTheme } from '@emotion/react';
import ReactGA from "react-ga4";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import ImageGallery from 'react-image-gallery';


import SimpleSelect from "../../component/SelectDropDown/SimpleSelect";
import ApiClient from "../../service/ApiClient";
import PopupNotification from "./PopupNotification";
import Spinner from "../../component/Spinner/Spinner";

export default function Product(props) {
    const theme = useTheme();
    let { product } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [article, setArticle] = useState([]);
    const [actualSize, setActualSize] = useState(null);
    const [actualQuantity, setActualQuantity] = useState(1);
    const [images, setImages] = useState({});
    const [actualImage, setActualImage] = useState(null);
    const [actualImageIndex, setActualImageIndex] = useState(0);
    const [cart, setCart] = useState({});
    const [showNotification, setShowNotification] = useState(false);
    const [isExpandBuy, setIsExpandBuy] = useState(false);

    const styles = {
        link: {
            fontSize: "14px",
            color: "#383838",
            margin: "0.25rem",
            cursor: "pointer",
            "&:hover": {
                textDecoration: "underline",
                color: theme.palette.colors.green
            }
        },
        linkSeparator: {
            fontSize: "14px",
            color: "#383838",
            margin: "0.25rem"
        },
        titleProduct: {
            fontWeight: "bold",
            fontSize: '20px',
            marginBottom: "1rem",
            [theme.breakpoints.up('md')]: {
                fontSize: '28px',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '35px',
            },
        },
        priceProduct: {
            fontWeight: "bold",
            fontSize: '20px',
            color: theme.palette.colors.green,
        },
        sizeContainer: {
            marginTop: "1rem"
        },
        enabledIcon: {
            color: theme.palette.colors.green,
            cursor: "pointer"
        },
        disabledIcon: {
            color: "#f7f7f7",
            cursour: "initial"
        },
        buttonAdd: {
            color: "white",
            backgroundColor: theme.palette.colors.green,
            borderRadius: "25px!important",
            fontWeight: "bold",
            "&:hover": {
                backgroundColor: "black",
                color: theme.palette.colors.green
            }
        },
        descriptionProduct: {
            fontWeight: "bold",
            fontSize: "14px",
            marginTop: "1rem"
        },
        overlayContainer: {
            position: "fixed",
            top: 0,
            left: 0,
            right:0,
            bottom:0,
        },
        overlay: {
            width: "100%",
            height: "100%",
            backgroundColor: "#878787",
            opacity: "50%",
            
        },
        spinner: {
            position: "fixed",
            top: 0,
            left: 0,
            right:0,
            bottom:0,
        },
        buyContainer: {
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "white",
            boxShadow: "0 0 10px 0 rgba(0,0,0,0.25)",
            padding: "1rem",
            zIndex: "10"
        },
        closeContainer: {
            color: theme.palette.colors.green,
            margin: "1rem 0",
            fontWeight: "bold",
            fontSize: "10px"
        },
        line: {
            borderBottom: "1px solid #f0f0f0",
            height: "1rem"
        },
        miniImageContainer: {
            marginBottom: "1rem",
        },
        miniImageContainerActive: {
            marginBottom: "1rem",
            border: "1px " + theme.palette.colors.green + " solid", 
        },
    }

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        (async () => {
            try {
                setIsLoading(true);
                await loadData();
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        })();
    }, [product]);

    async function loadData() {
        try {
            let result = await ApiClient.instance.getShopArticleByPath(product);
            let tmpArticle = result.data[0];
            let tmpSize = tmpArticle.size.split(",");
            let tab = [];
            
            for (let i = 0; i < tmpSize.length; i++) {
                tab.push({name: tmpSize[i], value: i});
            }

            tmpArticle.size = tab;

            tab = [];
            for (let i = 0; i < tmpArticle.picture.length; i++) {
                tab.push(process.env.REACT_APP_API_URL + tmpArticle.picture[i].link);
            }
            setActualImage(tab[0])
            setImages(tab);
            setArticle(tmpArticle);
            setActualSize(tmpArticle.size[0].value);

            if (localStorage.getItem('shop_session') !== null) {
                result = await ApiClient.instance.getShopSession(localStorage.getItem('shop_session'));

                if (result.data !== null) {
                    setCart(result.data);
                    props.handleCartIcon(result.data.products.length);
                } else {
                    setCart({});
                    localStorage.removeItem('shop_session');
                    props.handleCartIcon(0);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    function handleSize(data) {
        setActualSize(data);
    }

    async function handleAddCart() {
        let data;
        if (props.dimensions <= 900 && !isExpandBuy) {
            setIsExpandBuy(true);
        } else {
            if (actualQuantity !== 0) {
                if (localStorage.getItem('shop_session') === null) {
                    data = {
                        products: [{
                            product_id: article.id,
                            quantity: actualQuantity,
                            size: article.size[actualSize].name
                        }]
                    }
                    let result = await ApiClient.instance.createShopSession(data);
                    localStorage.setItem('shop_session', result.data.session_id);
                    await loadData();
                } else {
                    let tmp = cart.products;
                    let isPresent = false;
    
                    for (let i = 0; i < tmp.length; i++) {
                        if (tmp[i].product_id === article.id && tmp[i].size === article.size[actualSize].name) {
                            tmp[i].quantity += actualQuantity;
                            isPresent = true;
                        }
                    }
    
                    if (!isPresent) {
                        tmp.push({
                            product_id: article.id,
                            quantity: actualQuantity,
                            size: article.size[actualSize].name
                        })
                    }
                    
                    data = {
                        products: tmp
                    }
                    let result = await ApiClient.instance.updateShopSession(cart.id, data);
                    await loadData();
                }
                setIsExpandBuy(false);
                setShowNotification(true);
            }
        }
    }

    function handleHoverImage(image, index) {
        setActualImage(image);
        setActualImageIndex(index);
    }

    function buildSmallPic() {
        let row = [];

        for (let i = 0; i < images.length; i++) {
            row.push(<Grid container item sx={actualImageIndex === i ? styles.miniImageContainerActive: styles.miniImageContainer} onMouseOver={() => {handleHoverImage(images[i], i)}} justifyContent={"center"}>
                <Grid item>
                    <img style={{maxWidth: "100%", maxHeight: "100%", justifyContent: "center"}} src={images[i]} alt="thumbnail"/>
                </Grid>
            </Grid>)
        }

        return row;
    }

    if (article.length !== 0 && cart !== {} && props.dimensions > 900) {
        return (<Grid container item justifyContent={"space-between"}>
        <Grid container item xs={7.5} justifyContent={"space-between"}>
            <Grid container item direction={"column"} xs={2}>
                {buildSmallPic()}
            </Grid>

            <Grid container item xs={9} justifyContent={"center"}>
                <Grid item xs={10}>
                    <img style={{width: "100%"}} src={actualImage} alt="articlePhoto"/>
                </Grid>
            </Grid>
           
        </Grid>

        <Grid container item direction={"column"} xs={3.5}>
            <Grid container item>
                <Grid item>
                    <Link to={"/"} sx={{textDecoration: "none!important"}}>
                        <Typography sx={styles.link}>Accueil</Typography>
                    </Link>
                </Grid>
                <Grid item>
                    <Typography sx={styles.linkSeparator}>/</Typography>
                </Grid>

                <Grid item>
                    <Link to={"/" + props.lastPath} sx={{textDecoration: "none!important"}}>
                        <Typography sx={styles.link}>{props.lastPath}</Typography>
                    </Link>
                </Grid>

            </Grid>
            <Grid item>
                <Typography sx={styles.titleProduct}>{article.name}</Typography>
            </Grid>

            <Grid item>
                <Typography sx={styles.priceProduct}>{parseFloat(article.price).toFixed(2) + " €"}</Typography>
            </Grid>

            <Grid container item sx={{marginTop: "1rem"}}>
                <Grid item xs={8} lg={7}>
                    <SimpleSelect data={article.size} onChange={handleSize} actualIndex={actualSize} textLabel={"Taille"} inputStyle={{textAlign: "center", fontWeight: "bold", color: theme.palette.colors.green}}/>
                </Grid>
            </Grid>

            <Grid container item sx={{marginTop: "1rem"}}>
                <Grid item xs={8} lg={7}>
                    <TextField
                        fullWidth
                        sx={{width: "100%!important", input: {textAlign: "center", fontWeight: "bold", color: theme.palette.colors.green}}}
                        id="standard-start-adornment"
                        label={"Quantité"}
                        value={actualQuantity}
                        InputLabelProps={{style: {color: theme.palette.colors.green}}}
                        InputProps={{
                            startAdornment: <InputAdornment position="start" sx={actualQuantity === 1 ? styles.disabledIcon : styles.enabledIcon}><RemoveCircleIcon onClick={() => {if (actualQuantity !== 0) { setActualQuantity(parseInt(actualQuantity) - 1)}}}/></InputAdornment>,
                            endAdornment: <InputAdornment position="end" sx={styles.enabledIcon}><AddCircleIcon onClick={() => {setActualQuantity(parseInt(actualQuantity) + 1)}}/></InputAdornment>,
                        }}
                        onChange={(event) => {
                            let tmp = event.target.value;
                            if (tmp === "") {
                                tmp = 1;
                            }
                            if (parseInt(tmp) > 0) {
                                setActualQuantity(parseInt(tmp));
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Grid container item sx={{marginTop: "1rem"}}>
                <Grid item xs={8} lg={7}>
                    <Button fullWidth sx={styles.buttonAdd} onClick={handleAddCart}>
                    Ajouter au panier
                    </Button>
                </Grid>
            </Grid>

            <Grid container item sx={{marginTop: "2rem"}}>
                <Grid item xs={12}>
                        <Typography sx={styles.priceProduct}>Description</Typography>
                        <Typography sx={styles.descriptionProduct}>{article.description}</Typography>
                </Grid>
            </Grid>
        </Grid>
        {showNotification && 
            <PopupNotification handleClose={() => setShowNotification(false)} product={article}/>
        }

        { isLoading && 
            <Box sx={styles.overlayContainer}>
                <Box sx={styles.overlay}>

                </Box>
                <Grid container sx={styles.spinner} justifyContent={"center"} alignItems={"center"}>
                    <Grid item>
                        <Spinner />
                    </Grid>
                </Grid>
                
            </Box>
            }
    </Grid>)
    } else if (article.length !== 0 && cart !== {} && props.dimensions <= 900) {
        return (
            <Grid container item justifyContent={"center"}>
                <Grid container item direction={"column"} xs={11.5} sm={10}>
                    <Grid container item>
                        <Grid item>
                            <Link to={"/"} sx={{textDecoration: "none!important"}}>
                                <Typography sx={styles.link}>Accueil</Typography>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Typography sx={styles.linkSeparator}>/</Typography>
                        </Grid>

                        <Grid item>
                            <Link to={"/" + props.lastPath} sx={{textDecoration: "none!important"}}>
                                <Typography sx={styles.link}>{props.lastPath}</Typography>
                            </Link>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Typography sx={styles.titleProduct}>{article.name}</Typography>
                    </Grid>

                    <Grid container item>
                        <Grid container item xs={11} justifyContent={"space-between"}>

                            <Grid container item direction={"column"} xs={2}>
                                {buildSmallPic()}
                            </Grid>

                            <Grid container item xs={9}>
                                <Grid item xs={10}>
                                    <img style={{width: "100%"}} src={actualImage} alt="articlePhoto"/>
                                </Grid>
                            </Grid>
           
                            
                        </Grid>
                    </Grid>

                    <Grid item sx={{marginTop: "2rem"}}>
                        <Typography sx={styles.priceProduct}>Description</Typography>
                        <Typography sx={styles.descriptionProduct}>{article.description}</Typography>
                    </Grid>
                </Grid>
                <Grid container sx={styles.buyContainer} direction={"column"}>
                    { isExpandBuy &&
                    <Grid container item justifyContent={"center"} sx={{marginTop: "1rem"}}>
                        <Grid item xs={8} lg={7}>
                            <SimpleSelect data={article.size} onChange={handleSize} actualIndex={actualSize} textLabel={"Taille"} inputStyle={{textAlign: "center", fontWeight: "bold", color: theme.palette.colors.green}}/>
                        </Grid>
                    </Grid>
                    }

                    { isExpandBuy &&
                    <Grid container item justifyContent={"center"} sx={{marginTop: "1rem"}}>
                        <Grid item xs={8} lg={7}>
                            <TextField
                                fullWidth
                                sx={{width: "100%!important", input: {textAlign: "center", fontWeight: "bold", color: theme.palette.colors.green}}}
                                id="standard-start-adornment"
                                label={"Quantité"}
                                value={actualQuantity}
                                InputLabelProps={{style: {color: theme.palette.colors.green}}}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start" sx={actualQuantity === 0 ? styles.disabledIcon : styles.enabledIcon}><RemoveCircleIcon onClick={() => {if (actualQuantity !== 0) { setActualQuantity(parseInt(actualQuantity) - 1)}}}/></InputAdornment>,
                                    endAdornment: <InputAdornment position="end" sx={styles.enabledIcon}><AddCircleIcon onClick={() => {setActualQuantity(parseInt(actualQuantity) + 1)}}/></InputAdornment>,
                                }}
                                onChange={(event) => {
                                    let tmp = event.target.value;
                                    if (tmp === "") {
                                        tmp = 0;
                                    }
                                    if (parseInt(tmp) >= 0) {
                                        setActualQuantity(parseInt(tmp));
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    }

                    { isExpandBuy &&
                    <Grid container item justifyContent={"center"}>
                        <Grid sx={styles.line} item xs/>
                    </Grid>
                    }

                    { isExpandBuy &&
                    <Grid container item>
                        <Grid sx={styles.closeContainer} container item xs justifyContent={"center"} alignItems={"center"} onClick={() => setIsExpandBuy(false)}>
                            <Grid item>
                                <CloseIcon/>
                            </Grid>
                            <Grid item>
                                <Typography sx={{fontSize: "13px"}}>Fermer</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    }

                    <Grid container item justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item>
                            <Typography sx={styles.priceProduct}>{parseFloat(article.price).toFixed(2) + " €"}</Typography>
                        </Grid>

                        <Grid item>
                            <Button fullWidth sx={styles.buttonAdd} onClick={handleAddCart}>
                                Ajouter au panier
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {showNotification && 
                    <PopupNotification handleClose={() => setShowNotification(false)} product={article}/>
                }
            </Grid>
        );
    } else {
        return (<Grid/>)
    }
    
}