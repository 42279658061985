import {GoogleMap, LoadScript} from "@react-google-maps/api";

const containerStyle = {
    minWidth: '325px',
    height: '400px'
};

const center = {
    lat: 48.569241,
    lng: -3.342756
};

export default function Map() {
    return <LoadScript
        googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY}
    >
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={16}
            mapTypeId={"satellite"}
        >
            { /* Child components, such as markers, info windows, etc. */ }
            <></>
        </GoogleMap>
    </LoadScript>
}