import React, {useEffect, useState} from "react";

import "./TimeInput.scss";

/**
 * Time input component
 *
 * @handleTime: update function arg(type, value)
 * @hour: default value for the hour input
 * @min: default value for the min input
 */
export default function TimeInput(props) {

    function handleData(type, data) {
        const re = /^[0-9\b]+$/;

        if (data === '' || re.test(data)) {
            if (type === "hour") {
                if (data === "")
                    data = 0;
                if (parseInt(data) >= 10)
                    data = parseInt(data, 10);
                if (parseInt(data) >= 24)
                    data = 23;
                props.handleTime("hour", data);
            }
            else {
                if (data === "")
                    data = 0;
                if (parseInt(data) >= 10)
                    data = parseInt(data, 10);
                if (parseInt(data) >= 60)
                    data = 59;
                props.handleTime("min", data);
            }
        }
    }

    return (
        <div className={"timeInput-container"}>
            <div className={"timeInput-content"}>
                <div className={"hour-container col-6"}>
                    <i className={"material-icons"} onMouseDown={() => handleData("hour", props.hour + 1)}>arrow_drop_up</i>
                    <input className={"form-control col hour"} value={props.hour} onChange={(e) => handleData("hour", e.target.value)}/>
                    <i className={"material-icons"} onClick={() => handleData("hour", props.hour - 1)}>arrow_drop_down</i>
                </div>
                <span>:</span>
                <div className={"min-container col-6"}>
                    <i className={"material-icons"} onClick={() => handleData("min", props.minute + 1)}>arrow_drop_up</i>
                    <input className={"form-control col min"} value={props.minute} onChange={(e) => handleData("min", e.target.value)}/>
                    <i className={"material-icons"} onClick={() => handleData("min", props.minute - 1)}>arrow_drop_down</i>
                </div>
            </div>
        </div>
    )
}