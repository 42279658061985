import React, { useState, useEffect } from 'react'
import { Box, useTheme } from "@mui/material";
import ApiClient from "../../service/ApiClient";
import { Parser } from 'html-to-react';
import moment from "moment";

import TextEditor from '../../component/TextEditor/TextEditor';

export default function Histoire (props) {
    const theme = useTheme();
    
    const [history, setHistory] = useState(null);
    const [hour, setHour] = useState(null);
    const [date, setDate] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                await loadData();
            } catch (error) {
                console.log(error);
            }
        })();
    }, [])

    async function loadData() {
        try {
            let result = await ApiClient.instance.getHistory();

            setHour(moment(result.data.lastUpdate).format("HH:mm"));
            setDate(moment(result.data.lastUpdate).format("DD MMMM YYYY"));
            setHistory(result.data);
        } catch (error) {
            console.log(error);
        }
    }
    
    async function save(htmlText) {
        try {
            await ApiClient.instance.updateHistory({content: htmlText});
            props.createNotification("success", {title: "Succès", message: "Le contenu de la page histoire publié", timeout: 3000});
        } catch (error) {
            props.createNotification("error", {title: "Erreur", message: "Le contenu de la page histoire non publié", timeout: 3000});
            console.log(error);
        }
    }

    if (history !== null) {
        return (
            <Box>
                {props.isAdmin &&
                    <TextEditor placeholder={"Ecrivez quelques choses"} data={history.content} isAdmin={props.isAdmin} save={save}/>
                }
                {!props.isAdmin &&
                 Parser().parse(history.content)}

                <Box sx={{fontWeight: "bold", color: theme.palette.colors.green}}>Mis à jour le {date} à {hour}</Box>
            </Box>
        );
    } else {
        return (
            <Box></Box>
        );
    }
   
}