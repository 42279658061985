import React, { useState, useEffect } from 'react';
import {
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    TextField,
    Typography,
    FormControl,
    FilledInput
 } from "@mui/material";

import ApiClient from "../../service/ApiClient";
import ReactGA from "react-ga4";

import '../../assets/Authentification.scss'
import {Visibility, VisibilityOff} from "@mui/icons-material";
import { useLocation, useNavigate } from 'react-router-dom';

export default function Authentication(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [visibilityPassword, setVisibilityPassword] = useState(false);
    const [stayLog, setStayLog] = useState(false);
    const [notification, setNotification] = useState("");

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    async function handleSubmitSignIn() {
        try {
            await ApiClient.instance.login(email, password, stayLog)
            props.connect();
            navigate(location?.state?.prevUrl)

        } catch (err) {
            props.createNotification("error", {title: "Erreur", message: "Email ou mot de passe incorrect", timeout: 3000});
            onBadUser();
        }
    }

    function onBadUser() {
        setNotification("L'email ou le mot de passe est invalide.");
    }

    return (
      <Grid container direction={"row"} justifyContent={"center"} alignItems={"center"} style={{height: "100%"}}>
          <Grid className={"auth-container"} container item direction={"column"} justifyContent={"space-between"} alignItems={"center"} xs={11} md={6}>
              <Grid className={"auth-title"} item><Typography variant={"h4"} style={{marginLeft: "0"}}>Se connecter</Typography></Grid>
              <Grid className={"auth-input"} container item justifyContent={"center"} xs>
                  <Grid item xs={10} sm={8} md={6}>
                    <TextField
                      sx={{width: "100%"}}
                      autoComplete="email"
                      id="standard-error-helper-text"
                      label="Email"
                      variant="filled"
                      value={email}
                      onChange={event => {
                          setEmail(event.target.value);
                      }}
                    />
                  </Grid>
              </Grid>
              <Grid className={"auth-input"} container item justifyContent={"center"}>
                  <Grid item xs={10} sm={8} md={6}>
                  <FormControl sx={{ width: '100%' }} variant="filled">
                  <InputLabel htmlFor="filled-adornment-password">Mot de passe</InputLabel>
                  <FilledInput
                      id="filled-adornment-password"
                      type={visibilityPassword ? 'text' : 'password'}
                      value={password}
                      onChange={(event) => { setPassword(event.target.value) }}
                      endAdornment={
                          <InputAdornment position="end">
                              <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => { setVisibilityPassword(!visibilityPassword)}}
                                  onMouseDown={(event) => { event.preventDefault() }}
                              >
                                  {visibilityPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                          </InputAdornment>
                      }
                  />
                  </FormControl>
                  </Grid>
              </Grid>
              {notification !== "" && <div className="text-danger mt-3">{notification}</div>}
              <Grid className={"auth-input"} item>
                  <input className={"stay-log-checkbox"} type="checkbox" onChange={(event) => { setStayLog(!stayLog) }}/>
                      <label style={{marginBottom: "0"}}>Rester connecté ?</label>
              </Grid>
              <Grid item>
                  <a href={"/forgetPassword"} className={"forget-password"}>Mot de passe oublié ?</a>
              </Grid>
              <Grid className={"auth-button"} item>
                  <button onClick={handleSubmitSignIn} className="btn btn-primary"><Typography variant={"h5"}>Connexion</Typography></button>
              </Grid>
              <Grid className={"auth-register"} item>
                  <Typography variant={"h5"}>
                      Pas de compte ? <a href={"/inscription"}>S'inscrire
                      maintenant</a>
                  </Typography>
              </Grid>
          </Grid>
      </Grid>
    );
}
