import React, {useState} from "react";
import 'moment/locale/fr';
import { TextField, FormControl, FormControlLabel, Checkbox  } from "@mui/material";

import { Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle } from "../../component/Popup/Popup";

export default function AddUpdateTeamPopup(props) {
    const [season, setSeason] = useState(props.season ? {id: props.season.id, name: props.season.name, defaultSeason: props.season.defaultSeason} : {})
    const [defaultSeason, setDefaultSeason] = useState(props.season ? props.season.defaultSeason : false);
    const [error, setError] = useState(false);

    function handleAddSubmit() {
        if (season.name == null || season.name === "") {
            setError(true);
            return;
        }
        let data = season;
        data.defaultSeason = defaultSeason;
        props.submit(data);
    }

    function handleUpdateSubmit() {
        if (season.name == null || season.name === "") {
            setError(true);
            return;
        }
        let data = season;
        data.defaultSeason = defaultSeason;
        props.submit(data);
    }

    return (
        <Popup size={"large"} close={props.close}>
            <PopupHeader>
                <PopupTitle>{props.season ? "Modifier une saison" : "Créer une nouvelle saison"}</PopupTitle>
            </PopupHeader>

            <PopupBody>
                <div className={"row justify-content-center"}>
                    <TextField inputProps={{min: 0, style: { textAlign: 'center' }}} type={"text"} label={"Saison"} defaultValue={season.name} onChange={(event) => {
                        let tmp = season;
                        tmp.name = event.target.value;
                        setSeason(tmp);
                    }}/>
                </div>
                { props.season && 
                <div className={"row justify-content-center mt-4"}>
                <FormControlLabel label="Saison par defaut" control={
                <Checkbox checked={defaultSeason}  inputProps={{ 'aria-label': 'controlled', label: "Test" }} onChange={(event) => {
                        setDefaultSeason(event.target.checked);
                    }} />}>
                   
                </FormControlLabel>

                </div>
                }
                {error && props.season == null &&<small className={"text-danger col-12"}>Veuillez remplir tous les champs</small>}
                {error && props.season && <small className={"text-danger col-12"}>Le champ saison est obligatoire</small>}
            </PopupBody>

            <PopupFooter dismiss={"Annuler"} close={props.close}>
                <input className={"btn btn-primary"} type={"submit"} value={"Valider"}
                       onClick={() => props.season ? handleUpdateSubmit() : handleAddSubmit()}/>
            </PopupFooter>
        </Popup>
    );
}