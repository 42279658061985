import React, { useState } from "react";
import 'moment/locale/fr';
import moment from 'moment';
import { DayPicker } from "react-day-picker";
import fr from  'date-fns/locale/fr';
import 'react-day-picker/dist/style.css';

import {Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle} from "../../../component/Popup/Popup";
import TimeInput from "../../../component/Input/Time/TimeInput";

export default function UpdateArticlePopup(props) {
    const [article, setArticle] = useState({
        id: props.article.id,
        title: props.article.title !== undefined ? props.article.title : "",
        article: props.article.article !== undefined ? props.article.article : "",
        date: new Date(props.article.date),
        hour: moment(props.article.date).format("HH"),
        min: moment(props.article.date).format("mm"),
        year: moment(props.article.date).format("YYYY").toString(),
        picture: props.article.firstPicture,
    });
    const [selectedDay, setSelectedDay] = useState(new Date(props.article.date));


    // function handleYearMonthChange(date) {
    //     let tmp = article;
    //     tmp.Date = date;
    //     setArticle({...article, tmp})
    // }

    // function YearMonthForm(date, localeUtils, onChange) {
    //     const months = localeUtils.getMonths();
    //
    //     return (
    //         <form className="DayPicker-Caption">
    //             <select name="month" onChange={(e) => {
    //                 const { year, month } = e.target.form;
    //                 handleYearMonthChange(new Date(year.value, month.value));
    //             }} value={date.getMonth()}>
    //                 {months.map((month, i) => (
    //                     <option key={month} value={i}>
    //                         {month}
    //                     </option>
    //                 ))}
    //             </select>
    //
    //             <input name="year" type={"text"} placeholder={"Année"} key={"year"}
    //                    value={date.getFullYear()} onChange={(e) => {
    //                 const { year, month } = e.target.form;
    //                 handleYearMonthChange(new Date(year.value, month.value));
    //             }}/>
    //         </form>
    //     );
    // }
    //
    // function buildCard(index) {
    //     return (<div className={"col-6 pt-3"} key={index}>
    //         <div className={"card text-center"}>
    //             <img src={URL.createObjectURL(article.picture[index].file)}
    //                  className={"img-thumbnail cursor-pointer "} alt="..."/>
    //             <div className={"card-body p-0"}>
    //                 <input className="form-control" type={"text"} placeholder={"Ajouter une légende"}
    //                        value={article.picture[index].pictureTitle} onChange={(event) => {
    //                     let tmp = article;
    //                     tmp.picture[index].pictureTitle = event.target.value;
    //                     setArticle({...article, tmp});
    //                 }}/>
    //             </div>
    //         </div>
    //     </div>);
    //
    // }
    //
    // function buildImagePreview() {
    //     let list = [];
    //     let row = [];
    //
    //     for (let i = 0; i < article.picture.length; i++) {
    //         for (let count = 0; count < 2 && i < article.picture.length; i++, count++){
    //             row.push(this.buildCard(i));
    //         }
    //         i--;
    //         list.push(<div className={"row"} key={i}>{row}</div>);
    //         row = [];
    //     }
    //     return list;
    // }

    function handleTime(type, data) {
        let tmp = article;
        tmp[type] = data;
        if (type === "hour")
            tmp.date.setHours(data);
        else
            tmp.date.setMinutes(data);
        setArticle({...article, tmp});
    }

    function handleSubmit() {
        if (article.title === "")
            return;
        if (article.article === "")
            return;
        props.modify(article.id, article, selectedDay);
    }

    return (
        <Popup size={"large"} close={() => props.close("PopupModify")} sx={{zIndex: "2"}}>
            <PopupHeader>
                <PopupTitle>Modifier un article</PopupTitle>
            </PopupHeader>

            <PopupBody>
                <div className={"form-container"}>
                    <label className={"text-left"}>Titre<i
                        className={"text-danger"}> *</i></label>
                    <input className="form-control" type={"text"} placeholder={"Ajouter un titre"}
                           value={article.title} onChange={(event) => {
                        let tmp = article;
                        tmp.title = event.target.value;
                        setArticle({...article, tmp});
                    }}/>
                    {!article.title &&
                    <small className={"text-danger col-12"}>Ce champ est obligatoire</small>}
                </div>
                <div className={"form-group"}>
                    <div>
                        <label className={"text-left"}>Texte<i
                            className={"text-danger"}> *</i></label>
                        <textarea className="form-control input-article" placeholder={"Ajouter du texte"}
                                  value={article.article} onChange={(event) => {
                            let tmp = article;
                            tmp.article = event.target.value;
                            setArticle({...article, tmp});
                        }}/>
                    </div>
                    {!article.article &&
                    <small className={"text-danger col-12"}>Ce champ est obligatoire</small>}
                </div>

                <div className={"w-100 horizontal-top-line-grey-dark row dateTime"}>
                    <h6 className={"w-100"}>Horaires</h6>
                    <DayPicker className={"date-picker"} mode={"single"} selected={selectedDay} onSelect={setSelectedDay}
                               locale={fr}/>
                    <div className={"col-6"}>
                        <TimeInput hour={article.hour} minute={article.min}
                                   handleTime={handleTime}/>
                    </div>
                </div>

                {/*<div className={"w-100 text-center row"}>
                        <label className={"w-100 col-12"}>Image</label>
                        <input accept=".png, .jpg" type={"file"} multiple className={"input-image col"}
                               onChange={(event) => {
                                   let tmp = article;
                                   for (let i = 0; i < event.target.files.length; i++) {
                                       tmp.picture.push({
                                           pictureID: i,
                                           file: event.target.files[i],
                                           pictureTitle: ""
                                       });
                                   }
                                   setArticle(tmp)
                               }}/>
                    </div>*/}

                    {/*article.picture.length !== 0 &&
                    buildImagePreview()
                    */}
            </PopupBody>

            <PopupFooter dismiss={"Annuler"} close={() => props.close("PopupModify")}>
                <input className={"btn btn-primary"} type={"submit"} value={"Valider"}
                       onClick={() => handleSubmit()}/>
            </PopupFooter>
        </Popup>);
}