import React, {useEffect, useState} from 'react';
import {Box, Grid, TextField, useTheme } from "@mui/material";
import ReactGA from "react-ga4";

import "../../assets/Contact.scss"
import DropzoneFile from "../../component/Input/DropezoneFile/DropzoneFile";
import Button from "@mui/material/Button";
import ApiClient from "../../service/ApiClient";
import Map from "../../component/Map/Map";


export default function Contact(props) {
    const theme = useTheme();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [firstNameError, setFirstNameError] = useState(null);
    const [lastNameError, setLastNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [phoneNumberError, setPhoneNumberError] = useState(null);
    const [subjectError, setSubjectError] = useState(null);
    const [messageError, setMessageError] = useState(null);
    const [attachment, setAttachment] = useState([]);

    const styles = {
        leftContainer: {
            backgroundColor: theme.palette.colors.whiteBox,
        },
        rightContainer: {
            [theme.breakpoints.up('xs')]: {
                paddingTop: "2rem"
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: "0"
            },
        },
        inputCustomize: {
            color: theme.palette.colors.green + "!important",
            backgroundColor: theme.palette.colors.whiteBox
        },
        '& label.Mui-focused': {
            color: theme.palette.colors.green,
        },
        submitButton: {
            borderRadius: "0!important",
            border: "none!important",
            backgroundColor: theme.palette.colors.green + "!important",
            transition: "0.5s!important",
            '&:hover': {
                backgroundColor: "black!important"
            },
        },
        leftTitle: {
            display: "inline-block"
        },
        lineTitle: {
            position: "relative",
            zIndex: "2",
            textTransform: "uppercase",
            marginBottom: "1rem",
            '&:after': {
                marginLeft: "1rem",
                position: "absolute",
                width: "50%",
                height: "2px",
                backgroundColor: "black",
                bottom: "12px",
                content: "''",
                zIndex: "2",
                borderBottom: "none!important",
                [theme.breakpoints.up('md')]: {
                    width: "45%",
                },
                [theme.breakpoints.up('lg')]: {
                    width: "60%",
                },
    
            },
        },
        lineTitleGreen: {
            color:  theme.palette.colors.green,
            fontWeight: "bold",
            textTransform: "uppercase",
            '&:after': {
                marginLeft: "1rem",
                position: "absolute",
                width: "50px",
                height: "2px",
                backgroundColor: theme.palette.colors.green,
                bottom: "12px",
                content: "''",
                zIndex: "3",
                borderBottom: "none!important"
            },
        },
        subtitleLeft: {
            fontWeight: "bold"
        },
        contentLeft: {
            '& span': {
                fontWeight: "bold"
            }
        },
        contactText: {
            display: "inline-flex",
            color: "black",
            '&:hover': {
                color: theme.palette.colors.green + "!important",
                textDecoration: "none!important"
            },
        }
    };

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    useEffect(() => {
        (async () => {
            if (firstNameError === "" && lastNameError === "" && emailError === ""
                && phoneNumberError === "" && subjectError === "" && messageError === "") {
                await ApiClient.instance.sendMail(buildContactUsMail());
                await ApiClient.instance.sendMail(buildContactUsAccuseReceptionMail());
                resetForm();
                props.createNotification("success", {title: "Succès", message: "Message envoyé", timeout: 3000})
            } else if (firstNameError !== null && lastNameError !== null && emailError !== null
                && phoneNumberError !== null && subjectError !== null && messageError !== null) {
                props.createNotification("error", {title: "Erreur", message: "Message non envoyé des champs obligatoires sont vide ou invalide", timeout: 3000})
            }
        })();
    }, [firstNameError, lastNameError, emailError, phoneNumberError, subjectError, messageError]);

    function handlePhoneNumber(event) {
        const phoneRegex = new RegExp("^[0-9 ]{0,14}$");
        let number = event.target.value;
        if (phoneRegex.test(number)) {
           if (number.length !== 0) {
                number = number.replace(/\s/g, '');
                number = number.match(/.{1,2}/g);
                number = number.join(" ");
            }
            setPhoneNumber(number);
        }
    }

    function handleChangeAttachment(data) {
        setAttachment(data);
    }

    function buildContactUsMail() {
        let fd = new FormData();

        fd.append("templateMail", "templateMail_contactUs")
        fd.append("destMail", process.env.REACT_APP_MAIL);
        fd.append("userName", lastName + " " + firstName);
        fd.append("userMail", email);
        if (phoneNumber !== "") {
            fd.append("userPhone", phoneNumber);
        }
        fd.append("subject", subject);
        fd.append("message", message);
        attachment.forEach(file => {
            fd.append("attachmentFiles", file.file);
        })
        return (fd);
    }

    function buildContactUsAccuseReceptionMail() {
        let fd = new FormData();

        fd.append("templateMail", "templateMail_contactUs_accuseReception")
        fd.append("destMail", email);
        fd.append("userName", lastName + " " + firstName);
        fd.append("subject", "Accusé de reception: " + subject);

        return (fd);
    }

    function resetForm() {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        setSubject("");
        setMessage("")
        setAttachment([]);
        setFirstNameError(null);
        setLastNameError(null);
        setEmailError(null);
        setPhoneNumberError(null);
        setSubjectError(null);
        setMessageError(null);
        attachment.forEach(file => {
            file.remove();
        });
        if (attachment.length !== 0) {
            attachment[0].remove();
        }
    }

    async function handleSubmit() {
        const mailRegex = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$");

        firstName === "" ? setFirstNameError("Ce champ est nécessaire") : setFirstNameError("");
        lastName === "" ? setLastNameError("Ce champ est nécessaire") : setLastNameError("");
        email === "" ? setEmailError("Ce champ est nécessaire") : (!mailRegex.test(email)) ? setEmailError("Veuillez saisir une adresse e-mail valide.") : setEmailError("");
        phoneNumber.length !== 14 && phoneNumber !== "" ? setPhoneNumberError("Veuillez saisir un numéro de téléphone valide.") : setPhoneNumberError("");
        subject === "" ? setSubjectError("Ce champ est nécessaire") : setSubjectError("");
        message === "" ? setMessageError("Ce champ est nécessaire") : setMessageError("");
    }

    function buildPreview({meta, fileWithMeta}) {
        const { name } = meta;
        return (
            <Grid container justifyContent={"flex-start"} alignItems={"center"} item style={{fontWeight: "bold"}}>
                <Grid item style={{lineHeight: "1"}}><i className="material-icons remove" style={{}} onClick={fileWithMeta.remove}>clear</i></Grid>
                <Grid item><span>{name}</span></Grid>
            </Grid>
        );
    }

    return (
        <Grid container justifyContent={"space-between"}>
            <Grid container direction={"column"} item sx={styles.leftContainer} xs={12} md={8} lg={8}>
                <Grid container item justifyContent={"space-between"} style={{padding: "1rem"}}>
                    <Grid item xs={5.5}>
                        <TextField
                            autoComplete="given-name"
                            fullWidth
                            required
                            id="standard-error-helper-text"
                            label="Votre prénom"
                            variant="filled"
                            value={firstName}
                            helperText={firstNameError}
                            onChange={event => {
                                setFirstNameError(null);
                                setFirstName(event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={5.5}>
                        <TextField
                            autoComplete="family-name"
                            fullWidth
                            required
                            id="standard-error-helper-text"
                            label="Votre nom"
                            variant="filled"
                            value={lastName}
                            helperText={lastNameError}
                            onChange={event => {
                                setLastNameError(null);
                                setLastName(event.target.value);
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container item justifyContent={"flex-start"} style={{padding: "1rem"}}>
                    <Grid item xs={5.5}>
                        <TextField
                            autoComplete="email"
                            fullWidth
                            required
                            id="standard-error-helper-text"
                            label="Votre adresse e-mail"
                            variant="filled"
                            value={email}
                            helperText={emailError}
                            onChange={event => {
                                setEmailError(null);
                                setEmail(event.target.value);
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container item justifyContent={"flex-start"} style={{padding: "1rem"}}>
                    <Grid item xs={5.5}>
                        <TextField
                            autoComplete="tel"
                            fullWidth
                            id="standard-error-helper-text"
                            label="Numéro de téléphone"
                            variant="filled"
                            value={phoneNumber}
                            helperText={phoneNumberError}
                            onChange={event => {
                                setPhoneNumberError(null);
                                handlePhoneNumber(event);
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container item justifyContent={"flex-start"} style={{padding: "1rem"}}>
                    <Grid item xs={5.5}>
                        <TextField
                            fullWidth
                            required
                            id="standard-error-helper-text"
                            label="Sujet de votre message"
                            variant="filled"
                            value={subject}
                            helperText={subjectError}
                            onChange={event => {
                                setSubjectError(null);
                                setSubject(event.target.value);
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container item justifyContent={"flex-start"} style={{padding: "1rem"}}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="filled-multiline-static"
                            required
                            label="Votre message"
                            multiline
                            rows={10}
                            variant="filled"
                            value={message}
                            helperText={messageError}
                            onChange={event => {
                                setMessageError(null);
                                setMessage(event.target.value);
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container item justifyContent={"flex-start"} style={{padding: "1rem"}}>
                    <Grid item xs={12}>
                        <DropzoneFile handleChange={handleChangeAttachment} attachmentFile={attachment} accept={"*"} buildPreview={buildPreview}/>
                    </Grid>
                </Grid>

                <Grid container item justifyContent={"flex-start"} style={{padding: "0 1rem 1rem 1rem"}}>
                    <Grid item xs={12}>
                        <Button fullWidth variant="contained" sx={styles.submitButton} onClick={handleSubmit}>ENVOYER</Button>
                    </Grid>
                </Grid>


            </Grid>

            <Grid container direction={"column"} item sx={styles.rightContainer} xs={12} md={3.75} lg={3}>
                <Grid item>
                    <Box sx={styles.lineTitle}>
                        <h5 style={styles.leftTitle}>Nos <span style={styles.lineTitleGreen}>Coordonnées</span></h5>
                    </Box>
                </Grid>
                <Grid item sx={styles.contentLeft}>
                    <p>
                         <a style={styles.contactText} href="mailto:contact@usmenebre.fr"><i className="material-icons contactIcon">email</i> contact@usmenebre.fr</a> <br/>
                        <a style={styles.contactText} href="tel:06 72 71 42 66"><i className="material-icons contactIcon">phone</i> 06 72 71 42 66</a>
                        <a style={styles.contactText} target={"_blank"} href="https://www.google.fr/maps/place/US+M%C3%A9n%C3%A9+Br%C3%A9/@48.5691599,-3.3436608,17z/data=!4m15!1m8!3m7!1s0x4811858ce7e9d8f1:0xc7c893fb21856c40!2s9+Toullan+Bourg,+22540+Louargat!3b1!8m2!3d48.5691599!4d-3.3410859!16s%2Fg%2F11smz7skyp!3m5!1s0x4811858ce954ea09:0x20666a1605e9784e!8m2!3d48.569817!4d-3.3415638!16s%2Fg%2F11khzn_8lg?entry=ttu"><i className="material-icons contactIcon">home</i>9 Toullan Bourg – 22540 Louargat</a>
                    </p>
                </Grid>

                <Grid item>
                    <h6 style={styles.subtitleLeft}>Complexe sportif</h6>
                    <p>9 Toullan Bourg – 22540 Louargat</p>
                </Grid>

                <Grid item>
                    <h6 style={styles.subtitleLeft}>Stade Louis Torquéau</h6>
                    <p>2 rue du Stade – 22540 Louargat</p>
                </Grid>

                <Grid container item>
                    <Grid item xs={12}>
                        <Map/>
                    </Grid>

                </Grid>
            </Grid>

        </Grid>
    )
}
