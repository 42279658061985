import React, { useState } from "react";
import 'moment/locale/fr';
import { DayPicker } from "react-day-picker";
import fr from  'date-fns/locale/fr';
import 'react-day-picker/dist/style.css';

import { Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle } from "../../../component/Popup/Popup";
import TimeInput from "../../../component/Input/Time/TimeInput";
import "./AddArticlePopup.scss"
import {CircularProgress } from "@mui/material";
import moment from "moment";
import ApiClient from "../../../service/ApiClient";

const emptyArticle = {
        title: '',
        article: '',
        picture: [],
        firstPicture: '',
        hour: 0,
        min: 0,
        date: new Date()
}

export default function AddArticlePopup (props) {
    const [article, setArticle] = useState(emptyArticle);
    const [selectedDay, setSelectedDay] = useState(new Date());
    const [picOnly, setPicOnly] = useState(false);
    const [changeDate, setChangeDate] = useState(false);
    const [selectedPicture, setSelectedPicture] = useState([]);
    const [isUploading, setIsUploading] = useState(false);

    async function handleSubmit() {
        if (article.title === "")
            return;
        if (article.article === "" && !picOnly)
            return;
        await addArticle();
    }

    async function addArticle() {
        try {
            setIsUploading(true);
            if (changeDate) {
                selectedDay.setHours(article.hour);
                selectedDay.setMinutes(article.min);
                article.date = new moment(selectedDay).format("YYYY-MM-DD HH:mm:ss");
                article.year = new moment(selectedDay).format("YYYY").toString();
            } else {
                article.date = new moment().format("YYYY-MM-DD HH:mm:ss");
                article.year = new moment().format("YYYY").toString();
            }
            let result = await ApiClient.instance.postArticle(article);
            if (selectedPicture.length !== 0) {
                let selectedPictureBuild = [];
                for(let i = 0; i < selectedPicture.length; i++) {
                    selectedPictureBuild.push({file: selectedPicture[i], pictureTitle: selectedPicture[i].pictureTitle !== undefined ? selectedPicture[i].pictureTitle: ""});
                }
                await ApiClient.instance.postArticlePic(selectedPictureBuild, result.data.articleId);
            }
            setIsUploading(false);
            props.close();
            props.createNotification("success", {title: "Succès", message: "Article publié", timeout: 3000})
        } catch (e) {
            setIsUploading(false);
            props.close();
            props.createNotification("error", {title: "Erreur", message: "Article non publié", timeout: 3000})
            console.log(e);
        }
    }

    function handleTime(type, data) {
        let tmp = article;
        tmp[type] = data;
        if (type === "hour")
            tmp.date.setHours(data);
        else
            tmp.date.setMinutes(data);
        if (data === "") {
            setArticle({...article, tmp})
            return;
        }
        setArticle({...article, tmp})
    }

    function buildCard(index) {
        return (<div className={"col-4 pt-3"} key={index}>
            <div className={"card text-center preview-image" + (selectedPicture.includes(article.picture[index].file) ? " selected" : "")}>
                { selectedPicture.includes(article.picture[index].file) &&
                    <span className={"span-order"}>{selectedPicture.indexOf(article.picture[index].file) + 1}</span>
                }
                <img src={URL.createObjectURL(article.picture[index].file)} onClick={() => {
                    let tmp = selectedPicture;
                    if (tmp.includes(article.picture[index].file))
                        tmp.splice(tmp.indexOf(article.picture[index].file), 1);
                    else {
                        tmp.push(article.picture[index].file);
                    }
                    setSelectedPicture([...tmp]);
                }} className={"img-thumbnail cursor-pointer "} alt="..."/>
                <div className={"card-body p-0"}>
                    {selectedPicture.includes(article.picture[index].file) &&
                    <input className="form-control" type={"text"} placeholder={"Ajouter une légende"}
                           value={selectedPicture[selectedPicture.indexOf(article.picture[index].file)].pictureTitle} onChange={(event) => {
                        let tmp = selectedPicture;
                        tmp[tmp.indexOf(article.picture[index].file)].pictureTitle = event.target.value;
                        setSelectedPicture([...tmp]);
                    }}/>
                    }
                </div>
            </div>
        </div>);
    }

    function buildImagePreview() {
        let list = [];
        let row = [];

        let tmp = article;
        if (tmp.firstPicture === '' && picOnly)
            tmp.firstPicture = article.picture[0].file.name;
        for (let i = 0; i < article.picture.length; i++) {
            for (let count = 0; count < 3 && i < article.picture.length; i++, count++){
                row.push(buildCard(i));
            }
            i--;
            list.push(<div className={"row"} key={i}>{row}</div>);
            row = [];
        }
        return list;
    }

    return (
        <Popup size={"large"} close={() => {
            setArticle({...article, emptyArticle});
            if (!isUploading)
                props.close();
        }} sx={{zIndex: 10}}>
            <PopupHeader>
                { isUploading &&
                <PopupTitle>Publication du nouvel article</PopupTitle>}
                { !isUploading &&
                <PopupTitle>Créer un nouvel article</PopupTitle>}
            </PopupHeader>

            { !isUploading &&
            <PopupBody>
                        <div className={"form-container"}>
                            <label className={"text-left"}>Titre<i
                                className={"text-danger"}> *</i></label>
                            <input className="form-control" type={"text"} placeholder={"Ajouter un titre"}
                                   value={article.Title} onChange={(event) => {
                                let tmp = article;
                                tmp.title = event.target.value;
                                setArticle({...article, tmp});
                            }}/>
                            {!article.title &&
                            <small className={"text-danger col-12"}>Ce champ est obligatoire</small>}
                        </div>

                        {!picOnly &&
                        <div className={"form-group"}>
                            <div>
                                <label className={"text-left"}>Texte<i
                                    className={"text-danger"}> *</i></label>
                                <textarea className="form-control input-article" placeholder={"Ajouter du texte"}
                                          value={article.Article} onChange={(event) => {
                                    let tmp = article;
                                    tmp.article = event.target.value;
                                    setArticle({...article, tmp});
                                }}/>
                                {!article.article &&
                                <small className={"text-danger col-12"}>Ce champ est obligatoire</small>}
                            </div>
                        </div>
                        }

                        <div className={"changeDate-container row mt-3"}>
                            <div className={"col-1"}>
                                <input type={"checkbox"} value={"date"} checked={picOnly} onChange={() => setPicOnly(!picOnly)}/>
                            </div>
                            <p>Article photo uniquement</p>
                        </div>

                        <div className={"changeDate-container row mt-3"}>
                            <div className={"col-1"}>
                                <input type={"checkbox"} value={"date"} checked={changeDate} onChange={() => setChangeDate(!changeDate)}/>
                            </div>
                            <p>Définir date et heure</p>
                        </div>

                        {changeDate &&
                        <div className={"w-100 horizontal-top-line-grey-dark row dateTime"}>
                            <h6 className={"w-100"}>Horaires</h6>
                            <DayPicker className={"date-picker"} mode={"single"} selected={selectedDay} onSelect={setSelectedDay}
                                       locale={fr}/>
                            <div className={"col-6"}>
                                <TimeInput hour={article.hour} minute={article.min}
                                           handleTime={handleTime}/>
                            </div>
                        </div>
                        }

                        <div className={"w-100 text-center row horizontal-top-line-grey-dark"}>
                            <h6 className={"w-100"}>Photo</h6>
                            <input accept=".png, .jpg" type={"file"} multiple className={"input-image col text-center"}
                                   onChange={(event) => {
                                       let tmp = article;
                                       for (let i = 0; i < event.target.files.length; i++) {
                                           tmp.picture.push({
                                               pictureId: i,
                                               file: event.target.files[i],
                                               pictureTitle: ""
                                           });
                                       }
                                       setArticle({...article, tmp});
                                   }}/>
                        </div>

                        {article.picture.length !== 0 &&
                        buildImagePreview()
                        }
            </PopupBody>}

            { isUploading &&
            <PopupBody>
                    <CircularProgress color="secondary" />
                </PopupBody>}

            { !isUploading &&  <PopupFooter dismiss={"Annuler"} close={() => {
                setArticle({...article, emptyArticle});
                props.close();
            }}>
                <input className={"btn btn-primary"} type={"submit"} value={"Créer"}
                       onClick={() => handleSubmit()}/>
            </PopupFooter>}
        </Popup>
    );
}