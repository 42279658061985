import React from 'react';
import 'moment/locale/fr';
import { Grid, useTheme } from "@mui/material";


import "../../assets/Classement.scss"

export default function ReglementMonProno() {
    const theme = useTheme();

    const styles = {
        pronoRuleContainer: {
            marginTop: "1rem!important",
            backgroundColor: theme.palette.colors.whiteBox,
            padding: "1rem"
        },
        pronoRuleTitle: {
            margin: "1rem 2rem",
            fontWeight: "bold",
            fontSize: "20px"
        },
        pronoRuleTextTitle: {
            margin: "1rem 2rem",
            fontWeight: "bold",
            fontSize: "15px"
        },
        pronoRuleTextBody: {
            margin: "0 2rem",
            fontSize: "15px"
        },
        pronoTitleExample: {
            textDecoration: "underline",
            fontWeight: "bold"
        },
        pronoRuleLink: {
            color: theme.palette.colors.green,
        }
    };

    return (
        <Grid container justifyContent={"center"}>
            <Grid item sx={styles.pronoRuleContainer} xs={12} md={8}>
                <Grid container justifyContent={"center"} item xs={12}>
                    <Grid item>
                        <h2 style={styles.pronoRuleTitle}>Règlement du jeu « Pronos »</h2>
                    </Grid>
                </Grid>
                <p style={styles.pronoRuleTextBody}>Le jeu « Pronos » est gratuit et ouvert à tous. Il se joue sur l’ensemble de la saison et des matchs officiels (championnat et coupe), des équipes seniors, à domicile ou à l’extérieur, de l’US Méné Bré (USMB). Création d’un compte sur le site de l’USMB obligatoire pour pouvoir participer au jeu. </p>
                <h4 style={styles.pronoRuleTextTitle}>Comment ?</h4>
                <p style={styles.pronoRuleTextBody}>Chaque semaine, il sera possible de pronostiquer sur le résultat des matchs du week-end à venir. <br/>
                    Les pronostics devront être passés au plus tard 1 heure avant le début de la rencontre. Plus de pronostics possibles sur les matchs passés.<br/>
                    Un classement des joueurs sera réalisé selon les résultats. <br/>
                    Les meilleurs joueurs recevront une récompense en fin de saison (un lot maximum par personne).</p>
                <h4 style={styles.pronoRuleTextTitle}>Fonctionnement ?</h4>
                <Grid sx={styles.pronoRuleTextBody} container item xs={12} justifyContent={"space-evenly"}>
                    <Grid item>
                        <ul>
                            <h6 style={styles.pronoTitleExample}>Barème de point :</h6>
                            <br/>
                            <br/>
                            <li>3 points le score exact</li>
                            <li>0 point le mauvais résultat</li>
                            <li>1 point le résultat <br/> (Match gagnant, perdant ou ex-aequo)</li>
                        </ul>
                    </Grid>
                    <Grid item>
                        <h6 style={styles.pronoTitleExample}>Exemple :</h6>
                        <p>Match joué : score final : 2 – 0 <br/> <br/>
                        Joueur  1 : 2 - 0<br/>
                        Joueur  3 : 0 – 0<br/>
                        Joueur  2 : 1 - 0</p>
                    </Grid>
                </Grid>
                <p style={styles.pronoRuleTextBody}>A noter que pour les matchs de coupe le résultat des pronos est celui du match à la fin des 90 minutes.</p>
                <p style={styles.pronoRuleTextBody}>Tout match non joué ne comptera pas pour les résultats des pronostics (ex: forfait, report)</p>
                <h4 style={styles.pronoRuleTextTitle}>Bonus</h4>
                <p style={styles.pronoRuleTextBody}>Les pronostiqueurs ont la possibilité d'utiliser des bonus pour augmenter leurs points sur le pronostic de leur choix. Les bonus ont un nombre limité d'utilisations sur la saison.</p>
                <Grid sx={styles.pronoRuleTextBody} container item xs={12} justifyContent={"space-evenly"}>
                    <Grid item xs={10}>
                    <ul>
                        <br/>
                        <li><b>Multiplicateur 2:</b> Double le résultat d'un de vos pronostics. (Utilisation par saison: 1)</li>
                        <li><b>Quitte ou double:</b> Soit vous avez le résultat exact et votre score est doublé soit vous repartez bredouille. (Utilisation par saison: 2)</li>
                        <li><b>Seconde chance:</b> Même si votre pronostic est mauvais la seconde chance vous permet d'empocher 1 point. (Utilisation par saison: 2)</li>
                    </ul>
                    </Grid>
                </Grid>
                   
                <h4 style={styles.pronoRuleTextTitle}>Classement</h4>
                <p style={styles.pronoRuleTextBody}>A chaque fin de match, un classement provisoire sera établi et visible en temps réel sur le site internet de l’USMB. En fin de saison, le classement final sera effectué selon le nombre de points obtenus par chaque participant.<br/>
                    En cas d’égalité de points,  le deuxième critère sera le nombre de match pronostiqués : le gagnant sera le joueur ayant le moins de matches joués pour atteindre ce résultat.
                </p>
                <h4 style={styles.pronoRuleTextTitle}>Résultats</h4>
                <p style={styles.pronoRuleTextBody}>Les résultats seront affichés sur le site de l’USMB et les gagnants contactés par mail. Le gagnant devra confirmer l’acceptation de son lot sous un délai de 15 jours. A défaut, le lot sera remis en jeu et attribué au joueur suivant dans le classement, selon les mêmes conditions d’attribution. Les lots ne pourront pas faire l’objet d’échange ni  de remboursement en équivalent de valeur.</p>
                <h4 style={styles.pronoRuleTextTitle}>Données personnelles</h4>
                <p style={styles.pronoRuleTextBody}>Les mentions relatives aux données personnelles sont consultables directement dans la partie « <a style={styles.pronoRuleLink} href={"/mentions-legales"}>Mentions légales</a> » sur la page du site de l’USMB.</p>
            </Grid>
        </Grid>
    );
}