import React, {useState} from "react";
import { DayPicker } from "react-day-picker";
import fr from  'date-fns/locale/fr';
import 'react-day-picker/dist/style.css';

import { Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle } from "../../component/Popup/Popup";

export default function UpdateArticlePressePopup(props) {
    const [article, setArticle] = useState({
        Id: props.article.Id,
        Title: props.article.Title,
        Date: new Date(props.article.Date),
        picture: props.article.picture,
    });
    const [date, setDate] = useState(new Date(props.article.Date));

    function buildCard(index)  {
        return (<div className={"col-6 pt-3"} key={index}>
            <div className={"card text-center"}>
                <img src={URL.createObjectURL(article.picture[index].file)}
                     className={"img-thumbnail cursor-pointer "} alt="..."/>
                <div className={"card-body p-0"}>
                    <input className="form-control" type={"text"} placeholder={"Ajouter une légende"}
                           value={article.picture[index].pictureTitle} onChange={(event) => {
                        let tmp = article;
                        tmp.picture[index].pictureTitle = event.target.value;
                        setArticle(tmp);
                    }}/>
                </div>
            </div>
        </div>);
    }

    function buildImagePreview() {
        let list = [];
        let row = [];

        for (let i = 0; i < article.picture.length; i++) {
            for (let count = 0; count < 2 && i < article.picture.length; i++, count++){
                row.push(buildCard(i));
            }
            i--;
            list.push(<div className={"row"} key={i}>{row}</div>);
            row = [];
        }
        return list;
    }

    function handleSubmit() {
        let articleTmp = article;
        if (article.Title === "")
            return;
        if (article.Article === "")
            return;
        articleTmp.Date = date;
        props.modify(article.Id, articleTmp);
    }

    return (<Popup size={"large"} close={() => props.close("PopupModify")}>
        <PopupHeader>
            <PopupTitle>Modifier un article</PopupTitle>
        </PopupHeader>

        <PopupBody>
            <div className={"form-container"}>
                <label className={"text-left"}>Titre<i
                    className={"text-danger"}> *</i></label>
                <input className="form-control" type={"text"} placeholder={"Ajouter un titre"}
                       value={article.Title} onChange={(event) => {
                    let tmp = article;
                    tmp.Title = event.target.value;
                    setArticle({...article, tmp});
                }}/>
                {!article.Title &&
                <small className={"text-danger col-12"}>Ce champ est obligatoire</small>}
            </div>

            <div className={"w-100 horizontal-top-line-grey-dark row dateTime"}>
                <h6 className={"w-100"}>Date</h6>
                <DayPicker className={"date-picker"} mode={"single"} selected={date} onSelect={setDate}
                           locale={fr}/>
            </div>

            {/* <div className={"w-100 text-center row"}>
                        <label className={"w-100 col-12"}>Image</label>
                        <input accept=".png, .jpg" type={"file"} multiple className={"input-image col"}
                               onChange={(event) => {
                                   var article = this.state.article;
                                   for (let i = 0; i < event.target.files.length; i++) {
                                       article.picture.push({
                                           pictureID: i,
                                           file: event.target.files[i],
                                           pictureTitle: ""
                                       });
                                   }
                                   this.setState({article: article});
                               }}/>
                    </div>

                    {this.state.article.picture.length !== 0 &&
                    this.buildImagePreview()
                    }*/}
        </PopupBody>

        <PopupFooter dismiss={"Annuler"} close={() => props.close("PopupModify")}>
            <input className={"btn btn-primary"} type={"submit"} value={"Valider"}
                   onClick={() => handleSubmit()}/>
        </PopupFooter>
    </Popup>);
}