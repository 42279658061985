import { Navigate, Route, Routes } from "react-router-dom";
import { useState } from "react";

import Authentification from "../scenes/Authentification/Authentification";
import Accueil from "../scenes/Accueil/Accueil";
import Actualite from "../scenes/Actualité/Actualite";
import ActualiteShowMore from "../scenes/Actualité/ActualiteShowMore";
import Calendrier from "../scenes/Calendrier/Calendrier";
import Classement from "../scenes/Classement/Classement";
import RevuePresse from "../scenes/RevuePresse/RevuePresse";
import EspaceVideos from "../scenes/EspaceVideos/EspaceVideos";
import Acces from "../scenes/InfoPratique/Acces";
import Contact from "../scenes/Contact/Contact";
import Partenaire from "../scenes/Partenaire/Partenaire";
import UnsubscribePronoReminder from "../scenes/PronoReminder/UnsubscribePronoReminder";
import Categorie from "../scenes/Categorie/Categorie";
import Equipe from "../scenes/Equipe/Equipe";
import Season from "../scenes/Saison/Season"
import Evenement from "../scenes/Evenement/Evenement"; 
import Inscription from "../scenes/Authentification/Inscription";
import ForgetPassword from "../scenes/Authentification/ForgetPassword";
import ChangePassword from "../scenes/Authentification/ChangePassword";
import ActivateAccount from "../scenes/Authentification/ActivateAccount";
import MonProno from "../scenes/MonProno/MonProno";
import Profile from "../scenes/Profile/Profile";
import Histoire from '../scenes/Histoire/Histoire';
import MentionsLegales from "../scenes/MentionsLegales/MentionsLegales";
import Boutique from "../scenes/Boutique/Boutique";
import Product from "../scenes/Boutique/Product";
import Cart from "../scenes/Boutique/Cart";
import Checkout from "../scenes/Checkout/Checkout";
import OrderSucces from "../scenes/Boutique/OrderSucces";
import BoutiqueAdmin from "../scenes/BoutiqueAdmin/BoutiqueAdmin";

export function ClubSiteRoutes(props) {
    return (
        <Routes>
            <Route exact path="/" name="home" element={ <Accueil dimensions={props.dimensions.width} isAdmin={props.isAdmin} createNotification={props.createNotification}/> }/>
            <Route exact path="/histoire" name="histoire" element={ <Histoire isAdmin={props.isAdmin} createNotification={props.createNotification}/> }/>
            <Route exact path="/actu/:year" name="actu" element={ <Actualite isAdmin={props.isAdmin} createNotification={props.createNotification}/> }/>
            <Route exact path="/actu/:year/:id" name="actuShowMore" element={ <ActualiteShowMore/> }/>
            <Route exact path="/calendrier/:team" name="calendrier" element={ <Calendrier isAdmin={props.isAdmin} createNotification={props.createNotification}/> }/>
            <Route exact path="/classement/:type/:team" name="classement" element={ <Classement isAdmin={props.isAdmin} createNotification={props.createNotification}/> }/>
            <Route exact path="/revue-presse" name="revuePresse" element={ <RevuePresse isAdmin={props.isAdmin} createNotification={props.createNotification}/> }/>
            <Route exact path="/espace-videos" name="espaceVideo" element={ <EspaceVideos/> }/>
            <Route exact path="/acces" name="acces" element={ <Acces/> }/>
            <Route exact path="/contacts" name="contacts" element={ <Contact createNotification={props.createNotification}/> }/>
            <Route exact path="/partenaires" name="partenaires" element={ <Partenaire isAdmin={props.isAdmin} createNotification={props.createNotification}/> }/>
            <Route exact path="/mentions-legales" name="mentionsLegales" element={ <MentionsLegales/> }/>
            <Route exact path="/unsubscribeProno/:bearer" name="unsubscribePronoReminder" element={ <UnsubscribePronoReminder/> }/>
            <Route exact path="/monProno" name="monProno" element={ <MonProno updateUserData={props.authenticate} createNotification={props.createNotification} isAuth={props.isAuth} user={props.user} dimensions={props.dimensions.width}
                                                                            dropDownPronoType={props.dropDownPronoType} handleChangeActiveMenu={(type, action) => props.handleChangeActiveMenu(type, action)}/> }/>
            <Route exact path="/boutique-commande" name="boutique commande" element={ <BoutiqueAdmin createNotification={props.createNotification} isAuth={props.isAuth} isAdmin={props.isAdmin} isAdminShop={props.isAdminShop}/> }/>
            {!props.isAuth && <Route exact path="/forgetPassword" name="forgetPassword" element={ <ForgetPassword createNotification={props.createNotification}/> }/>}
            {!props.isAuth && <Route exact path="/changePassword/:token" name="changePassword" element={ <ChangePassword createNotification={props.createNotification}/> }/>}
            {!props.isAuth && <Route exact path="/activation/:userId/:token" name="activation" element={ <ActivateAccount/> }/>}
            {!props.isAuth && <Route exact path="/connexion" name="connexion" element={ <Authentification connect={props.authenticate} createNotification={props.createNotification}/> }/>}
            {!props.isAuth && <Route exact path="/inscription" name="inscription" element={ <Inscription createNotification={props.createNotification}/> }/>}
            {props.isAuth && <Route exact path="/mon-compte" name="monCompte" element={ <Profile updateUserData={props.authenticate} createNotification={props.createNotification} user={props.user} signout={props.signOut}/> }/>}
            {props.isAuth && props.isAdmin && <Route exact path="/categories" name="categories" element={ <Categorie createNotification={props.createNotification}/> }/>}
            {props.isAuth && props.isAdmin && <Route exact path="/equipes" name="équipes" element={ <Equipe createNotification={props.createNotification}/> }/>}
            {props.isAuth && props.isAdmin && <Route exact path="/evenements" name="évenements" element={ <Evenement createNotification={props.createNotification}/> }/>}
            {props.isAuth && props.isAdmin && <Route exact path="/saisons" name="saisons" element={ <Season createNotification={props.createNotification}/> }/>}
            <Route path="/*" element={ <Navigate to="/" replace/>}/>
        </Routes>
    );
}

export function ShopSiteRoutes(props) {
    const [lastPath, setLastPath] = useState("");

    function handleLastPath(data) {
        setLastPath(data);
    }

    if (props.isAuth && (props.user.Role === "TESTEUR" || props.user.Role === "ADMINSHOP" || props.isAdmin)) {
        return (
            <Routes>
                <Route exact path="/" name="boutique" element={ <Boutique dimensions={props.dimensions.width} isAdmin={props.isAdmin} createNotification={props.createNotification} handleCartIcon={props.handleCartIcon} handleLastPath={handleLastPath}/> }/>
                <Route exact path="/:category" name="boutique" element={ <Boutique dimensions={props.dimensions.width} isAdmin={props.isAdmin} createNotification={props.createNotification} handleCartIcon={props.handleCartIcon} handleLastPath={handleLastPath}/> }/>
                <Route exact path="/produit/:product" name="produit" element={ <Product dimensions={props.dimensions.width} isAdmin={props.isAdmin} createNotification={props.createNotification} handleCartIcon={props.handleCartIcon} lastPath={lastPath}/> }/>
                <Route exact path="/panier" name="panier" element={ <Cart dimensions={props.dimensions.width} isAdmin={props.isAdmin} createNotification={props.createNotification} handleCartIcon={props.handleCartIcon}/> }/>
                <Route exact path="/checkout" name="checkout" element={ <Checkout dimensions={props.dimensions.width}/> }/>
                <Route exact path="/orderSucces" name="orderSucces" element={ <OrderSucces/> }/>
                <Route exact path="/contacts" name="contacts" element={ <Contact createNotification={props.createNotification}/> }/>
                <Route exact path="/mentions-legales" name="mentionsLegales" element={ <MentionsLegales/> }/>
                <Route exact path="/boutique-commande" name="boutique commande" element={ <BoutiqueAdmin createNotification={props.createNotification} isAuth={props.isAuth} isAdmin={props.isAdmin} isAdminShop={props.isAdminShop}/> }/>
                {!props.isAuth && <Route exact path="/forgetPassword" name="forgetPassword" element={ <ForgetPassword createNotification={props.createNotification}/> }/>}
                {!props.isAuth && <Route exact path="/changePassword/:token" name="changePassword" element={ <ChangePassword createNotification={props.createNotification}/> }/>}
                {!props.isAuth && <Route exact path="/activation/:userId/:token" name="activation" element={ <ActivateAccount/> }/>}
                {!props.isAuth && <Route exact path="/connexion" name="connexion" element={ <Authentification connect={props.authenticate} createNotification={props.createNotification}/> }/>}
                {!props.isAuth && <Route exact path="/inscription" name="inscription" element={ <Inscription createNotification={props.createNotification}/> }/>}
                {props.isAuth && <Route exact path="/mon-compte" name="monCompte" element={ <Profile updateUserData={props.authenticate} createNotification={props.createNotification} user={props.user} signout={props.signOut}/> }/>}
                {props.isAuth && props.isAdmin && <Route exact path="/categories" name="categories" element={ <Categorie createNotification={props.createNotification}/> }/>}
                {props.isAuth && props.isAdmin && <Route exact path="/equipes" name="équipes" element={ <Equipe createNotification={props.createNotification}/> }/>}
                {props.isAuth && props.isAdmin && <Route exact path="/evenements" name="évenements" element={ <Evenement createNotification={props.createNotification}/> }/>}
                {props.isAuth && props.isAdmin && <Route exact path="/saisons" name="saisons" element={ <Season createNotification={props.createNotification}/> }/>}
                <Route path="/*" element={ <Navigate to="/" replace/>}/>
            </Routes>
        );
    } else {
        return ( 
        <Routes>
            {!props.isAuth && <Route exact path="/connexion" name="connexion" element={ <Authentification connect={props.authenticate} createNotification={props.createNotification}/> }/>}
        </Routes>
        );
    }
    
}