import React, {useEffect} from "react";

import ReactGA from "react-ga4";


export default function EspaceVideos(props) {
    const styles = {
        videoContainer: {
            height: "75vh"
        },
    };

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    return (
        <div style={styles.videoContainer}>
            <iframe id="ytplayer" type="text/html" width="100%" height="100%"
                    src="https://www.youtube.com/embed/?listType=playlist&list=PL16HUqDeVISnp-UvI9Yxuvx5jS7RAy0-7&playsinline=1&color=white"
                    frameBorder="0" allowFullScreen/>
        </div>
    )
}
