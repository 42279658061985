import React from 'react';
import { Grid, Typography, Link  } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';

import { useTheme } from '@emotion/react';
import moment from "moment";

export default function DropdownClub (props) {
    const theme = useTheme();
    const styles = {
        container: {
         backgroundColor: "black",
         padding: "1rem 0"
        },
        navItem: {
            cursor: "pointer",
            marginTop: "0.5rem",
            borderBottom: "solid 1px white",
            
            [theme.breakpoints.up('md')]: {
                fontSize: '0.9rem',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '1rem',
            },
        },
        linkItem: {
            textDecoration: "none!important",
            color: "white",
            marginLeft: "3rem!important",
            '&:hover': {
                color: theme.palette.colors.green + "!important",
        },
        }
    };

    return (
        <Grid container item justifyContent={"space-evenly"} xs={6} md={8} sx={styles.container} onMouseEnter={props.showDropdown} onMouseLeave={props.hideDropdown}>
            <Grid container item direction={"column"} sx={styles.column} xs={5}>
                <Grid item sx={styles.navItem}>
                {props.buildSubLink("Actualités", "/actu/" + new moment().format("YYYY").toString(), false)}
                </Grid>

                <Grid item sx={styles.navItem}>
                {props.buildSubLink("Histoire", "/histoire", false)}
                </Grid>
            </Grid>
            <Grid container item direction={"column"} xs={5}>

            </Grid>
        </Grid>
    )
}