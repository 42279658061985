import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, Typography  } from "@mui/material";
import { styled } from "@mui/system";


import "../../assets/Calendrier.scss"
import ApiClient from "../../service/ApiClient";
import { Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle } from "../../component/Popup/Popup";
import AddUpdateTeamPopup from "./AddUpdateEquipePopup";
import ReactGA from "react-ga4";

export default function Equipe(props) {
    const [search, setSearch] = useState("");
    const [teams, setTeams] = useState([]);
    const [actualPage, setActualPage] = useState(0);
    const [nbRow, setNbRow] = useState(200);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [actualTeam, setActualTeam] = useState(null);
    const [deleteError, setDeleteError] = useState(false);
    const [showPopup, setShowPopup] = useState("");

    const styles = {
        header: {
            backgroundColor: "black",
            color: "white",
            padding: "0.5rem"
        },
        rows: {
            padding: "0.5rem",
            borderBottom: "solid 1px #c7c7c7",
            height: "60px"
        },
        img: {
            height: "100%"
        },
        cssLabel: {
            "&.Mui-focused": {
                color: "#048f00"
            }
        },
    };

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        (async () => {
            try {
                await loadData();
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    async function loadData() {
        try {
            let result = await ApiClient.instance.getTeams();
            setTeams(result.data);
        } catch (error) {
            console.log(error);
        }
    }

    async function addTeam(data) {
        try {
            await ApiClient.instance.createTeam(data);
            props.createNotification("success", {title: "Succès", message: "Equipe créée", timeout: 3000});
            setShowPopup("");
            await loadData();
        } catch (e) {
            props.createNotification("error", {title: "Erreur", message: "Equipe non créée", timeout: 3000});
            console.log(e);
        }
    }

    async function updateTeam(data) {
        try {
            await ApiClient.instance.updateTeam(data);
            props.createNotification("success", {title: "Succès", message: "Equipe mis à jour", timeout: 3000});
            setShowPopup("");
            await loadData();
        } catch (e) {
            props.createNotification("error", {title: "Erreur", message: "Equipe non mis à jour", timeout: 3000});
            console.log(e);
        }
    }

    function buildRowTeam() {
        let rows = [];
        const re =  new RegExp("(" + search + ")\w*", "yi");
        for (let i = actualPage * nbRow; i < (nbRow * (actualPage + 1)) && i < teams.length; i++) {
            if (!teams[i].name.search(re)) {
                rows.push(<Grid item container direction={"row"} key={i}>
                    <Grid item xs><Box sx={styles.rows}><img style={styles.img} alt={"team-logo"}
                                                                    src={process.env.REACT_APP_API_URL + teams[i].photo}/></Box></Grid>
                    <Grid item xs={4} sm={6} md={8} lg={9}><Box sx={styles.rows}><Typography
                        variant={"body1"}>{teams[i].name}</Typography></Box></Grid>
                    <Grid item xs><Box sx={styles.rows}>
                        <button className={"btn btn-sm remove-article"}
                                onClick={() => {
                                    setActualTeam(teams[i]);
                                    setConfirmPopup(true);
                                }}><i className="material-icons">delete</i></button>
                        <button className={"btn btn-sm remove-article"}
                                onClick={() => {
                                    setActualTeam(teams[i]);
                                    setShowPopup("update");
                                }}><i className="material-icons">edit</i></button>
                    </Box>
                    </Grid>
                </Grid>)
            }
        }
        return (<Grid container direction={"column"}>{rows}</Grid>)
    }

    if (teams.length === 0)
        return (<div/>)
    return (<Box>
        <TextField sx={{marginBottom: "1rem"}} inputProps={{min: 0}} InputLabelProps={{classes: {root: styles.cssLabel}}}
                   type={"text"} label={"Rechercher"} onChange={(event) => {
                       setSearch(event.target.value);
                   }}
        />
        <Grid container direction={"column"}>
            <Grid item container direction={"row"} justifyContent={"center"} alignItems={"center"}>
                <Grid item xs>
                    <Box sx={styles.header}><Typography variant={"h6"}>Logo</Typography></Box>
                </Grid>
                <Grid item xs={4} sm={6} md={8} lg={9}>
                    <Box sx={styles.header}><Typography variant={"h6"}>Name</Typography></Box>
                </Grid>
                <Grid item xs><Box sx={styles.header}><Typography variant={"h6"}>Action</Typography></Box></Grid>
            </Grid>
            <Grid item>
                <Box style={{height: "400px", overflow: "auto"}}>
                    {buildRowTeam()}
                </Box>
            </Grid>
        </Grid>
        {showPopup === "create" && <AddUpdateTeamPopup close={() => setShowPopup("")} submit={addTeam} team={null}/>}
        {showPopup === "update" && <AddUpdateTeamPopup close={() => setShowPopup("")} submit={updateTeam} team={actualTeam}/>}
        {confirmPopup &&
        <Popup close={() => {
            setConfirmPopup(false);
            setDeleteError(false);
        }}>
            <PopupHeader>
                <PopupTitle>Supprimer une équipe</PopupTitle>
            </PopupHeader>
            <PopupBody>
                <p>Voulez-vous vraiment supprimer cette équipe ?</p>
                {deleteError && <small className={"text-danger col-12"}>Impossible de supprimer une équipe utilisé dans les calendriers ou les classements</small>}
            </PopupBody>
            <PopupFooter dismiss={"Non"} close={() => {
                setConfirmPopup(false);
                setDeleteError(false);
            }}>
                <button className={"btn btn-primary"} onClick={async () => {
                    try {
                        await ApiClient.instance.deleteTeam(actualTeam.value);
                        props.createNotification("success", {title: "Succès", message: "Equipe supprimée", timeout: 3000});
                        setConfirmPopup(false);
                        setDeleteError(false);
                        await loadData();
                    } catch (e) {
                        props.createNotification("error", {title: "Erreur", message: "Equipe non supprimée", timeout: 3000});
                        setDeleteError(true);
                        console.log(e);
                    }
                }}>Oui
                </button>
            </PopupFooter>
        </Popup>}
        <div className={"addArticle"}>
            <button className={"no-button"} onClick={() => {setShowPopup("create")}}>
                <i className="material-icons">add_circle</i>
            </button>
        </div>
    </Box>)
}
