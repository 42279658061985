import React from "react";
import {Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle} from "../../component/Popup/Popup";

export default class AddPartenairePopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            partner: {
                Title: '',
                picture: [],
                Site: ""
            }

        };
    }

    buildCard = () => {
        return (<div className={"col-4 pt-3"}>
            <div className={"card text-center"}>
                <img src={URL.createObjectURL(this.state.partner.picture)}
                     className={"img-thumbnail cursor-pointer "} alt="..."/>
            </div>
        </div>);

    };

    buildImagePreview = () => {
        return (<div className={"row"}>{this.buildCard()}</div>);
    };

    handleSubmit = () => {
        if (this.state.partner.Title === "")
            return;
        this.props.add(this.state.partner);
    };

    render() {
        return (
            <Popup size={"large"} close={this.props.close}>
                <PopupHeader>
                    <PopupTitle>Créer un nouveau partenaire</PopupTitle>
                </PopupHeader>

                <PopupBody>
                    <div className={"form-container"}>
                        <label className={"text-left"}>Nom du partenaire<i
                            className={"text-danger"}> *</i></label>
                        <input className="form-control" type={"text"} placeholder={""}
                               value={this.state.partner.Title} onChange={(event) => {
                            let partner = this.state.partner;
                            partner.Title = event.target.value;
                            this.setState({partner: partner});
                        }}/>
                        {!this.state.partner.Title &&
                        <small className={"text-danger col-12"}>Ce champ est obligatoire</small>}
                    </div>

                    <div className={"form-container"}>
                        <label className={"text-left"}>Site du partenaire</label>
                        <input className="form-control" type={"text"} placeholder={""}
                               value={this.state.partner.Site} onChange={(event) => {
                            let partner = this.state.partner;
                            partner.Site = event.target.value;
                            this.setState({partner: partner});
                        }}/>
                    </div>

                    <div className={"w-100 text-center row horizontal-top-line-grey-dark"}>
                        <h6 className={"w-100"}>Photo</h6>
                        <input accept=".png, .jpg" type={"file"} className={"input-image col text-center"}
                               onChange={(event) => {
                                   var partner = this.state.partner;
                                   partner.picture = event.target.files[0];
                                   this.setState({partner: partner});
                               }}/>
                    </div>

                    {this.state.partner.picture.length !== 0 &&
                    this.buildImagePreview()
                    }
                </PopupBody>

                <PopupFooter dismiss={"Annuler"} close={this.props.close}>
                    <input className={"btn btn-primary"} type={"submit"} value={"Créer"}
                           onClick={() => this.handleSubmit()}/>
                </PopupFooter>
            </Popup>
        );
    }
}