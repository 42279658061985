import App from './app/App';    
import React, {useEffect} from "react";
import {createRoot} from "react-dom/client";

const rootElement = document.getElementById("root");

function AppWithCallbackAfterRender() {
    useEffect(() => {
        console.log('rendered');
    });

    return <App/>
}

const root = createRoot(rootElement);
root.render(<AppWithCallbackAfterRender />);
