import { NavLink, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useTheme, Box, Checkbox, FormControlLabel, Grid, LinearProgress, TextField, Tooltip, Typography, Button, Popover } from "@mui/material";
import moment from 'moment';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';

import ApiClient from "../../service/ApiClient";
import Spinner from "../../component/Spinner/Spinner";
import "../../assets/Monprono.scss";

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props}/>
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export default function PronoMonProno(props) {
    const theme = useTheme();
    const location = useLocation();
    const [prono, setProno] = useState(null);
    const [nextMatch, setNextMatch] = useState(null);
    const [pastMatch, setPastMatch] = useState(null);
    const [pastProno, setPastProno] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [isPronoRemind, setIsPronoRemind] = useState(null);
    const [isUserAcceptRules, setIsUserAcceptRules] = useState(false);
    const [inventoryBonus, setInventoryBonus] = useState([]);
    const [openBonus, setOpenBonus] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [actualMatchIndex, setActualMatchIndex] = useState(null)

    const styles = {
        unavailableBonus: {
            width: "100%", 
            height: "100%", 
            backgroundColor: "grey", 
            position: "absolute!important", 
            top: 0, 
            opacity: 0.5
        },
        availableBonus: {
            width: "100%", 
            height: "100%", 
            backgroundColor: "black", 
            position: "absolute!important", 
            top: 0, 
            opacity: 0.1,
            cursor: "pointer"
        }
    };

    useEffect(() => {
        if (props.isAuth) {
            (async () => {
                try {
                    setIsLoading(true);
                    await loadData();
                    setIsLoading(false);
                } catch (error) {
                    console.log(error);
                }
            })();
        }
    }, [props.actualSeason, props.isAuth]);
    
    async function loadData() {
        try {
            let copy = [];
            let result = await ApiClient.instance.getPronoReminderStatus();
            setIsPronoRemind(result.data.status);
            result = await ApiClient.instance.getPastMatch(props.actualSeason);
            setPastMatch(result.data);
            result = await ApiClient.instance.getAllProno(props.user.Id, props.actualSeason);
            setNextMatch(result.data.data.nextMatch);
            setPastProno(result.data.data.lastProno);
            for (let i = 0; i < result.data.data.nextMatch.length; i++) {
                copy.push({
                    team1_prono: result.data.data.nextMatch[i]?.team1_prono,
                    team2_prono: result.data.data.nextMatch[i]?.team2_prono,
                    usedBonus: result.data.data.nextMatch[i]?.usedBonus
                });
            }
            setProno(copy);
            result = await ApiClient.instance.getPronoBonusInventory(props.user.Id);
            setInventoryBonus(result.data)
        } catch (e) {

        }
    }

    function handleBonusSelected(match, matchIndex, inventoryBonus, inventoryBonusIndex) {
        let tmp = match;
        if (inventoryBonusIndex === -1) {
            if (tmp[matchIndex].usedBonus !== null) {
                let tmpBonusInventory = inventoryBonus;
                for (let i = 0; i < inventoryBonus.length; i++) {
                    if (inventoryBonus[i].bonus_id === tmp[matchIndex].usedBonus) {
                        tmpBonusInventory[i].quantity += 1;
                        setInventoryBonus([...tmpBonusInventory]);
                    }
                }
            }
            tmp[matchIndex].usedBonus =  null;
            tmp[matchIndex].bonusPicture =  null;
            setNextMatch([...tmp]);
            setAnchorEl(null); 
            setOpenBonus(false)
        } else {
            if (inventoryBonus[inventoryBonusIndex].quantity !== 0) {
                if (tmp[matchIndex].usedBonus !== null) {
                    let tmpBonusInventory = inventoryBonus;
                    for (let i = 0; i < inventoryBonus.length; i++) {
                        if (inventoryBonus[i].bonus_id === tmp[matchIndex].usedBonus) {
                            tmpBonusInventory[i].quantity += 1;
                            setInventoryBonus([...tmpBonusInventory]);
                        }
                    }
                }
                tmp[matchIndex].usedBonus =  inventoryBonus[inventoryBonusIndex].bonus_id;
                tmp[matchIndex].bonusPicture =  inventoryBonus[inventoryBonusIndex].picture;
                setNextMatch([...tmp]);
    
                tmp = inventoryBonus;
                tmp[inventoryBonusIndex].quantity -=  1;
            
                setInventoryBonus([...tmp])
                setAnchorEl(null); 
                setOpenBonus(false)
            }
        }
    }

    function isPronoChanged() {
        for (let i = 0; i < nextMatch?.length; i++) {
            if (nextMatch[i]?.team1_prono !== prono[i]?.team1_prono || nextMatch[i]?.team2_prono !== prono[i]?.team2_prono) {
                return true;
            }
            if (nextMatch[i]?.usedBonus !== prono[i]?.usedBonus) {
                return true
            }
        }
        return false;
    }

    async function handleSaveProno() {
        try {
            let isPronoValid = false;
            setIsSaveLoading(true);
            for (let i = 0; i < nextMatch.length; i++) {
                if (nextMatch[i].team1_prono !== null && nextMatch[i].team2_prono !== null) {
                    if (prono[i]?.team1_prono === null && prono[i]?.team2_prono === null) {
                        await ApiClient.instance.createProno({
                            MatchId_seniorsA: nextMatch[i].category === "seniorsA" ? nextMatch[i].matchId : null,
                            MatchId_seniorsB: nextMatch[i].category === "seniorsB" ? nextMatch[i].matchId : null,
                            MatchId_seniorsC: nextMatch[i].category === "seniorsC" ? nextMatch[i].matchId : null,
                            Team1_prono: nextMatch[i].team1_prono,
                            Team2_prono:nextMatch[i].team2_prono,
                            usedBonus: nextMatch[i].usedBonus
                        })
                    } else {
                        await ApiClient.instance.updateProno(nextMatch[i].pronoId,{
                            Team1_prono: nextMatch[i].team1_prono,
                            Team2_prono:nextMatch[i].team2_prono,
                            usedBonus: nextMatch[i].usedBonus
                        })
                    }
                    isPronoValid = true;
                }
            }
            setIsSaveLoading(false);
            if (isPronoValid) {
                props.createNotification("success", {title: "Succès", message: "Pronostics sauvegardés", timeout: 3000});
                await loadData();
            } else {
                props.createNotification("error", {title: "Erreur", message: "Pronostics non sauvegardés", timeout: 3000});
            }
        } catch (e) {
            setIsSaveLoading(false);
            console.log(e);
            props.createNotification("error", {title: "Erreur", message: "Pronostics non sauvegardés", timeout: 3000});
        }
    }

    function buildNextMatchContainer() {
        let listMatchBuild = [];

        if (nextMatch !== null) {
            for (let i = 0; i < nextMatch.length; i++) {
                listMatchBuild.push(buildCalendar(nextMatch[i], moment(nextMatch[i].date), i, true));
            }
        }

        return (
            <Grid container item direction={"column"}>
                <Grid className={"nextMatch-title"} container item justifyContent={"space-between"} alignItems={"flex-end"}>
                    <Grid item>
                        <h2 className={"nextMatch-container"}>
                            Prochains
                            <span> matchs</span>
                        </h2>
                    </Grid>

                    <Grid container item justifyContent={"flex-end"} xs={2} alignItems={"center"}>
                        <Grid item style={{marginRight: "1rem"}}>
                            <Tooltip title={isPronoRemind === 0 ? "Recevoir un rappel par mail" : "Ne plus recevoir de rappel par mail"}>
                                <Box style={{color: theme.palette.colors.green, cursor: "pointer", "&:hover": {color: theme.palette.colors.green}}} onClick={async () => {
                                    try {
                                        await ApiClient.instance.updatePronoReminder(isPronoRemind === 0 ? "subscribe" : "unsubscribe");
                                        await loadData();
                                        props.createNotification("success", {title: "Succès", message: isPronoRemind === 0 ? "Notification par mail activé" : "Notification par mail désactivé", timeout: 3000});
                                    } catch (e) {
                                        console.log(e);
                                        props.createNotification("error", {title: "Erreur", message: "Rappel non mis à jour", timeout: 3000});
                                    }
                                }}>
                                    {isPronoRemind === 0 ? <NotificationsOffIcon/> : <NotificationsActiveIcon/>}
                                </Box>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Button sx={{backgroundColor: theme.palette.colors.green, margin: "0.5rem 0", "&:hover": {backgroundColor: theme.palette.colors.green}}} variant="contained"
                                    disabled={!isPronoChanged() || isSaveLoading} onClick={handleSaveProno}>Valider</Button>
                        </Grid>
                    </Grid>
                </Grid>
                { nextMatch !== null && nextMatch?.length !== 0  && listMatchBuild}
                { (nextMatch === null || nextMatch?.length === 0) && <Grid item><Typography variant={"h5"}>Pas de prochain match disponible</Typography></Grid>}
            </Grid>
        )
    }

    function buildPastMatchContainer() {
        let listMatchBuild = [];
        let newPastProno = pastProno;
        let match = {};

        if (pastMatch !== null) {
            for (let i = 0; i < pastMatch.length; i++) {
                let count = 0
                match = null;
                for (count; count < newPastProno?.length; count++) {
                    if (newPastProno[count].matchId === pastMatch[i].id && newPastProno[count].category === pastMatch[i].category) {
                        match = newPastProno[count];
                        break;
                    }
                }
                if (match === null) {
                    match = pastMatch[i];
                }
                if (match.competition !== "Amical") {
                    listMatchBuild.push(buildCalendar(match, moment(match.date), i, false));
                }
            }
        }

        return (<Grid container item direction={"column"}>
            <Grid className={"nextMatch-title"} container item justifyContent={"space-between"} alignItems={"center"}>
                <Grid item>
                    <h2 className={"nextMatch-container"}>
                        Matchs
                        <span> passés</span>
                    </h2>
                </Grid>
            </Grid>
            { pastMatch !== null && pastMatch?.length !== 0 && listMatchBuild.length !== 0 &&  listMatchBuild}
            { (pastMatch === null || pastMatch?.length === 0 || listMatchBuild.length === 0) && <Grid item><Typography variant={"h5"}>Pas de match disputé</Typography></Grid>}
        </Grid>);
    }

    function setColorResultProno(pronoResult) {
        switch (pronoResult) {
            case 1:
                return "#ffc107";
            case 0:
                return "#dc3545";
            default:
                return "#048f00";
        }
    }

    function buildBonusList(match) {
        let row = [];

        row.push(<Grid item xs={2.5}>
            <Tooltip title={"Aucun bonus"}>
                <Box sx={{width:"100%", height: "100%", position: "relative"}} onClick={() => {handleBonusSelected(match, actualMatchIndex, inventoryBonus, -1)}}>
                    <img style={{width:"100%", height: "100%", position: "absolute!important", top: 0}} src={process.env.REACT_APP_API_URL + "/icon_bonus/aucun.png"} alt="bonus"/>
                    <Box sx={styles.availableBonus}/>
                </Box>
            </Tooltip>
        </Grid>)

        for (let i = 0; i < inventoryBonus.length; i++) {
            row.push(<Grid item xs={2.5}>
                <Tooltip title={inventoryBonus[i].name + " : " + inventoryBonus[i].description}>
                    <Box sx={{width:"100%", height: "100%", position: "relative"}} onClick={() => {handleBonusSelected(match, actualMatchIndex, inventoryBonus, i)}}>
                        <img style={{width:"100%", height: "100%", position: "absolute!important", top: 0}} src={process.env.REACT_APP_API_URL + "/icon_bonus/" + inventoryBonus[i].picture} alt="bonus"/>
                        <Box sx={inventoryBonus[i].quantity === 0 ? styles.unavailableBonus : styles.availableBonus}/>
                        <Typography sx={{position: "absolute", bottom: "0", right: "0", backgroundColor: "white", border: "1px solid black", lineHeight: "15px", width: "15px", textAlign: "center", fontSize: "12px"}}>x{inventoryBonus[i].quantity}</Typography>
                    </Box>
                </Tooltip>
            </Grid>)
        }
        
        return row;
    }

    function buildCalendar(match, date, index, isNextMatch) {
        let encodedUriTeam1 = encodeURI(process.env.REACT_APP_API_URL + match.team1.logo).replaceAll("'", "\\'");
        let encodedUriTeam2 = encodeURI(process.env.REACT_APP_API_URL + match.team2.logo).replaceAll("'", "\\'");

        if (window.innerWidth > 960) {
            return (
                <Grid key={match.matchId} container item style={{overflow: "hidden", margin: "10px 0", border: "solid 1px #c7c7c7"}} alignItems={"center"}>
                    <Grid item>
                        <Box className={"left-part"}/>
                    </Grid>
                    <Grid container direction={"column"} md={3} item justifyContent={"center"}>
                        <Grid item>
                            <Typography
                                variant={"subtitle1"}><b>{date.format("D ") + date.format("MMM").charAt(0).toUpperCase() + date.format("MMM").slice(1) + date.format(" YYYY")}</b> - {date.format("H")}h{date.format("mm")}
                            </Typography>
                        </Grid>
                        <Grid item><Typography variant={"subtitle1"}>{match.competition}</Typography></Grid>
                    </Grid>
                    <Grid container item xs style={{marginRight: "1rem"}} alignItems={"center"}>
                        <Grid container item xs justifyContent={"flex-end"} style={{marginRight: "10px"}}>
                            <Grid item>
                                <Typography variant={"subtitle1"}>{match.team1.name}</Typography>
                            </Grid>
                        </Grid>
                        <Grid style={{
                            background: "url(" + encodedUriTeam1 + ")", backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat', height: "100px"}} item xs={2}/>

                    </Grid>
                    {isNextMatch &&
                    <Grid container item direction={"column"} xs={2}>
                        <Grid container item>
                            <Grid className={"prono-input"} item xs>
                                <TextField
                                    disabled={(date.diff(new Date(), 'hour') < 1)}
                                    id="standard-error-helper-text"
                                    variant="filled"
                                    value={nextMatch[index].team1_prono != null ? nextMatch[index].team1_prono : ""}
                                    inputProps={{min: 0, style: {textAlign: "center", fontSize: "1.5rem", paddingTop: "8px"}}}
                                    onChange={event => {
                                        if (/^[0-9]*$/.test(event.target.value)) {
                                            let tmp = nextMatch;

                                            tmp[index].team1_prono =  event.target.value;
                                            if (tmp[index].team1_prono.length < 1) {
                                                tmp[index].team1_prono = 0;
                                            } else if (tmp[index].team1_prono[0] === '0' && tmp[index].team1_prono.length !== 1) {
                                                tmp[index].team1_prono = tmp[index].team1_prono.slice(1);
                                            }
                                            tmp[index].team1_prono = parseInt(tmp[index].team1_prono);
                                            setNextMatch([...tmp]);
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item style={{fontWeight: "bold", fontSize: "1.5rem"}}>-</Grid>

                            <Grid className={"prono-input"} item xs>
                                <TextField
                                    disabled={(date.diff(new Date(), 'hour') < 1)}
                                    id="standard-error-helper-text"
                                    variant="filled"
                                    value={nextMatch[index].team2_prono != null ? nextMatch[index].team2_prono : ""}
                                    inputProps={{min: 0, style: {textAlign: "center", fontSize: "1.5rem", paddingTop: "8px"}}}
                                    onChange={event => {
                                        if (/^[0-9]*$/.test(event.target.value)) {
                                            let tmp = nextMatch;

                                            tmp[index].team2_prono =  event.target.value;
                                            if (tmp[index].team2_prono.length < 1) {
                                                tmp[index].team2_prono = 0;
                                            } else if (tmp[index].team2_prono[0] === '0' && tmp[index].team2_prono.length !== 1) {
                                                tmp[index].team2_prono = tmp[index].team2_prono.slice(1);
                                            }
                                            tmp[index].team2_prono = parseInt(tmp[index].team2_prono);
                                            setNextMatch([...tmp]);
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item style={{marginTop: "1rem"}}>
                            <Grid container item direction={"column"} xs={4}>
                                <Grid item xs style={{textAlign: "center"}}>
                                    1
                                </Grid>
                                <Grid item xs>
                                    <LinearProgressWithLabel value={match.stats.team1} />
                                </Grid>
                            </Grid>
                            <Grid container item direction={"column"} xs={4}>
                                <Grid item xs style={{textAlign: "center"}}>
                                    N
                                </Grid>
                                <Grid item xs>
                                    <LinearProgressWithLabel value={match.stats.egalite} />
                                </Grid>
                            </Grid>
                            <Grid container item direction={"column"} xs={4}>
                                <Grid item xs style={{textAlign: "center"}}>
                                    2
                                </Grid>
                                <Grid item xs>
                                    <LinearProgressWithLabel value={match.stats.team2} />
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>}

                    {!isNextMatch &&
                    <Grid container item direction={"column"} xs={1} alignItems={"center"}>
                        <Grid item>Resultat</Grid>
                        <Grid item xs={2}>
                            <Typography variant={"h6"} style={{textAlign: "center"}}>{match.team1.score === null ? " " : match.team1.score} - {match.team2.score === null ? " " : match.team2.score}</Typography>
                            {match.team1.penalty !== null && match.team2.penalty !== null &&
                            <Typography variant={"h5"} style={{textAlign: "center"}}>{match.team1.penalty} tab {match.team2.penalty}</Typography>
                            }
                        </Grid>

                        <Grid item>Pronostic</Grid>
                        <Grid item xs={2}>
                            <Typography variant={"h6"} style={{textAlign: "center", color:  setColorResultProno(match.pronoResult)}}>{match.team1_prono} - {match.team2_prono}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant={"h7"} style={{textAlign: "center"}}>{"+" + (match.pronoResult !== undefined ? match.pronoResult : "0") + (match.pronoResult > 1 ? "pts" : "pt")}</Typography>
                        </Grid>

                    </Grid>}

                    <Grid container item xs style={{marginLeft: "1rem"}} alignItems={"center"}>
                        <Grid style={{
                            background: "url(" + encodedUriTeam2 + ")", backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat', height: "100px"}} item xs={2}/>
                        <Grid container item xs justifyContent={"flex-start"} style={{marginLeft: "10px"}}>
                            <Grid item>
                                <Typography variant={"subtitle1"}>{match.team2.name}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    {isNextMatch &&
                    <Grid item sx={{marginRight: "1rem"}}>
                         { nextMatch[index].usedBonus === null &&
                            <Button sx={{backgroundColor: theme.palette.colors.green, color: "white", margin: "0.5rem 0", "&:hover": {backgroundColor: theme.palette.colors.green}}} onClick={(event) => {setAnchorEl(event.currentTarget); setOpenBonus(true); setActualMatchIndex(index)}}>Bonus</Button>
                        }
                        { nextMatch[index].usedBonus !== null &&
                            <Tooltip title={nextMatch[index].bonusName}>
                                <img style={{width:"50px", height: "50px", position: "absolute!important", top: 0, cursor: "pointer"}} src={process.env.REACT_APP_API_URL + "/icon_bonus/" + nextMatch[index].bonusPicture} alt="bonus" onClick={(event) => {setAnchorEl(event.currentTarget); setOpenBonus(true); setActualMatchIndex(index)}}/>
                            </Tooltip>
                        }
                         <Popover
                            open={openBonus}
                            anchorEl={anchorEl}
                            onClose={() => {setAnchorEl(null); setOpenBonus(false)}}
                            anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            >
                                <Grid container sx={{width: "200px"}} justifyContent={"space-between"}>
                                    {buildBonusList(nextMatch)}
                                </Grid>
                            </Popover>
                    </Grid>
                    }

                    {!isNextMatch &&
                    <Grid item sx={{marginRight: "1rem"}} xs={0.5}>
                        { match.usedBonus !== null && match.usedBonus !== undefined &&
                            <Tooltip title={match.bonusName}>
                                <img style={{width:"50px", height: "50px", position: "absolute!important", top: 0}} src={process.env.REACT_APP_API_URL + "/icon_bonus/" + match.bonusPicture} alt="bonus"/>
                            </Tooltip>
                        }
                    </Grid>
                    }
                </Grid>
            );
        } else {
            return (
                <Grid key={match.matchId} container item style={{overflow: "hidden", margin: "10px 0", border: "solid 1px #c7c7c7"}} alignItems={"center"}>
                    <Grid item>
                        <Box className={"left-part"} style={{height: "250px"}}/>
                    </Grid>
                    <Grid item container direction={"column"} alignItems={"center"} xs>
                        <Grid container item justifyContent={"center"} xs>
                            <Grid container direction={"column"} item alignItems={"center"}>
                                <Grid item>
                                    <Typography variant={"subtitle1"}>
                                        <b>{date.format("D ") + date.format("MMM").charAt(0).toUpperCase() + date.format("MMM").slice(1) + date.format(" YYYY")}</b> - {date.format("H")}h{date.format("mm")}
                                    </Typography>
                                </Grid>
                                <Grid item><Typography variant={"subtitle1"}>{match.Competition}</Typography></Grid>
                            </Grid>
                        </Grid>
                        <Grid container item justifyContent={"center"} xs alignItems={"center"}>
                            <Grid container item direction={"column"} alignItems={"center"} xs>
                                <Grid container item justifyContent={"center"}>
                                    <Grid style={{background: "url(" + encodedUriTeam1 + ")", backgroundPosition: 'center',
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat', height: "100px"}} item xs={4}/>
                                </Grid>
                                <Grid item><Typography variant={"subtitle1"}>{match.team1.name}</Typography></Grid>
                            </Grid>
                            {isNextMatch &&
                            <Grid container item direction={"column"} xs={5}>
                                <Grid container item>
                                    <Grid className={"prono-input"} item xs>
                                        <TextField
                                            disabled={(date.diff(new Date(), 'hour') < 1)}
                                            id="standard-error-helper-text"
                                            variant="filled"
                                            value={nextMatch[index].team1_prono != null ? nextMatch[index].team1_prono : ""}
                                            inputProps={{min: 0, style: {textAlign: "center", fontSize: "1.5rem", paddingTop: "8px"}}}
                                            onChange={event => {
                                                if (/^[0-9]*$/.test(event.target.value)) {
                                                    let tmp = nextMatch;

                                                    tmp[index].team1_prono =  event.target.value;
                                                    if (tmp[index].team1_prono.length < 1) {
                                                        tmp[index].team1_prono = 0;
                                                    } else if (tmp[index].team1_prono[0] === '0' && tmp[index].team1_prono.length !== 1) {
                                                        tmp[index].team1_prono = tmp[index].team1_prono.slice(1);
                                                    }
                                                    tmp[index].team1_prono = parseInt(tmp[index].team1_prono);
                                                    setNextMatch([...tmp]);
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid item style={{fontWeight: "bold", fontSize: "1.5rem"}}>-</Grid>

                                    <Grid className={"prono-input"} item xs>
                                        <TextField
                                            disabled={(date.diff(new Date(), 'hour') < 1)}
                                            id="standard-error-helper-text"
                                            variant="filled"
                                            value={nextMatch[index].team2_prono != null ? nextMatch[index].team2_prono : ""}
                                            inputProps={{min: 0, style: {textAlign: "center", fontSize: "1.5rem", paddingTop: "8px"}}}
                                            onChange={event => {
                                                if (/^[0-9]*$/.test(event.target.value)) {
                                                    let tmp = nextMatch;

                                                    tmp[index].team2_prono =  event.target.value;
                                                    if (tmp[index].team2_prono.length < 1) {
                                                        tmp[index].team2_prono = 0;
                                                    } else if (tmp[index].team2_prono[0] === '0' && tmp[index].team2_prono.length !== 1) {
                                                        tmp[index].team2_prono = tmp[index].team2_prono.slice(1);
                                                    }
                                                    tmp[index].team2_prono = parseInt(tmp[index].team2_prono);
                                                    setNextMatch([...tmp]);
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item style={{marginTop: "1rem"}}>
                                    <Grid container item direction={"column"} xs={4}>
                                        <Grid item xs style={{textAlign: "center"}}>
                                            1
                                        </Grid>
                                        <Grid item xs>
                                            <LinearProgressWithLabel value={match.stats.team1} />
                                        </Grid>
                                    </Grid>
                                    <Grid container item direction={"column"} xs={4}>
                                        <Grid item xs style={{textAlign: "center"}}>
                                            N
                                        </Grid>
                                        <Grid item xs>
                                            <LinearProgressWithLabel value={match.stats.egalite} />
                                        </Grid>
                                    </Grid>
                                    <Grid container item direction={"column"} xs={4}>
                                        <Grid item xs style={{textAlign: "center"}}>
                                            2
                                        </Grid>
                                        <Grid item xs>
                                            <LinearProgressWithLabel value={match.stats.team2} />
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>}

                            {!isNextMatch &&
                            <Grid container item direction={"column"} xs={2} alignItems={"center"}>
                                <Grid item>Resultat</Grid>
                                <Grid item xs={2}>
                                    <Typography variant={"h6"} style={{textAlign: "center"}}>{match.team1.score === null ? " " : match.team1.score} - {match.team2.score === null ? " " : match.team2.score}</Typography>
                                    {match.team1.penalty !== null && match.team2.penalty !== null &&
                                    <Typography variant={"h5"} style={{textAlign: "center"}}>{match.team1.penalty} tab {match.team2.penalty}</Typography>
                                    }
                                </Grid>

                                <Grid item>Pronostic</Grid>
                                <Grid item xs={2}>
                                    <Typography variant={"h6"} style={{textAlign: "center", color:  setColorResultProno(match.pronoResult)}}>{match.team1_prono} - {match.team2_prono}</Typography>
                                </Grid>

                            </Grid>}
                            <Grid container item direction={"column"} xs alignItems={"center"} justifyContent={"center"}>
                                <Grid container item justifyContent={"center"}><Grid style={{
                                    background: "url(" + encodedUriTeam2 + ")", backgroundPosition: 'center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat', height: "100px"}} item xs={4}/></Grid>
                                <Grid item><Typography variant={"subtitle1"}>{match.team2.name}</Typography></Grid>
                            </Grid>
                        </Grid>

                        { isNextMatch &&
                            <Grid item sx={{marginRight: "1rem"}}>
                                { nextMatch[index].usedBonus === null &&
                                    <Button sx={{backgroundColor: theme.palette.colors.green, color: "white", margin: "0.5rem 0", "&:hover": {backgroundColor: theme.palette.colors.green}}} onClick={(event) => {setAnchorEl(event.currentTarget); setOpenBonus(true); setActualMatchIndex(index)}}>Bonus</Button>
                                }
                                { nextMatch[index].usedBonus !== null &&
                                    <Tooltip title={nextMatch[index].bonusName}>
                                        <img style={{width:"50px", height: "50px", position: "absolute!important", top: 0, cursor: "pointer"}} src={process.env.REACT_APP_API_URL + "/icon_bonus/" + nextMatch[index].bonusPicture} alt="bonus" onClick={(event) => {setAnchorEl(event.currentTarget); setOpenBonus(true); setActualMatchIndex(index)}}/>
                                    </Tooltip>
                                }
                                <Popover
                                    open={openBonus}
                                    anchorEl={anchorEl}
                                    onClose={() => {setAnchorEl(null); setOpenBonus(false)}}
                                    anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}>
                                        <Grid container sx={{width: "200px"}} justifyContent={"space-between"}>
                                            {buildBonusList(nextMatch)}
                                        </Grid>
                                </Popover>
                            </Grid>
                        }

                        {!isNextMatch && 
                            <Grid item xs={2}>
                                <Typography variant={"h7"} style={{textAlign: "center"}}>{"+" + (match.pronoResult !== undefined ? match.pronoResult : "0") + (match.pronoResult > 1 ? "pts" : "pt")}</Typography>
                            </Grid>
                        }

                        {!isNextMatch &&
                            <Grid item xs={2}>
                                { match.usedBonus !== null &&  match.usedBonus !== undefined &&
                                    <Tooltip title={match.bonusName}>
                                        <img style={{width:"50px", height: "50px", position: "absolute!important", top: 0}} src={process.env.REACT_APP_API_URL + "/icon_bonus/" + match.bonusPicture} alt="bonus"/>
                                    </Tooltip>
                                }
                            </Grid>
                        }
                    </Grid>
                </Grid>
            )
        }
    }

    if (!props.isAuth) {
        return (<Grid className={"prono-container-login"} container item justifyContent={"center"} alignItems={"center"}>
            <Grid  container direction={"column"} justifyContent={"center"} alignItems={"center"} item>
                <Grid item>
                    <Typography variant={"h5"}>Vous devez être connecté pour accéder à vos pronostics</Typography>
                </Grid>
                <Grid className={"auth-button"} item>
                    <NavLink to={"/connexion"} state={{prevUrl: location.pathname}}>
                        <button className="btn btn-primary"><Typography variant={"h5"}>Connexion</Typography></button>
                    </NavLink>
                </Grid>
            </Grid>
        </Grid>);
    } else if (!props.user.pronoRules) {
        const label = (
            <span>J'ai lu et j'accepte le&nbsp;
                <span className={"link-accept-rule"} onClick={() => props.goToRules()}>réglement du jeu de pronostic</span>
            </span>);
        return (<Grid className={"prono-container-login"} container item justifyContent={"center"} alignItems={"center"}>
            <Grid  container direction={"column"} justifyContent={"center"} alignItems={"center"} item>
                <Grid item>
                    <FormControlLabel checked={isUserAcceptRules} onChange={() => setIsUserAcceptRules(!isUserAcceptRules)} control={<Checkbox sx={{ color: theme.palette.colors.green, '&.Mui-checked': {color: theme.palette.colors.green} }}/>} label={label} />
                </Grid>
                <Grid className={"auth-button"} item>
                    <Button variant="contained" sx={{ backgroundColor: theme.palette.colors.green, '&:hover': {backgroundColor: theme.palette.colors.green}}} disabled={!isUserAcceptRules} onClick={async () => {
                        if (isUserAcceptRules) {
                            let result = await ApiClient.instance.userAcceptPronoRules();
                            props.updateUserData(result.data.token, result.data.user);
                        }
                    }}>
                        <Typography variant={"h5"}>Valider</Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>)
    } else {
        if (isLoading) {
            return (<Grid container item justifyContent={"center"} alignItems={"center"}>
                <Grid item>
                    <Spinner/>
                </Grid>
            </Grid>);
        } else {
            return (<Grid container item justifyContent={"center"}>
                <Grid container item justifyContent={"flex-start"}>

                </Grid>
                {buildNextMatchContainer()}
                {buildPastMatchContainer()}
            </Grid>);
        }

    }

}
