import React, { useEffect, useState } from "react";
import { DayPicker } from "react-day-picker";
import fr from  'date-fns/locale/fr';
import 'react-day-picker/dist/style.css';
import Select from 'react-select'

import TimeInput from "../../component/Input/Time/TimeInput";
import {Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle} from "../../component/Popup/Popup";
import "../../assets/reactSelectStyle.scss"


export default function AddUpdateMatchPopup(props) {
    const [competition, setCompetition] = useState(props.match !== null ? props.match.competition : '');
    const [amical, setAmical] = useState((props.match !== null ? props.match.competition === "Amical" : false))
    const [date, setDate] = useState(props.match !== null ? new Date(props.match.date) : new Date());
    const [hour, setHour] = useState(props.match !== null ? new Date(props.match.date).getHours() : 15);
    const [minute, setMinute] = useState(props.match !== null ? new Date(props.match.date).getMinutes() : 30);
    const [team1, setTeam1] = useState(props.match !== null ? {value: props.match.team1.id, label: props.match.team1.name } : null);
    const [team2, setTeam2] = useState(props.match !== null ?  {value: props.match.team2.id, label: props.match.team2.name } : null);
    const [saison, setSaison] = useState(null);

    useEffect(() => {
        if (props.match !== null) {
            let result = props.seasonData.find(item => item.value === props.match.season)
            if (result !== null) {
                setSaison({
                    value: result.value,
                    label: result.label
                });
            }
        }
    }, []);

    function handleSubmit() {
        let match;
        if (props.match !== null) {
            match = {
                id: props.match.id,
                amical: amical,
                competition: competition,
                team1: {
                    id: team1.value,
                    score: props.match.team1.score,
                    penalty: props.match.team1.penalty
                },
                team2: {
                    id: team2.value,
                    score: props.match.team2.score,
                    penalty: props.match.team2.penalty
                },
                season: saison.value,
                date: date.setHours(hour, minute)
            }
        } else {
            match = {
                competition: competition,
                amical: amical,
                team1: {
                    id: team1.value,
                    score: null,
                    penalty: null
                },
                team2: {
                    id: team2.value,
                    score: null,
                    penalty: null
                },
                season: saison.value,
                date: date.setHours(hour, minute)
            }
        }
        if (competition === "" && !amical)
            return;
        if (team1 === null || team2 === null || saison === null)
            return;
        props.submit(match);
    }

    function handleTime(type, data) {
        if (type === "hour"){
            setHour(data);
        }
        else {
            setMinute(data);
        }
    }

    function renderTeam(args, option, snapshot, className) {
        const imgStyle = {
            verticalAlign: 'middle',
            marginRight: 10,
        };

        return (
            <button {...args} className={className} type="button">
                <span><img alt="" style={imgStyle} width="32"  src={process.env.REACT_APP_API_URL + option.photo} /><span>{option.name}</span></span>
            </button>
        );
    }

    return (
        <Popup size={"large"} close={props.close}>
            <PopupHeader>
                <PopupTitle>{props.match !== null ? "Modifier un match" : "Créer un nouveau match"}</PopupTitle>
            </PopupHeader>

            <PopupBody>
            <div className={"changeDate-container row mt-3"}>
                    <div className={"col-1"}>
                        <input type={"checkbox"} value={"amical"} checked={amical} onChange={() => {
                            setAmical(!amical);
                            setCompetition("");
                            }}/>
                    </div>
                    <p>Match amical</p>
                </div>
                { !amical &&
                <div className={"form-container"}>
                    <label className={"text-left"}>Competition<i
                        className={"text-danger"}> *</i></label>
                    <input className="form-control" type={"text"} placeholder={""}
                           value={competition} onChange={(event) => {
                        setCompetition(event.target.value);
                    }}/>
                    {!competition &&
                    <small className={"text-danger col-12"}>Ce champ est obligatoire</small>}
                </div> }

                <div className={"w-100 horizontal-top-line-grey-dark row dateTime"}>
                    <h6 className={"w-100"}>Date et heure</h6>
                    <DayPicker className={"date-picker"} mode={"single"} selected={date} onSelect={setDate}
                               locale={fr}/>
                    <div className={"col-6"}>
                        <TimeInput hour={hour} minute={minute}
                                   handleTime={handleTime}/>
                        <label className={"text-left"}>Saison<i
                            className={"text-danger"}> *</i></label>
                        <Select
                            options={props.seasonData}
                            defaultValue={saison}
                            value={saison}
                            placeholder="Selectionnez Saison"
                            onChange={(value) => {
                                setSaison(value);
                            }}
                        />
                    </div>
                </div>

                <div className={"row horizontal-top-line-grey-dark"}>
                    <h6 className={"w-100"}>Sélection d'équipe</h6>
                    <div className={"col-6 text-center"}>
                        <label className={"text-left"}>Equipe 1<i
                            className={"text-danger"}> *</i></label>
                        <Select
                            options={props.teams}
                            defaultValue={team1}
                            value={team1}
                            renderOption={renderTeam}
                            placeholder="Selectionnez une équipe"
                            onChange={(value) => {
                                setTeam1(value);
                            }}
                        />
                    </div>

                    <div className={"col-6 text-center"}>
                        <label className={"text-left"}>Equipe 2<i
                            className={"text-danger"}> *</i></label>
                        <Select
                            options={props.teams}
                            renderOption={renderTeam}
                            value={team2}
                            placeholder="Selectionnez une équipe"
                            onChange={(value) => {
                                setTeam2(value);
                            }}
                        />
                    </div>
                </div>
            </PopupBody>

            <PopupFooter dismiss={"Annuler"} close={props.close}>
                <input className={"btn btn-primary"} type={"submit"} value={props.match !== null ? "Modifier" : "Créer"}
                       onClick={() => handleSubmit()}/>
            </PopupFooter>
        </Popup>
    );
}