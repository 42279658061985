import React, {useEffect, useState} from "react";
import 'moment/locale/fr';
import Select from 'react-select'
import { Grid } from "@mui/material";

import { Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle } from "../../component/Popup/Popup";
import "../../assets/reactSelectStyle.scss"


export default function AddClassementPopup(props) {
    const [selectedTeam, setSelectedTeam] = useState([]);
    const [numberTeam, setNumberTeam] = useState(0);
    const [allTeam, setAllTeam] = useState(props.teams);

    useEffect(() => {
        setAllTeam(props.teams);
    }, [props.teams]);

    function handleSubmit() {
        if (selectedTeam.length === 0)
            return;
        props.add(selectedTeam);
    }

    function renderTeam(props, option, snapshot, className) {
        const imgStyle = {
            verticalAlign: 'middle',
            marginRight: 10,
        };

        return (
            <button {...props} className={className} type="button">
                <span><img alt="" style={imgStyle} width="32"  src={process.env.REACT_APP_API_URL + option.photo} /><span>{option.name}</span></span>
            </button>
        );
    }

    function buildTeamSelection() {
        let list = [];
        for (let i = 0; i < numberTeam; i++)
            list.push(<Grid item xs={5.5} md={3.5}>
                <label className={"text-left"}>Equipe {i + 1}<i
                    className={"text-danger"}> *</i></label>
                <Select name={"team"}
                              options={allTeam}
                              renderOption={renderTeam}
                              placeholder="Selectionnez une équipe"
                              value={selectedTeam[i]}
                              onChange={(value) => {
                                  let index = getIndexOf(value.value);
                                  let tmpSelectedTeam = [...selectedTeam];
                                  tmpSelectedTeam[i] = allTeam[index];
                                  setSelectedTeam(tmpSelectedTeam);
                              }}
                />
            </Grid>
            );
        return list;
    }

    function getIndexOf(value) {
        return allTeam.findIndex(item => item.value === value);
    }

    return (
            <Popup size={"large"} close={props.close}>
                <PopupHeader>
                    <PopupTitle>Créer un nouveau classement</PopupTitle>
                </PopupHeader>

                <PopupBody>
                    <div className={"row justify-content-center"}>
                        <h6 className={"w-100 mb-2"}>Nombre d'équipe</h6>
                        <input className="form-control mb-4 w-50" type={"text"}
                                onBlur={(event) => {
                                   setNumberTeam(parseInt(event.target.value));
                                   setSelectedTeam(Array(parseInt(event.target.value)).fill({}))
                        }}/>
                        <h6 className={"w-100"}>Sélection d'équipe</h6>
                        <Grid container justifyContent={"space-evenly"}>
                            {buildTeamSelection()}
                        </Grid>
                    </div>
                </PopupBody>

                <PopupFooter dismiss={"Annuler"} close={props.close}>
                    <input className={"btn btn-primary"} type={"submit"} value={"Créer"}
                           onClick={() => handleSubmit()}/>
                </PopupFooter>
            </Popup>
        );
}