import React, {useState, useEffect} from "react";
import 'moment/locale/fr';
import { Grid, TextField, Typography } from "@mui/material";

import { Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle } from "../../component/Popup/Popup";
import ApiClient from "../../service/ApiClient";

export default function OrderItemPopup(props) {
    const [order, setOrder] = useState(props.order);
    const [orderItem, setOrderItem] = useState([]);
    const [orderRefund, setOrderRefund] = useState(false);
    const [reasonRefund, setReasonRefund] = useState("");

    const styles = {
        separator: {
            "borderBottom": "1px solid #dee2e6",
            "margin": "1rem 0",
            "width": "100%"
        },
        header: {
            "padding": "1rem",
            "backgroundColor": "black",
            "color": "white"
        }, 
        body: {
            "padding": "1rem",
            "borderBottom": "1px solid #c7c7c7"
        }
    }

    useEffect(() => {
        (async () => {
            try {
                await loadData();
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    async function loadData() {
        try {
            let result = await ApiClient.instance.getShopOrderItem(order.id);
            setOrderItem(result.data);
        } catch (error) {
            console.log(error);
        }
    }

    function buildRowItem() {
        let row = [];
        for(let i = 0; i < orderItem.length; i++) {
            row.push(
                <Grid container item>
                    <Grid container item sx={styles.body} xs>
                        <Grid item xs>
                            <Typography>{orderItem[i].ref}</Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography>{orderItem[i].name}</Typography>
                        </Grid>

                        <Grid item xs>
                            <Typography>{orderItem[i].size}</Typography>
                        </Grid>

                        <Grid item xs={2}>
                            <Typography>{orderItem[i].quantity}</Typography>
                        </Grid>
                    </Grid>
                </Grid>);
        }
        return row;
    }

    return (
        <Popup size={"large"} close={props.close}>
            <PopupHeader>
                <PopupTitle>{orderRefund ? "Remboursement commande N° " + order.order_id : "Commande N° " + order.order_id}</PopupTitle>
            </PopupHeader>

            <PopupBody>
                <Grid container direction={"column"} justifyContent={"space-between"} alignItems={"center"}>
                <Grid container item>
                    <b>Nom client :&nbsp;</b> {" " + order.name}
                </Grid>

                <Grid container item sx={{marginTop: "1rem"}}>
                    <b>Mail client :&nbsp;</b> {" " + order.email}
                </Grid>
                
                <Grid container item sx={{marginTop: "1rem"}}>
                    <b>Statut :&nbsp;</b> {" " + order.status_label}
                </Grid>

                { order.status_label === "Remboursée" &&
                <Grid container item sx={{marginTop: "1rem"}}>
                    <b>Cause du remboursmenent :&nbsp;</b> {order.refund_reason ? " " + order.refund_reason : " Non spécifié"}
                </Grid>
                }

                <Grid item sx={styles.separator}/>
                <Grid container item>
                    { (!props.isCodeNotFound && !orderRefund) &&
                    <Grid container item sx={styles.header} xs>
                        <Grid item xs>
                            <Typography>Réf.</Typography>
                        </Grid>

                        <Grid item xs>
                            <Typography>Nom</Typography>
                        </Grid>

                        <Grid item xs>
                            <Typography>Taille</Typography>
                        </Grid>

                        <Grid item xs={2}>
                            <Typography>Quantité</Typography>
                        </Grid>
                    </Grid>}

                    { props.isCodeNotFound &&
                    <Grid item>
                        <Typography>Code non valide</Typography>
                    </Grid>
                    }
                </Grid>
                    {!orderRefund && buildRowItem()}
                    {orderRefund &&
                    <TextField fullWidth
                        id="outlined-textarea"
                        label="Raison"
                        multiline
                        onChange={(event) => {setReasonRefund(event.target.value)}}
                        rows={4}
                    />
                    }
                </Grid>
            </PopupBody>

            <PopupFooter dismiss={"Fermer"} close={props.close}>
            {(!props.isCode && !orderRefund && order.status_label !== "Remboursée") && 
            <input className={"btn btn-primary"} type={"submit"} value={"Rembourser"}
                       onClick={() => setOrderRefund(true)}/>
            }
            {(!props.isCode && orderRefund) && 
            <input className={"btn btn-primary"} type={"submit"} value={"Valider"}
                       onClick={async () => {
                        await ApiClient.instance.createRefundOrder(order.id, {reason: reasonRefund});
                        props.close()
                       }}/>
            }
            {props.isCode && 
            <input className={"btn btn-primary"} type={"submit"} value={"Confirmer la livraison"}
                       onClick={() => props.confirmWithdraw()}/>
            }
            </PopupFooter>
        </Popup>
    );
}