import { useState, useEffect } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { Button, Grid, Typography } from "@mui/material";
import { useTheme } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ReactGA from "react-ga4";

import ApiClient from "../../service/ApiClient";

export default function PopupNotification(props) {
    const theme = useTheme();
    const [randProduct, setRandProduct] = useState([]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        (async () => {
            try {
                await loadData();
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    async function loadData() {
        try {
            let result = await ApiClient.instance.getShopProductRand(props.product.id, 5);
            setRandProduct(result.data);
        } catch (error) {
            console.log(error);
        }
    }

    const styles = {
        overlay: {
            overflow: "hidden",
            backgroundColor: "rgba(219, 219, 219, 0.5)",
            height: "100vh",
            width: "100vw",
            maxHeight: "-webkit-fill-available",
            position: "fixed",
            top: 0,
            left:0,
            zIndex: "1004",
        },
        containerInfo: {
            backgroundColor: "white",
            height: "100%",
           
        },
        containerTop: {
            padding: "2rem",
        },
        popupTitle: {
            fontSize: "18px",
            fontWeight: "bold",
            color: theme.palette.colors.green
        },
        closeIcon: {
            fontSize: "30px",
            cursor: "pointer"
        },
        productRow: {
            padding: "2rem",
        },
        productName: {
            fontSize: "15px",
            color: "black"
        },
        productPrice: {
            fontSize: "15px",
            color: theme.palette.colors.green
        },
        randProductContainer: {
            backgroundColor: "#f6f6f6;",
            overflow: "auto"
        },
        randProductTitle: {
            fontSize: "14px",
            margin: "0 1rem 1rem 1rem"
        },
        randProductRow: {
            height: "100px",
            backgroundColor: "white",
            border: "1px solid black",
            borderRadius: "15px",
            margin: "0.5rem 1rem",
            padding: "10px",
            cursor: "pointer"
        },
        imageContainer: {
            height: "80px", 
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        continueShopContainer: {
            cursor: "pointer",
            textAlign: "center",
           
        },
        continueShop: {
            textDecoration: "underline",
            "&:hover": {
                textDecoration: "none"
            }
        },
        continueCart: {
            color: "white",
            backgroundColor: theme.palette.colors.green,
            borderRadius: "25px!important",
            fontWeight: "bold",
            "&:hover": {
                backgroundColor: "black",
                color: theme.palette.colors.green
            }
        },
    };

    function buildRandProduct() {
        let row = [];

        for (let i = 0; i < randProduct.length; i++) {
            row.push(
                <Grid container item sx={styles.randProductRow} justifyContent={"space-between"} alignItems="center" to={"/produit/" + randProduct[i].path} component={RouterLink} onClick={() => {props.handleClose()}}>
                    <Grid sx={styles.imageContainer} item xs={2}>
                        <img style={{maxHeight: "100%", maxWidth: "100%"}} alt="article" src={process.env.REACT_APP_API_URL + randProduct[i].firstPicture}/>
                    </Grid>

                    <Grid container item direction={"column"} xs={9} justifyContent={"space-between"}>
                        <Grid item>
                            <Typography sx={styles.productName}>{randProduct[i].name}</Typography>
                        </Grid>

                        <Grid item>
                            <Typography sx={styles.productPrice}>{parseFloat(randProduct[i].price).toFixed(2) + " €"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>);
        }
        return row;
    }

    return (<Grid container sx={styles.overlay} justifyContent={"flex-end"} onClick={() => {props.handleClose()}}>
        <Grid container item sx={styles.containerInfo} direction={"column"} xs={11} sm={7} md={4.5} lg={3.5} onClick={(e) => {e.stopPropagation()}}>
            <Grid container item sx={styles.containerTop} justifyContent={"space-between"} alignItems={"center"} xs={0.5}>
                <Grid item>
                    <Typography sx={styles.popupTitle}>Ajouté au panier</Typography>
                </Grid>
                <Grid item>
                    <CloseIcon sx={styles.closeIcon} onClick={() => {props.handleClose()}}/>
                </Grid>
            </Grid>

            <Grid container item sx={styles.productRow} justifyContent={"space-between"} xs={1}>
                <Grid item xs={2}>
                    <img style={{maxHeight: "100%", maxWidth: "100%"}} alt="article" src={process.env.REACT_APP_API_URL + props.product.firstPicture}/>
                </Grid>

                <Grid container item direction={"column"} xs={9} justifyContent={"space-between"}>
                    <Grid item>
                        <Typography sx={styles.productName}>{props.product.name}</Typography>
                    </Grid>

                    <Grid item>
                        <Typography sx={styles.productPrice}>{parseFloat(props.product.price).toFixed(2) + " €"}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container item xs sx={styles.randProductContainer} wrap="nowrap">
                <Grid container item xs={10.5}>
                    <Grid container item direction={"column"} sx={{padding: "2rem 0"}}>
                        <Grid item>
                            <Typography sx={styles.randProductTitle}>Des articles qui pourraient vous intéresser</Typography>
                        </Grid>
                    {buildRandProduct()}
                    </Grid>

                </Grid>
               
            </Grid>

            <Grid container item xs={1.5} justifyContent={"space-evenly"} alignItems={"center"}>
                <Grid item xs={5} wrap="nowrap" sx={styles.continueShopContainer} onClick={() => {props.handleClose()}}>
                <ArrowBackIosNewIcon sx={{fontSize: "15px"}}/><Typography display={"inline"} sx={styles.continueShop}>Continuer mes achats</Typography>
                </Grid>
                <Grid item xs={6}>
                <Button fullWidth sx={styles.continueCart} to={"/panier"} component={RouterLink}>
                   Voir mon panier
                </Button>
                </Grid>
            </Grid>
        </Grid>
    </Grid>);
}