import React from 'react';
import {Grid, Link, Typography, useTheme } from "@mui/material";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Link as RouterLink } from 'react-router-dom';

library.add(faFacebookF);
library.add(faInstagram);
library.add(faYoutube);

export default function Footer(props) {
    const theme = useTheme();
    const year = new Date().getFullYear();

    const styles = {
        footerContainer: {
            backgroundColor: theme.palette.colors.black,
            width: "100%",
            padding: "1rem",
        },
        footerLink: {
            color: "white",
            textDecoration: "none!important",
            margin: "0 0.5rem!important",
            '&:hover': {
                color: theme.palette.colors.green,
                textDecoration: "none"
            },
        },
        footerStade: {
            color: "white",
            borderRight: "1px solid white"
        },
        footerIcon: {
            color: "white",
            fontSize: "1.5rem",
            marginLeft: "0.5rem",
            verticalAlign: "middle",
            '&:hover': {
                color: theme.palette.colors.green
            }
        },
        footerMenu: {
            marginTop: "1rem!important",
            color: "white",
        },
    };

    return (
        <Grid container sx={styles.footerContainer} direction={"column"} alignItems={"center"} justifyContent={"space-between"}>
            <Grid container item alignItems={"center"} justifyContent={"center"}>
                <Grid item container direction={"column"} alignItems={"center"} justifyContent={"center"} sx={styles.footerStade} xs={6} sm={4} md={3} lg={2}>
                    <Grid item>
                        <Typography variant={"footerTitle"}>Complexe sportif</Typography>
                    </Grid>
                    <Grid item>
                        <Link sx={styles.footerLink} href={"https://goo.gl/maps/xFSPEuYA6u4HETWX9"} target={"_blank"} rel="noreferrer">
                            <Typography variant={"p"}>9 Toullan Bourg – 22 540 Louargat</Typography>
                        </Link>
                    </Grid>
                </Grid>

                <Grid item container direction={"column"} alignItems={"center"} justifyContent={"center"} sx={styles.footerStade} xs={6} sm={4} md={3} lg={2}>
                    <Grid item>
                        <Typography variant={"footerTitle"}>Stade Louis Torquéau</Typography>
                    </Grid>
                    <Grid item>
                        <Link sx={styles.footerLink} href={"https://www.google.com/maps/place/Stade+Louis+Torqueau/@48.5679031,-3.34277,15z/data=!4m5!3m4!1s0x0:0xa2180b60aef8dae6!8m2!3d48.5679031!4d-3.34277"} target={"_blank"} rel="noreferrer">
                            <Typography variant={"p"}>2 Rue du Stade – 22 540 Louargat</Typography>
                        </Link>
                    </Grid>
                </Grid>

                <Grid container alignItems={"center"} justifyContent={"center"} item xs={5} sm={3} md={2} lg={1}>
                    <Grid item>
                        <Link sx={styles.footerIcon} href={"https://www.facebook.com/profile.php?id=100057613052230"} target={"_blank"} rel="noreferrer">
                            <FontAwesomeIcon style={styles.footerIcon} icon={['fab', 'facebook-f']} />
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link sx={styles.footerIcon} href={"https://www.instagram.com/usmb_louargat/"} target={"_blank"} rel="noreferrer">
                            <FontAwesomeIcon style={styles.footerIcon} icon={['fab', 'instagram']} />
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link sx={styles.footerIcon} href={"https://www.youtube.com/channel/UCpjh_nePhHavtSo7kqj54zw"} target={"_blank"} rel="noreferrer">
                            <FontAwesomeIcon style={styles.footerIcon} icon={['fab', 'youtube']} />
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item sx={styles.footerMenu} justifyContent={"center"} alignItems={"center"} xs={2}>
                <Grid item>
                    <Link to={"/contacts"} component={RouterLink} sx={styles.footerLink}>
                        <Typography variant={"p"}>Nous contacter</Typography>
                    </Link>
                </Grid>
                <Grid item>
                    <Typography variant={"p"}>|</Typography>
                </Grid>
                <Grid item>
                    <Link to={"/mentions-legales"} component={RouterLink} sx={styles.footerLink}>
                        <Typography variant={"p"}>Mentions légales</Typography>
                    </Link>
                </Grid>
                <Grid item>
                    <Typography variant={"p"}>|</Typography>
                </Grid>
                <Grid item>
                    <Link sx={styles.footerLink} href rel="noreferrer">
                        <Typography variant={"p"}>© {year} US Méné Bré Louargat</Typography>
                    </Link>
                </Grid>
                <Grid item>
                    <Typography variant={"p"}>|</Typography>
                </Grid>
                <Grid item>
                    <Link sx={styles.footerLink} href="javascript:CookieScript.instance.show()">
                        <Typography variant={"p"}>Mes choix de cookies</Typography>
                    </Link>
                </Grid>
                <Grid item>
                    <Typography variant={"p"}>|</Typography>
                </Grid>
                <Grid item>
                    <Link sx={styles.footerLink} href={"https://www.linkedin.com/in/gwendal-amosse/"} target={"_blank"} rel="noreferrer">
                        <Typography variant={"p"}>Réalisé par Gwendal A.</Typography>
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    )
}
