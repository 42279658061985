import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import ReactGA from "react-ga4";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import ApiClient from "../../service/ApiClient";
import CheckoutForm from "./CheckoutForm";

import "./Checkout.scss"

const stripePromise = loadStripe("pk_test_51NZIFUB7OJVYfG7ep4UR897gRPND6i0JHPJ6DwR0AvmhNg869cai4Zugj0NYbYgO12wIro0eoafUTzlHI91PL3rr00DgTqxS5p");

export default function Checkout(props) {
    const [clientSecret, setClientSecret] = useState("");
    const [shopSession, setShopSession] = useState(null);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        (async () => {
            try {
                await loadData();
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    async function loadData() {
        try {
            if (localStorage.getItem('shop_session') !== null) {
                let result = await ApiClient.instance.getShopSession(localStorage.getItem('shop_session'));
                setShopSession(result.data);
                result = await ApiClient.instance.createPaymentIntent(result.data);
                setClientSecret(result.data.clientSecret);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (<Grid container item>
        {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm shopSession={shopSession} clientSecret={clientSecret} dimensions={props.dimensions}/>
        </Elements>
        )}
    </Grid>);
    
}