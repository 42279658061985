import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, Grid, Typography, useTheme } from "@mui/material";

import "../../assets/Categorie.scss";
import ApiClient from "../../service/ApiClient";
import { Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle } from "../../component/Popup/Popup";
import AddUpdateCategoryPopup from "./AddUpdateCategoriePopup";
import ReactGA from "react-ga4";
import { DndProvider } from 'react-dnd';
import { MultiBackend } from 'react-dnd-multi-backend';
import { HTML5toTouch }  from 'rdndmb-html5-to-touch';
import update from 'immutability-helper';
import Card from "./Card";

export default function Categorie(props) {
    const theme = useTheme();
    const [categories, setCategories] = useState([]);
    const [actualPage, setActualPage] = useState(0);
    const [nbRow, setNbRow] = useState(200);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [actualCategory, setActualCategory] = useState(null);
    const [showPopup, setShowPopup] = useState("");

    const styles = {
        header: {
            backgroundColor: "black",
            color: "white",
            padding: "0.5rem"
        },
        rows: {
            padding: "0.5rem",
            height: "60px",
            paddingTop: "18px"
        },
        img: {
            height: "100%"
        },
        cssLabel: {
            "&.Mui-focused": {
                color: "#048f00"
            }
        },
    };

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setCategories((prevCategories) =>
            update(prevCategories, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCategories[dragIndex]],
                ],
            }),
        )
    }, [])

    const renderCard = useCallback((category, index) => {
        return (
            <Card
                key={category.id}
                index={index}
                id={category.id}
                category={category}
                moveCard={moveCard}
                setActualCategory={setActualCategory}
                setShowPopup={setShowPopup}
                setConfirmPopup={setConfirmPopup}
            />
        )
    }, [])

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        (async () => {
            try {
                await loadData();
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);


    async function loadData() {
        try {
            let result = await ApiClient.instance.getCategory();
            setCategories(result.data);
        } catch (error) {
            console.log(error);
        }
    }

    async function addCategory(data) {
        try {
            let tmpCategory = data;

            tmpCategory.order = categories.length + 1;
            await ApiClient.instance.createCategory(tmpCategory);
            props.createNotification("success", {title: "Succès", message: "Catégorie créée", timeout: 3000});
            setShowPopup("");
            await loadData();
        } catch (e) {
            props.createNotification("error", {title: "Erreur", message: "Catégorie non créée", timeout: 3000});
            console.log(e);
        }
    }

    async function updateCategory(data) {
        try {
            await ApiClient.instance.updateCategory(data);
            props.createNotification("success", {title: "Succès", message: "Catégorie mis à jour", timeout: 3000});
            setShowPopup("");
            await loadData();
        } catch (e) {
            props.createNotification("error", {title: "Erreur", message: "Catégorie non mis à jour", timeout: 3000});
            console.log(e);
        }
    }

    async function updateOrderCategory() {
        let prevCategories = categories;

        prevCategories.forEach(function(category, index) {
            prevCategories[index].order = index + 1;
        });
        try {
            await ApiClient.instance.updateOrderCategory({data: prevCategories});
            props.createNotification("success", {title: "Succès", message: "Ordre des catégories mis à jour", timeout: 3000});
            await loadData();
        } catch (error) {
            props.createNotification("error", {title: "Erreur", message: "Ordre des catégories non mis à jour", timeout: 3000});
            console.log(error);
        }
    }

    function buildRowCategory() {
        return (<Grid container item direction={"column"}>
            {categories.map((category, i) => { return renderCard(category, i); })}
        </Grid>)
    }

    if (categories.length === 0)
        return (<div/>)
    return (
        <Grid container direction={"column"}>
            <Grid container item alignItems={"center"} sx={{marginBottom: "1rem"}}>
                <Grid item>
                    <Button variant="contained" sx={{backgroundColor: theme.palette.colors.green, marginLeft: "1rem"}} onClick={updateOrderCategory}>Mettre à jour l'ordre</Button>
                </Grid>
            </Grid>
            <Grid container item direction={"column"}>
                <Grid item container direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{backgroundColor: "black"}}>
                    <Grid item xs={2}>
                        <Box sx={styles.header}><Typography variant={"h6"}>Name</Typography></Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={styles.header}><Typography variant={"h6"}>Lien calendrier</Typography></Box>
                    </Grid>

                    <Grid item xs={3}>
                        <Box sx={styles.header}><Typography variant={"h6"}>Lien classement</Typography></Box>
                    </Grid>
                    <Grid item xs={2}><Box sx={styles.header}><Typography variant={"h6"}>Action</Typography></Box></Grid>
                </Grid>
                <Grid container item>
                    <Box style={{height: "400px", overflow: "auto"}}>
                        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                            {buildRowCategory()}
                        </DndProvider>
                    </Box>
                </Grid>
            </Grid>
            {showPopup === "create" && <AddUpdateCategoryPopup close={() => setShowPopup("")} submit={addCategory} category={null}/>}
            {showPopup === "update" && <AddUpdateCategoryPopup close={() => setShowPopup("")} submit={updateCategory} category={actualCategory}/>}
            {confirmPopup &&
            <Popup close={() => {
                setConfirmPopup(false);
            }}>
                <PopupHeader>
                    <PopupTitle>Supprimer une catégorie</PopupTitle>
                </PopupHeader>
                <PopupBody>
                    <p>Voulez-vous vraiment supprimer cette catégorie ?</p>
                </PopupBody>
                <PopupFooter dismiss={"Non"} close={() => {
                    setConfirmPopup(false);
                }}>
                    <button className={"btn btn-primary"} onClick={async () => {
                        try {
                            let prevCategories = categories;

                            prevCategories.forEach(function(category, index) {
                                if (category.id === actualCategory.id) {
                                    prevCategories.splice(index, 1);
                                }
                            })
                            setCategories(prevCategories);
                            await ApiClient.instance.deleteCategory(actualCategory.id);
                            await updateOrderCategory();
                            props.createNotification("success", {title: "Succès", message: "Catégorie supprimée", timeout: 3000});
                            setConfirmPopup(false);
                        } catch (e) {
                            props.createNotification("error", {title: "Erreur", message: "Catégorie non supprimée", timeout: 3000});
                            console.log(e);
                        }
                    }}>Oui
                    </button>
                </PopupFooter>
            </Popup>}
            <div className={"addArticle"}>
                <button className={"no-button"} onClick={() => {setShowPopup("create")}}>
                    <i className="material-icons">add_circle</i>
                </button>
            </div>
        </Grid>
    )
}
