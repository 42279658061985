import { useState, useEffect } from "react";
import { Grid, Typography, Select, FormControl, MenuItem, TextField, Button, Pagination, InputLabel } from "@mui/material";
import { useTheme } from '@emotion/react';
import ReactGA from "react-ga4";
import moment from 'moment';
import 'moment/locale/fr';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import ApiClient from "../../service/ApiClient";
import OrderItemPopup from "./OrderItemPopup";
import { NavLink, useLocation } from "react-router-dom";
import useQuery from "../../component/UrlQuery/UrlQuery";

export default function BoutiqueAdmin(props) {
    const theme = useTheme();
    const location = useLocation();
    const query = useQuery();
    const [nbPerPage, setNbPerPage] = useState(20);
    const [page, setPage] = useState(1);
    const [nbPage, setNbPage] = useState(0);
    const [filterBy, setFilterBy] = useState("all");
    const [searchCommand, setSearchCommand] = useState("");
    const [sortedBy, setSortedBy] = useState("date");
    const [orderBy, setOrderBy] = useState("desc");
    const [command, setCommand] = useState([]);
    const [commandStatus, setCommandStatus] = useState([]);
    const [actualOrder, setActualOrder] = useState(null);
    const [withdrawCode, setWithdrawCode] = useState("");
    const [isCode, setIsCode] = useState(false);
    const [isCodeNotFound, setIsCodeNotFound] = useState(false);
    const [isFirstQueryOrder, setIsFirstQueryOrder] = useState(true);
    const [isFirstQueryCode, setIsFirstQueryCode] = useState(true);

    const styles = {
        withdrawCodeRow: {
            "marginBottom": "1rem"
        },
        header: {
            "backgroundColor": "black",
            "color": "white",
            "padding": "1rem",
            "fontWeight": "bold",
        },
        headerItem: {
            "cursor": "pointer"
        },
        body: {
            "padding": "1rem",
            "borderBottom": "1px solid #c7c7c7",
            "&:hover" : {
                "backgroundColor": theme.palette.colors.green,
                "cursor": "pointer"
            }
        },
        textContent: {
            "fontSize": '0.5rem',
            [theme.breakpoints.up('sm')]: {
                "fontSize": '0.9rem',
            },
            [theme.breakpoints.up('md')]: {
                "fontSize": '1rem',
            },
            [theme.breakpoints.up('lg')]: {
                "fontSize": '1rem',
            },
        },
        pagination: {
            "marginTop": "1rem",
            ".Mui-selected": {
                "backgroundColor": theme.palette.colors.green + "!important",
                "color": "white",
                "&:hover": {
                    "backgroundColor": "black",
                    "color": "white",
                }
            }
        }
    }

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        (async () => {
            try {
                await loadData();
            } catch (error) {
                console.log(error);
            }
        })();
    }, [orderBy, page, filterBy, searchCommand, actualOrder]);

    async function loadData() {
        try {
            let result = await ApiClient.instance.getAllShopOrder(filterBy, searchCommand, sortedBy, orderBy, nbPerPage, (page - 1) * nbPerPage);
            setCommand(result.data.data);
            setNbPage(result.data.page);
            result = await ApiClient.instance.getShopOrderStatus();
            setCommandStatus(result.data);
            if (query.get("order") && isFirstQueryOrder) {
                setIsFirstQueryOrder(false);
                result = await ApiClient.instance.getShopOrder(query.get("order"));
                setActualOrder(result.data[0]);
            }

            if (query.get("code") && isFirstQueryCode){
                setIsFirstQueryCode(false);
                handleWithdrawCodeButton(true, query.get("code"));
            }
        } catch (error) {
            console.log(error);
        }
    }

    function buildStatusMenu(isFilter=false) {
        let item = [];

        if (isFilter) {
            item.push(<MenuItem sx={styles.textContent} value={"all"}>Tous</MenuItem>);
        }

        for (let i = 0; i < commandStatus.length; i++) {
            if ((commandStatus[i].label !== "Complétée" && commandStatus[i].label !== "Remboursée") || isFilter) {
                item.push(<MenuItem sx={styles.textContent} value={commandStatus[i].id}>{commandStatus[i].label}</MenuItem>);
            }
        }
        return (item);
    }

    async function handleChangeStatus(id, value) {
        try {
            await ApiClient.instance.updateShopOrder(id, {status_id: value});
            await loadData();
        } catch (error) {
            console.log(error);
        }
    }

    function buildCommandRow() {
        let row = [];

        for (let i = 0; i < command.length; i++) {
            row.push(
            <Grid container item xs={12} sx={styles.body} justifyContent={"center"} alignItems="center" onClick={() => {setActualOrder(command[i])}}>
                <Grid item xs>
                    <Typography sx={styles.textContent}>{command[i].order_id}</Typography>
                </Grid>

                <Grid item xs>
                    <Typography sx={styles.textContent}>{moment(command[i].date).format("DD/MM/YYYY HH:mm")}</Typography>
                </Grid>

                <Grid item xs>
                    {(command[i].status_label !== "Complétée" && command[i].status_label !== "Prêt" && command[i].status_label !== "Remboursée") &&
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            }}>
                            <Select sx={styles.textContent}
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={command[i].status_id}
                                onChange={(event) => {handleChangeStatus(command[i].id, event.target.value)}}>
                                    {buildStatusMenu()}
                            </Select>
                        </FormControl>
                    }

                    {(command[i].status_label === "Complétée" || command[i].status_label === "Prêt" || command[i].status_label === "Remboursée") &&
                        <Typography sx={styles.textContent}>{command[i].status_label}</Typography>
                    }
                </Grid>
            </Grid>)
        }

        return (row);
    }

    async function handleChangeSort(newSort) {
        try {
            let newOrderBy = (orderBy === "asc" ? "desc" : "asc");
            if (sortedBy === newSort) {
                setOrderBy(newOrderBy)
            } else {
                setOrderBy("asc");
                setSortedBy(newSort);
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function handleWithdrawCodeButton(isRedirect=false, code="") {
        try {
            if (withdrawCode !== "") {
                let result = await ApiClient.instance.getShopOrderWithCode(withdrawCode);

                if (result.data.length !== 0) {
                    setActualOrder(result.data[0]);
                    setIsCode(true);
                    setWithdrawCode("");
                } else {
                    props.createNotification("error", {title: "Erreur", message: "Code non valide", timeout: 3000});
                    setIsCodeNotFound(true);
                }
            }
            if (code !== "") {
                let result = await ApiClient.instance.getShopOrderWithCode(code);

                if (result.data.length !== 0) {
                    setActualOrder(result.data[0]);
                    setIsCode(true);
                    setWithdrawCode("");
                } else {
                    props.createNotification("error", {title: "Erreur", message: "Code non valide", timeout: 3000});
                    setIsCodeNotFound(true);
                }
            }  
        } catch (error) {
            console.log(error);
        }
    }

    async function confirmWithdraw() {
        try {
            await ApiClient.instance.confirmWithdrawShopOrder(actualOrder.id);
            setActualOrder(null);
            setIsCode(false);
            props.createNotification("success", {title: "Succès", message: "Retrait de la commande confirmé", timeout: 3000});
            await loadData();
        } catch (error) {
            props.createNotification("error", {title: "Erreur", message: "Retrait de la commande non confirmé", timeout: 3000});
            console.log(error);
        }
    }

    async function handleChangePage(event, value) {
       setPage(value);
       document.querySelector('.custom-scrollbars__content').scrollTo({
        top: 0,
        behavior: 'smooth',
       })
    }

    if (props.isAuth && (props.isAdmin || props.isAdminShop)) {
        return (
            <Grid container direction={"column"} justifyContent={"center"}>
                <Grid container item justifyContent={"space-between"} alignItems={"center"} xs={12}>
                    <Grid item xs={1.5}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Statut</InputLabel>
                        <Select sx={styles.textContent}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Statut"
                            value={filterBy}
                            onChange={(event) => {
                                setFilterBy(event.target.value)
                            }}>
                                {buildStatusMenu(true)}
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl>
                            <TextField id="outlined-basic" label="N° commande" variant="outlined" onChange={(event) => {
                                setSearchCommand(event.target.value)
                            }}/>
                        </FormControl>
                    </Grid>
                    <Grid container item xs={6} sx={styles.withdrawCodeRow} justifyContent={"flex-end"} alignItems={"center"}>
                        <Grid item>
                            <TextField
                                fullWidth
                                id="standard-error-helper-text"
                                label="Code"
                                variant="filled"
                                value={withdrawCode}
                                onChange={event => {
                                    setWithdrawCode(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Button sx={styles.textContent} variant="contained" color="secondary" onClick={handleWithdrawCodeButton}>Valider</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item>
                    <Grid container item xs={12} sx={styles.header} justifyContent={"center"}>
                        <Grid container item sx={styles.headerItem} xs alignitems={"center"} onClick={() => {handleChangeSort("order_id")}}>
                            <Grid item>
                                <Typography sx={styles.textContent}>N° Commande</Typography>
                            </Grid>

                            {sortedBy === "order_id" && orderBy === "asc" &&
                                <Grid item>
                                    <ArrowDropUpIcon/>
                                </Grid>
                            }

                            {sortedBy === "order_id" && orderBy === "desc" &&
                                <Grid item>
                                    <ArrowDropDownIcon/>
                                </Grid>
                            }
                        </Grid>
    
                        <Grid container item sx={styles.headerItem} xs alignitems={"center"} onClick={() => {handleChangeSort("date")}}>
                            <Grid item>
                                <Typography sx={styles.textContent}>Date</Typography>
                            </Grid>

                            {sortedBy === "date" && orderBy === "asc" &&
                                <Grid item>
                                    <ArrowDropUpIcon/>
                                </Grid>
                            }

                            {sortedBy === "date" && orderBy === "desc" &&
                                <Grid item>
                                    <ArrowDropDownIcon/>
                                </Grid>
                            }
                        </Grid>
    
                        <Grid container item sx={styles.headerItem} xs alignitems={"center"} onClick={() => {handleChangeSort("status_id")}}>
                            <Grid item>
                                <Typography sx={styles.textContent}>Statut</Typography>
                            </Grid>

                            {sortedBy === "status_id" && orderBy === "asc" &&
                                <Grid item>
                                    <ArrowDropUpIcon/>
                                </Grid>
                            }

                            {sortedBy === "status_id" && orderBy === "desc" &&
                                <Grid item>
                                    <ArrowDropDownIcon/>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    
                    {command.length > 0 && commandStatus.length > 0 &&
                        buildCommandRow()
                    }
                    <Grid container item justifyContent={"center"}>
                        <Grid item>
                            <Pagination sx={styles.pagination} count={nbPage} page={page} onChange={handleChangePage}/>
                        </Grid>
                    </Grid>
                </Grid>
                {actualOrder !== null && <OrderItemPopup close={() => {
                    setActualOrder(null);
                    setIsCode(false);
                    setIsCodeNotFound(false);
                    }} order={actualOrder} isCode={isCode} isCodeNotFound={isCodeNotFound} confirmWithdraw={confirmWithdraw}/>}
            </Grid>
        );
    } else if (props.isAuth && (!props.isAdmin && !props.isAdminShop)) {
        return (<Grid className={"prono-container-login"} container item justifyContent={"center"} alignItems={"center"}>
        <Grid  container direction={"column"} justifyContent={"center"} alignItems={"center"} item>
            <Grid item>
                <Typography variant={"h5"}>Vous n'avez pas les droits néccéssaire pour accéder à la page de gestion des commandes</Typography>
            </Grid>
            <Grid className={"auth-button"} item>
                <NavLink to={"/"} state={{prevUrl: location.pathname}}>
                    <button className="btn btn-primary"><Typography variant={"h5"}>Retour à l'acceuil</Typography></button>
                </NavLink>
            </Grid>
        </Grid>
    </Grid>)
    } else {
        return (<Grid className={"prono-container-login"} container item justifyContent={"center"} alignItems={"center"}>
        <Grid  container direction={"column"} justifyContent={"center"} alignItems={"center"} item>
            <Grid item>
                <Typography variant={"h5"}>Vous devez être connecté pour accéder à la page de gestion des commandes</Typography>
            </Grid>
            <Grid className={"auth-button"} item>
                <NavLink to={"/connexion"} state={{prevUrl: location.pathname + location.search}}>
                    <button className="btn btn-primary"><Typography variant={"h5"}>Connexion</Typography></button>
                </NavLink>
            </Grid>
        </Grid>
    </Grid>);
    }
    
}