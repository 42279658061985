import React, { useState, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import ApiClient from "../../service/ApiClient";

import { Box, useTheme } from "@mui/material";

export default function TextEditor(props) {
	const theme = useTheme();
    const editor = useRef(null);
	const [content, setContent] = useState(props.data !== null ? props.data : "");

	const styles = {
        addArticle: {
            position: "fixed",
            bottom: "75px",
            right: "0px",
            alignItems: "center",
            display: "block",
			backgroundColor: theme.palette.colors.green,
			borderRadius: "50%",
			padding: "10px",
            '& button': {
                backgroundColor: "transparent",
                border: "none"
            },
            '& i': {
                color: "white",
                fontSize: "40px"
            }
        },
    };

	const config = useMemo(
		() => {
			return {
				events:  {
					afterRemoveNode: async (node) => {
						if (node.nodeName === 'IMG') {
							await ApiClient.instance.deletePicture(node.src)
						}
	
					},
					backspace: (node) => {
						console.log("test");
					}
				},
                readonly: false,
                language: 'fr',
                placeholder: props.placeholder || 'Start typings...',
                disablePlugins: ['about', 'poweredByJodit', 'fullsize', 'classSpan', 'print', 'source'],
                enableDragAndDropFileToEditor: true,
                uploader: {
					url: process.env.REACT_APP_API_URL + 'uploadPicture',
					imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                    insertImageAsBase64URI: false,
					filesVariableName: function() {return "picture"},
					format: 'json',
                    method: 'POST',
					headers: {

					},
					prepareData: function (formdata) {
						return formdata;
					},
					isSuccess: function (resp) {
						return resp.success;
					},
					getMessage: function (resp) {
						return 0 !== resp.msg && Array.isArray(resp.msg)
						  ? resp.msg.join('')
						  : resp.msg;
					},
					process: function (resp) {
						let files = [];
						files.unshift(resp.files);
						return {
						  files: files,
						  error: resp.msg,
						  msg: resp.msg,
						};
					},
					error: function (value, e) { 
						value.j.e.fire('errorMessage', e.message, 'error', 4000);
					},
					defaultHandlerSuccess: function (data) {
						if (data.files && data.files.length) {
						  const tagName = 'img';
						  data.files.forEach((filename, index) => { //edetor insertimg function
							const elm = this.selection.j.createInside.element(tagName);
							const encodedUri = encodeURI(process.env.REACT_APP_API_URL + filename[0].filename).replaceAll("'", "\\'")
							elm.setAttribute('src', encodedUri);	
							this.selection.insertNode(elm);
						  });
						}
					  },
					  defaultHandlerError: function (value, e) {
						  console.log("handlerError");
						value.j.e.fire('errorMessage', e.message);
					  },
					  contentType: function (e) {
						return (
						  (void 0 === this.jodit.ownerWindow.FormData || 'string' == typeof e) &&
						  'application/x-www-form-urlencoded; charset=UTF-8'
						);
					  }
                },
            }
		},
		[props.placeholder]
	);

    return (
		<Box>
			<JoditEditor
			ref={editor}
			value={content}
			config={config}
			tabIndex={1} // tabIndex of textarea
			onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
			onChange={newContent => {}}
		/>
			{props.isAdmin &&
            <Box sx={styles.addArticle}>
                <button className={"no-button"} onClick={() => {
                   props.save(content);
                }}>
                    <i className="material-icons">save</i>
                </button>
            </Box>}
		</Box>
		
	);
}