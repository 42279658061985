import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Grid, Typography, Box, useTheme } from "@mui/material";

import SimpleSelect from "../../component/SelectDropDown/SimpleSelect";
import AddArticlePressePopup from "./addArticlePressePopup";
import { Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle } from "../../component/Popup/Popup";
import UpdateArticlePressePopup from "./updateArticlePressePopup";
import ApiClient from "../../service/ApiClient";

import ReactGA from "react-ga4";

export default function RevuePresse(props) {
    const theme = useTheme();
    const [articlesPresse, setArticlesPresse] = useState(null);
    const [articleYear, setArticleYear] = useState(null);
    const [actualYear, setActualYear] = useState(null);
    const [showFullscreen, setShowFullscreen] = useState(false);
    const [articleId, setArticleId] = useState(null);
    const [actualArticle, setActualArticle] = useState(null);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const styles = {
        addArticlePresse: {
            position: "fixed",
            bottom: "75px",
            right: "0px",
            alignItems: "center",
            display: "block",
            '& button': {
                backgroundColor: "transparent",
                border: "none"
            },
            '& i': {
                color: theme.palette.colors.green,
                fontSize: "60px"
            }
        },
        articleContainer: {
            padding: "10px",
        },
        colorBox: {
            cursor: "pointer",
            padding: "10px",
            backgroundColor: theme.palette.colors.whiteBox,
        },
        actionButton: {
            color: theme.palette.colors.green,
            border: "none",
            backgroundColor: "rgba(255, 255, 128, 0)",
            paddingTop: "5px",
            '&:hover': {
                color: "white"
            },
        },
        fullscreenBackground: {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 11,
        },
        fullscreenContainer: {
            position: "fixed",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: "white",
            maxHeight: "90%",
            maxWidth: "90%",
            margin: "auto",
            textAlign: "center",
            overflowX: "auto",
        }
    };

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        (async () => {
            try {
                await loadData(true, {name: null, value: null});
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    async function loadData(type, year) {
        let idYear = year.value;
        let nameYear = year.name
        let result = await ApiClient.instance.getRevuePresseYear();
        setArticleYear(result.data);
        if (type) {
            for (let i = 0; i < result.data.length; i++) {
                if (result.data[i].defaultSeason) {
                    idYear = result.data[i].value;
                    nameYear = result.data[i].name;
                    setActualYear(idYear);
                    break;
                }
            }
        }
        result = await ApiClient.instance.getArticlesPresse(nameYear);
        setArticlesPresse(result.data);
    }

    async function handleYear(idYear) {
        let year = articleYear.find(el => el.value === idYear)
        setActualYear(idYear);
        await loadData(false, year);
    }

    function closePopup(type) {
        if (type === "PopupModify")
            setActualArticle(null);
        else
            setShowPopup(!showPopup);
    }

    async function addArticlePresse(article) {
        try {
            let year = articleYear.find(el => el.value === actualYear)
            article.Date = new moment(article.Date).format("YYYY-MM-DD");
            await ApiClient.instance.postArticlePresse(article)
            props.createNotification("success", {title: "Succès", message: "Revue de presse créée", timeout: 3000});
            await loadData(false, year);
            closePopup();
        } catch (e) {
            console.log(e);
            props.createNotification("error", {title: "Erreur", message: "Revue de presse non créée", timeout: 3000});
        }
    }

    async function modifyArticlePresse(id, article) {
        try {
            let year = articleYear.find(el => el.value === actualYear)
            article.Date = new moment(article.Date).format("YYYY-MM-DD");
            await ApiClient.instance.updateArticlePresse(id, article);
            props.createNotification("success", {title: "Succès", message: "Revue de presse mis à jour", timeout: 3000});
            await loadData(false, year);
            closePopup("PopupModify");
        } catch (e) {
            console.log(e);
            props.createNotification("error", {title: "Erreur", message: "Revue de presse non mis à jour", timeout: 3000});
        }
    }

    async function deleteArticlePresse() {
        try {
            let year = articleYear.find(el => el.value === actualYear)
            await ApiClient.instance.deleteArticlePresse(articleId);
            props.createNotification("success", {title: "Succès", message: "Revue de presse supprimée", timeout: 3000});
            await loadData(false, year);
            setConfirmPopup(false);
        } catch (e) {
            console.log(e);
            props.createNotification("error", {title: "Erreur", message: "Revue de presse non supprimée", timeout: 3000});
        }
    }

    function buildArticleImage(article) {
        let encodedUri = encodeURI(process.env.REACT_APP_API_URL + article.Picture).replaceAll("'", "\\'").replaceAll("(", "\\(").replaceAll(")", "\\)");
        return (
            <Grid container direction={"column"} alignItems={"center"} justifyContent={"space-between"} sx={styles.colorBox}>
                { props.isAdmin &&
                <Grid container item justifyContent={"flex-end"}>
                    <Grid item>
                        <button style={styles.actionButton}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setConfirmPopup(true);
                                    setArticleId(article.Id);
                                }}><i className="material-icons">delete</i></button>
                        <button style={styles.actionButton}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setActualArticle(article);
                                }}><i className="material-icons">edit</i></button>
                    </Grid>
                </Grid>}
                <Grid item style={{
                    background: "url(" + encodedUri + ")", backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat', height: "300px", width: "100%"}}/>
                <Grid container item justifyContent={"center"} alignItems={"center"}>
                    <Grid item>
                        <Typography variant={"h5"}>{article.Title}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    function buildRow(nbArticlePerRow) {
        let row = [];
        let tabArticle = [];
        for (let i = 0; i < articlesPresse.length; i++) {
            for (let count = 0; count < nbArticlePerRow &&  i < articlesPresse.length; count++) {
                const tmpId = articlesPresse[i].Id;
                tabArticle.push(<Grid sx={styles.articleContainer} item xs key={articlesPresse[i].Id} onClick={() => {
                    setArticleId(tmpId);
                    setShowFullscreen(true);
                }}>
                    {buildArticleImage(articlesPresse[i], nbArticlePerRow, i)}
                </Grid>);
                i++
                if (i === articlesPresse.length && count !== nbArticlePerRow && nbArticlePerRow !== 1)
                    tabArticle.push(<Grid style={{margin: "1rem", padding: "10px"}} item xs key={-1}/>);
            }
            row.push(<Grid container item direction={"row"} justifyContent={"space-between"} key={i}>{tabArticle}</Grid>);
            tabArticle = [];
            i--;
        }
        return (<Grid container direction={"column"}>{row}</Grid>);
    }

    if (articleYear === null || articlesPresse === null)
        return (<Box/>);
    return (
        <Grid container justifyContent={"center"} alignItems={"stretch"} direction={"column"}>
            {showFullscreen &&
                <Box sx={styles.fullscreenBackground} onClick={() => setShowFullscreen(false)}>
                    <Box sx={styles.fullscreenContainer}>
                        <button className={"btn btn-sm close"}
                                onClick={(event) => {
                                    setShowFullscreen(false);
                                }}><i className="material-icons">close</i></button>
                        <img className={"fullscreen-image"} alt={"revuePresse"}
                             src={encodeURI(process.env.REACT_APP_API_URL + articlesPresse.find(el => el.Id === articleId).Picture)}/>
                    </Box>
                </Box>
            }
            <Grid item container direction={"row"} justifyContent={"flex-end"}>
                <Grid item>
                    <SimpleSelect data={articleYear} onChange={handleYear} actualIndex={actualYear} textLabel={"Année"}/>
                </Grid>
            </Grid>
            <Grid item>
                {buildRow((window.innerWidth >= 768 ? 2 : 1))}
            </Grid>
            {showPopup && <AddArticlePressePopup close={closePopup} add={addArticlePresse}/>}
            {actualArticle !== null && <UpdateArticlePressePopup article={actualArticle} close={closePopup} modify={modifyArticlePresse}/>}
            {confirmPopup &&
            <Popup close={() => {
                setConfirmPopup(false);
            }}>
                <PopupHeader>
                    <PopupTitle>Supprimer un article</PopupTitle>
                </PopupHeader>
                <PopupBody>
                    <p>Voulez-vous vraiment supprimer cet article ?</p>
                </PopupBody>
                <PopupFooter dismiss={"Non"} close={() => {
                    setConfirmPopup(false);
                }}>
                    <button className={"btn btn-primary"} onClick={async () => {
                        await deleteArticlePresse();
                    }}>Oui
                    </button>
                </PopupFooter>
            </Popup>}
            {props.isAdmin &&
            <Box sx={styles.addArticlePresse}>
                <button className={"no-button"} onClick={() => {
                    setShowPopup(true);
                }}>
                    <i className="material-icons">add_circle</i>
                </button>
            </Box>}
        </Grid>)
}
