import React, { useEffect, useState } from 'react';
import {NavLink, useParams} from "react-router-dom";
import moment from 'moment';
import 'moment/locale/fr';
import { Grid, TextField, useTheme } from "@mui/material";

import "../../assets/Classement.scss"
import SimpleSelect from "../../component/SelectDropDown/SimpleSelect";
import AddClassementPopup from "./AddClassementPopup";
import ApiClient from "../../service/ApiClient";
import UpdateClassementPopup from "./UpdateClassementPopup";
import AddPlayerPopup from "./AddPlayerPopup";
import { Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle } from "../../component/Popup/Popup";

import ReactGA from "react-ga4";

export default function Classement(props) {
    const theme = useTheme();
    const params = useParams();
    const [rank, setRank] = useState([]);
    const [rankGoal, setRankGoal] = useState([]);
    const [season, setSeason] = useState([]);
    const [actualSeason, setActualSeason] = useState(null);
    const [showPopup, setShowPopup] = useState("");
    const [allTeam, setAllTeam] = useState([]);
    const [editPlayer, setEditPlayer] = useState(false);
    const [actualPlayer, setActualPlayer] = useState(null);
    const [actualIdPlayer, setActualIdPlayer] = useState(null);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [phase, setPhase] = useState({});
    const [actualPhase, setActualPhase] = useState(1);

    const styles = {
        navItem: {
            backgroundColor: "white",
            color: "black",
            border: "1px black solid",
            cursor: "pointer",
            padding: "0.5rem 0"
        },
        navItemActive: {
            backgroundColor: "black",
            color: "white",
            border: "1px black solid",
            cursor: "pointer",
            padding: "0.5rem 0"
        }
    };

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        (async () => {
            try {
                await loadData();
            } catch (error) {
                console.log(error);
            }
        })();

    }, []);

    useEffect(() => {
        (async () => {
            try {
                await loadRank();
            } catch (error) {
                console.log(error);
            }
        })();

    }, [actualSeason, params.type, params.team, actualPhase]);

    async function loadData() {
        try {
            let result = await ApiClient.instance.getSeason();
            setSeason(result.data);
            for (let i = 0; i < result.data.length; i++) {
                if (result.data[i].defaultSeason) {
                    setActualSeason(result.data[i].value);
                }
            }
            result = await ApiClient.instance.getTeams();
            setAllTeam(result.data);
        } catch (error) {
            console.log(error);
        }
    }

    async function loadRank() {
        try {
            let result = null;
            if (params.type === "general" && actualSeason !== null) {
                result = await ApiClient.instance.getPhaseRank(params.team, actualSeason);
                setPhase(result.data);
                if (result.data.nbPhase === 1) {
                    setActualPhase(1);
                }
                result = await ApiClient.instance.getRank(params.team, actualSeason, actualPhase);
                setRank(result.data);
            } else if (params.type === "buteurs") {
                result = await ApiClient.instance.getRankGoal(params.team, actualSeason);
                setRankGoal(result.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    function buildHeadGeneral() {
        return (<tr className={"classement-header"}>
            <td className={"rank"}>Rang</td>
            <td>Equipes</td>
            <td className={"number-col"}>Pts.</td>
            <td className={"number-col"}>J.</td>
            <td className={"number-col"}>G.</td>
            <td className={"number-col"}>N.</td>
            <td className={"number-col"}>P.</td>
            <td className={"number-col"}>Bp.</td>
            <td className={"number-col"}>Bc.</td>
            <td className={"number-col"}>Diff.</td>
        </tr>);
    }

    function buildRowGeneral() {
        let rankTable = [];
        let encodedUri = null;
        for (let i = 0; i < rank.length; i++){
            encodedUri = encodeURI(process.env.REACT_APP_API_URL + rank[i].Team.Logo).replaceAll("'", "\\'");
            rankTable.push(
                <tr className={rank[i].Team.Name.search("Louargat") !== -1 || rank[i].Team.Name.search("Argoat") !== -1 ? "classement-usmb" : ""} key={i}>
                <td className={"rank"}>{rank[i].Ranks}.</td>
                <td>
                    <Grid container>
                        <Grid item xs={1} style={{
                            margin: "0.5rem 0",
                            backgroundImage: "url(" + encodedUri + ")", backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat', height: "30px"}}/>
                        <Grid item style={{marginLeft: "1rem"}}><span style={{height: "100%"}}>{rank[i].Team.Name}</span></Grid>
                    </Grid>
                </td>
                <td className={"number-col"}>{rank[i].Pts}</td>
                <td className={"number-col"}>{rank[i].J}</td>
                <td className={"number-col"}>{rank[i].G}</td>
                <td className={"number-col"}>{rank[i].N}</td>
                <td className={"number-col"}>{rank[i].D}</td>
                <td className={"number-col"}>{rank[i].Bp}</td>
                <td className={"number-col"}>{rank[i].Bc}</td>
                <td className={"number-col"}>{rank[i].Diff}</td>
                </tr>)
        }
        return rankTable
    }

    function buildHeadGoal() {
        return (<tr className={"classement-header"}>
            <td className={"rank"}>Rang</td>
            <td>Joueur</td>
            <td className={"number-col"}>Championnat</td>
            <td className={"number-col"}>Coupe</td>
            <td className={"number-col"}>Total</td>
            {props.isAdmin && <td style={{width: "100px", textAlign: "center"}}>Actions</td>}
        </tr>);
    }

    function buildRowGoal() {
        let rankTable = [];
        let rankNb = 1;

        for (let i = 0; i < rankGoal.length; i++){
            const tmpMatch = rankGoal[i];
            if (i > 0 && rankGoal[i - 1].GoalTotal !== rankGoal[i].GoalTotal)
                rankNb = i + 1;
            rankTable.push(
                <tr key={i}>
                    <td className={"rank"}>{rankNb}.</td>
                    <td><span style={{height: "100%"}}>{ rankGoal[i].Name}</span></td>
                    <td className={"number-col"}>{!editPlayer || rankGoal[i].Id !== (actualPlayer === null ? -1 : actualPlayer.Id) ? rankGoal[i].Goal : <TextField inputProps={{min: 0, style: { textAlign: 'center' }}} type={"tel"} defaultValue={actualPlayer.Goal} onChange={(event) => {
                        let tmp = actualPlayer;
                        tmp.Goal = parseInt(event.target.value);
                        tmp.GoalTotal = tmp.Goal + tmp.GoalCup;
                        setActualPlayer(tmp);
                    }}/>}</td>
                    <td className={"number-col"}>{!editPlayer || rankGoal[i].Id !== (actualPlayer === null ? -1 : actualPlayer.Id) ? rankGoal[i].GoalCup : <TextField inputProps={{min: 0, style: { textAlign: 'center' }}} type={"tel"} defaultValue={actualPlayer.GoalCup} onChange={(event) => {
                        let tmp = actualPlayer;
                        tmp.GoalCup = parseInt(event.target.value);
                        tmp.GoalTotal = tmp.Goal + tmp.GoalCup;
                        setActualPlayer(tmp);
                    }}/>}</td>
                    <td className={"number-col"}>{rankGoal[i].GoalTotal}</td>
                    {props.isAdmin &&
                    <td style={{width: "100px", textAlign: "center"}}>
                        {!editPlayer || rankGoal[i].Id !== (actualPlayer === null ? -1 : actualPlayer.Id) ?
                        <div>
                            <button className={"btn btn-sm remove-player"}
                                    onClick={() => {
                                        setConfirmPopup(true);
                                        setActualIdPlayer(rankGoal[i].Id);
                                    }}><i className="material-icons">delete</i></button>
                            <button className={"btn btn-sm edit-player"}
                                    onClick={() => {
                                        setEditPlayer(true);
                                        setActualPlayer(tmpMatch);
                                    }}><i className="material-icons">edit</i></button>
                        </div> :
                            <div>
                                <button className={"btn btn-sm cancel-edit"}
                                        onClick={async() => {
                                            setActualPlayer(null);
                                            setEditPlayer(false);
                                        }}><i className="material-icons">clear</i></button>
                                <button className={"btn btn-sm edit-player"}
                                        onClick={async() => {
                                            try {
                                                await ApiClient.instance.updatePlayer(actualPlayer, params.team, actualPlayer.Id);
                                                props.createNotification("success", {title: "Succès", message: "Joueur mis à jour", timeout: 3000});
                                                setEditPlayer(false);
                                                setActualPlayer(null);
                                                await loadRank();
                                            } catch (e) {
                                                props.createNotification("error", {title: "Erreur", message: "Joueur non mis à jour", timeout: 3000});
                                                console.log(e);
                                            }
                                        }}><i className="material-icons">done</i></button>
                            </div>}
                    </td>}
                </tr>)
        }
        return rankTable
    }

    async function handleSeason(idSeason) {
        setActualPhase(1);
        setActualSeason(idSeason);
    }

    async function addClassement(selectedTeam) {
        let data = [];

        for (let i = 0; i < selectedTeam.length; i++) {
            data.push({Team: selectedTeam[i].value, Ranks: i + 1});
        }
        try {
            await ApiClient.instance.createRank(data, params.team, actualSeason);
            props.createNotification("success", {title: "Succès", message: "Classement créé", timeout: 3000});
            setShowPopup('');
            await loadRank();
        } catch (e) {
            props.createNotification("error", {title: "Erreur", message: "Classement non créé", timeout: 3000});
            console.log(e);
        }

    }

    async function updateClassement(data) {
        try {
            await ApiClient.instance.updateRank(data, params.team, actualSeason, actualPhase);
            props.createNotification("success", {title: "Succès", message: "Classement mis à jour", timeout: 3000});
            setShowPopup('');
            await loadRank();
        } catch (e) {
            props.createNotification("error", {title: "Erreur", message: "Classement non mis à jour", timeout: 3000});
            console.log(e);
        }
    }

    async function updateLinkAuto(data) {
        try {
            await ApiClient.instance.updateLinkRank(data);
            props.createNotification("success", {title: "Succès", message: "Lien du classement mis à jour", timeout: 3000});
            setShowPopup('');
            await loadRank();
        } catch (e) {
            props.createNotification("error", {title: "Erreur", message: "Lien du classement non mis à jour", timeout: 3000});
            console.log(e);
        }
    }

    async function addPlayer(data) {
        try {
            data.GoalTotal = data.Goal + data.GoalCup;
            await ApiClient.instance.createPlayer(data, params.team, actualSeason);
            props.createNotification("success", {title: "Succès", message: "Joueur créé", timeout: 3000});
            setShowPopup('');
            await loadRank();
        } catch (e) {
            props.createNotification("error", {title: "Erreur", message: "Joueur non créé", timeout: 3000});
            console.log(e);
        }
    }

    function buildPhaseRank() {
        let arr = [];

        for (let i = 0; i < phase.nbPhase; i++) {
            arr.push(
            <Grid container item sx={(actualPhase === phase.phase[i] ? styles.navItemActive : styles.navItem)} xs={3} sm={2} md={2} lg={1} justifyContent={"center"} alignItems={"center"} 
            onClick={() => {
                setActualPhase(phase.phase[i]);
            }}>
                <Grid item>
                    Phase {phase.phase[i]}
                </Grid>
            </Grid>);
        }
        return arr
    }

    function buildRankGeneral() {
        return (
            <Grid container direction={"column"}>
                <Grid item container direction={"row"} justifyContent={"space-between"}>
                    <Grid item>
                        <nav className={"classement-top-container"}>
                            <NavLink className="nav-item" to={"/classement/general/" + params.team}>
                                <span className={params.type === "general" ? "active" : ""}>GÉNÉRAL</span>
                            </NavLink>
                            {(params.team !== "u15" && params.team !== "u17") &&
                            <NavLink className="nav-item" to={"/classement/buteurs/" + params.team}>
                                <span className={params.type === "buteurs" ? "active" : ""}>BUTEURS</span>
                            </NavLink>}
                        </nav>
                    </Grid>

                    <Grid item>
                        <SimpleSelect data={season} onChange={handleSeason}
                                      actualIndex={actualSeason} textLabel={"Saison"}/>
                    </Grid>
                </Grid>

                {phase.nbPhase > 1 &&
                    <Grid item container direction={"row"} sx={{marginTop: "1rem"}}>
                        {buildPhaseRank()}
                    </Grid>
                }

                <Grid item>
                    <table className={"classement-table"}>
                        <thead>
                        {buildHeadGeneral()}
                        </thead>
                        <tbody>
                        {buildRowGeneral()}
                        </tbody>
                    </table>
                    {rank.length !== 0 && rank[0].DateUpdate !== null && <div><label className={"dateUpdate"}>Classement mis à jour le {moment(rank[0].DateUpdate).format("DD/MM/YYYY HH:mm")}</label></div>}

                    {showPopup === "create" && <AddClassementPopup close={() => setShowPopup("")} add={addClassement} category={params.team} seasonData={season} teams={allTeam}/>}

                    {showPopup === "update" && <UpdateClassementPopup close={() => setShowPopup("")} update={updateClassement} updateLink={updateLinkAuto} category={params.team} actualSeason={actualSeason} seasonData={season} rank={rank}/>}

                    {showPopup === "delete" &&
                    <Popup close={() => {
                        setShowPopup("");
                    }}>
                        <PopupHeader>
                            <PopupTitle>Supprimer le classement</PopupTitle>
                        </PopupHeader>
                        <PopupBody>
                            <p>Voulez-vous vraiment supprimer le classement ?</p>
                        </PopupBody>
                        <PopupFooter dismiss={"Non"} close={() => {
                            setShowPopup("");
                        }}>
                            <button className={"btn btn-primary"} onClick={async() => {
                                try {
                                    await ApiClient.instance.deleteRank(params.team, actualSeason, actualPhase);
                                    props.createNotification("success", {title: "Succès", message: "Classement supprimé", timeout: 3000});
                                    setShowPopup('');
                                    setActualPhase(phase.phase[phase.nbPhase - 2]);
                                    await loadRank();
                                } catch (e) {
                                    props.createNotification("error", {title: "Erreur", message: "Classement non supprimé", timeout: 3000});
                                    console.log(e);
                                }
                            }}>Oui
                            </button>
                        </PopupFooter>
                    </Popup>
                    }

                    {props.isAdmin &&
                    <div className={"addUpdateRank"}>
                        <button className={"no-button"} onClick={() => {
                            rank.length === 0 ? setShowPopup("create") : setShowPopup("update")
                        }}>
                            <i className="material-icons">{rank.length === 0 ? "add_circle" : "update"}</i>
                        </button>
                    </div>}

                    {props.isAdmin && rank.length !== 0 &&
                    <div className={"addRank"}>
                        <button className={"no-button"} onClick={() => {
                           setShowPopup("create");
                        }}>
                            <i className="material-icons">add_circle</i>
                        </button>
                    </div>}

                    {props.isAdmin && rank.length !== 0 && actualPhase === phase.phase[phase.nbPhase - 1] &&
                    <div className={"deleteRank"}>
                        <button className={"no-button"} onClick={() => {
                            setShowPopup("delete");
                        }}>
                            <i className="material-icons">delete</i>
                        </button>
                    </div>}
                </Grid>
            </Grid>
        );
    }

    function buildRankGoal() {
        return (<Grid container direction={"column"}>
            <Grid item container direction={"row"} justifyContent={"space-between"}>
                <Grid item>
                    <nav className={"classement-top-container"}>
                        <NavLink className="nav-item" to={"/classement/general/" + params.team}>
                            <span className={params.type === "general" ? "active" : ""}>GÉNÉRAL</span>
                        </NavLink>
                        <NavLink className="nav-item" to={"/classement/buteurs/" + params.team}>
                            <span className={params.type === "buteurs" ? "active" : ""}>BUTEURS</span>
                        </NavLink>
                        {/* <NavLink className="nav-item" to={"/classement/passeurs/" + this.params.team}>
                            <span className={this.params.type === "passeurs" ? "active" : ""}>PASSEURS</span>
                        </NavLink> */}
                    </nav>
                </Grid>

                <Grid item>
                    <SimpleSelect data={season} onChange={handleSeason} actualIndex={actualSeason} textLabel={"Saison"}/>
                </Grid>
            </Grid>

            <Grid item>
                <table className={"classement-table"}>
                    <thead>
                    {buildHeadGoal()}
                    </thead>
                    <tbody>
                    {buildRowGoal()}
                    </tbody>
                </table>

                {showPopup === "create" && <AddPlayerPopup close={() => setShowPopup("")} add={addPlayer}/>}

                {confirmPopup &&
                <Popup close={() => {
                    setConfirmPopup(false);
                }}>
                    <PopupHeader>
                        <PopupTitle>Supprimer un joueur</PopupTitle>
                    </PopupHeader>
                    <PopupBody>
                        <p>Voulez-vous vraiment supprimer ce joueur ?</p>
                    </PopupBody>
                    <PopupFooter dismiss={"Non"} close={() => {
                        setConfirmPopup(false);
                    }}>
                        <button className={"btn btn-primary"} onClick={async() => {
                            try {
                                await ApiClient.instance.deletePlayer(params.team, actualIdPlayer);
                                props.createNotification("success", {title: "Succès", message: "Joueur supprimé", timeout: 3000});
                                setConfirmPopup(false);
                                await loadRank();
                            } catch (e) {
                                props.createNotification("error", {title: "Erreur", message: "Joueur non supprimé", timeout: 3000});
                                console.log(e);
                            }
                        }}>Oui
                        </button>
                    </PopupFooter>
                </Popup>
                }

                {props.isAdmin &&
                <div className={"addUpdateRank"}>
                    <button className={"no-button"} onClick={() => {setShowPopup("create")}}>
                        <i className={"material-icons"}>add_circle</i>
                    </button>
                </div>}
            </Grid>
        </Grid>)
    }

    if (actualSeason !== null) {
        if (params.type === "general")
            return (buildRankGeneral());
        else
            return (buildRankGoal());
    } else
        return <div/>
}
