import React, {useState} from "react";
import {Grid, useTheme } from "@mui/material";
import Dropzone from 'react-dropzone-uploader'
import {getDroppedOrSelectedFiles} from "html5-file-selector";

import "./DropzoneFile.scss"
import 'react-dropzone-uploader/dist/styles.css'



export default function DropzoneFile(props) {
    const theme = useTheme();
    const styles = {
        '& label.Mui-focused': {
            color: theme.palette.colors.green,
        },
        fileInput: {
            backgroundColor: 'white',
            border: "1px solid " + theme.palette.colors.green,
            cursor: 'pointer',
            padding: "6px 12px",
            textTransform: "uppercase",
            fontWeight: "bold",
            transition: "0.5s",
            '&:hover': {
                backgroundColor: 'black',
            },
        }
    };

    function handleChangeStatus({ meta, file }, status, fileWithMeta) {
        props.handleChange(fileWithMeta);
    }

    function getFilesFromEvent(e) {
        return new Promise(resolve => {
            getDroppedOrSelectedFiles(e).then(chosenFiles => {
                resolve(chosenFiles.map(f => f.fileObject))
            })
        })
    }

    function buildLayout({ input, previews, dropzoneProps, files, extra: { maxFiles } }) {
        return (
            <Grid container direction={"column"}>
                <Grid container item {...dropzoneProps} justifyContent={"center"} alignItems={"center"}>
                    {files.length < maxFiles && input}
                </Grid>
                <Grid container direction={"column"} item style={{margin: "1rem 0"}}>
                    {previews}
                </Grid>
            </Grid>
        )
    }

    function buildInput({ accept, onFiles, files, getFilesFromEvent }) {
        return (
            <Grid item container direction={"column"} justifyContent={"center"} alignItems={"center"} style={{heignt: "120px!important"}}>
                <Grid item style={{color: "rgba(0, 0, 0, 0.6)", fontWeight: "bold"}}>
                    Déposer les fichiers ici ou
                </Grid>

                <Grid item>
                    <label style={styles.fileInput}>
                        Sélectionnez des fichiers
                        <input
                            style={{ display: 'none' }}
                            type="file"
                            accept={accept}
                            multiple
                            onChange={e => {
                                getFilesFromEvent(e).then(chosenFiles => {
                                    onFiles(chosenFiles)
                                })
                            }}
                        />
                    </label>
                </Grid>
            </Grid>
        )
    }

    return (
        <Dropzone
            onChangeStatus={handleChangeStatus}
            accept={props.accept}
            LayoutComponent={buildLayout}
            InputComponent={buildInput}
            PreviewComponent={props.buildPreview}
            inputContent={"Déposer les fichiers ici ou cliquer pour sélectionner"}
            inputWithFilesContent={"Ajouter des fichiers"}
            SubmitButtonComponent={null}
            getFilesFromEvent={getFilesFromEvent}
            initialFiles={props.attachment}
        />
    );
}