import React, {useState} from "react";
import { Grid, Typography } from "@mui/material";
import moment from "moment";

import ApiClient from "../../service/ApiClient";
import {Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle} from "../../component/Popup/Popup";

export default function UpdateScorePopup(props) {
    const styles = {
        categoryTitle: {
            margin: "1rem",
        },
    };
    const [lastMatch, setLastMatch] = useState(props.lastMatch);
    const [nextMatch, setNextMatch] = useState(props.nextMatch);

    async function handleSubmit () {
        let tmpMatch = null;
        let result = null;
        let isError = false;
        const reg =  new RegExp("^[0-9]+$");

        for (let i = 0; i < lastMatch.length || i < nextMatch.length; i++) {
            if ( lastMatch[i].id !== null && (i < lastMatch.length && reg.test(lastMatch[i].team1.score) && reg.test(lastMatch[i].team2.score))) {
                tmpMatch = lastMatch[i];
                tmpMatch.date = new moment(tmpMatch.date).format("YYYY-MM-DD HH:mm:ss");
                tmpMatch.category = tmpMatch.category[0].toLowerCase() + tmpMatch.category.slice(1).replace(/ /g,'');
                result = await ApiClient.instance.updateMatch(tmpMatch.category, tmpMatch);
                if (lastMatch[i].competition !== "Amical") {
                    await ApiClient.instance.createOrUpdateRankProno(tmpMatch.category, tmpMatch.season);
                }
                isError =  result.status !== 200;
            }
            if (nextMatch[i].id !== null && (i < nextMatch.length && reg.test(nextMatch[i].team1.score) && reg.test(nextMatch[i].team2.score))) {
                tmpMatch = nextMatch[i];
                tmpMatch.date = new moment(tmpMatch.date).format("YYYY-MM-DD HH:mm:ss");
                tmpMatch.category = tmpMatch.category[0].toLowerCase() + tmpMatch.category.slice(1).replace(/ /g,'');
                result = await ApiClient.instance.updateMatch(tmpMatch.category, tmpMatch);
                if (nextMatch[i].competition !== "Amical") {
                    await ApiClient.instance.createOrUpdateRankProno(tmpMatch.category, tmpMatch.season);
                }
                isError =  result.status !== 200;
            }
        }
        props.close();
        if (!isError) {
            props.createNotification("success", {title: "Succès", message: "Score des matchs mis à jour", timeout: 3000});
        } else {
            props.createNotification("error", {title: "Erreur", message: "Score des matchs non mis à jour", timeout: 3000});
        }
    }

    function buildContent () {
        let row = [];

        for (let i = 0; i < lastMatch.length || i < nextMatch.length; i++) {
            row.push(
                <Grid container direction={"column"} key={i}>
                    <Grid item sx={styles.categoryTitle}><Typography variant={"h6"}>{lastMatch[i] !== undefined ? lastMatch[i].category : nextMatch[i].category}</Typography></Grid>
                    <Grid item container justifyContent={"center"}>
                        <Grid item xs container direction={"column"} alignItems={"center"} justifyContent={"center"}>
                            <Grid item><Typography style={{fontWeight: "bold", marginBottom: "1rem"}} variant={"subtitle2"}>Score dernier match</Typography></Grid>
                            { i < lastMatch.length && lastMatch[i].id !== null && 
                                <Grid item container alignItems={"center"}>
                                    <Grid item xs>{lastMatch[i].team1.name}</Grid>
                                    <Grid item xs={1}>
                                        <input style={{width: "100%"}} type={"number"} pattern={"[0-9]*"} defaultValue={(lastMatch[i].team1.score === null ? null : lastMatch[i].team1.score)} onChange={(event) => {
                                            let tmp = lastMatch
                                            tmp[i].team1.score = event.target.value;
                                            if (event.target.validity.valid)
                                                setLastMatch(tmp);
                                        }}/>
                                    </Grid>
                                    <Grid item style={{margin: "0 5px"}}>-</Grid>
                                    <Grid item xs={1}>
                                        <input style={{width: "100%"}} type={"number"} pattern={"[0-9]*"} defaultValue={(lastMatch[i].team2.score === null ? null : lastMatch[i].team2.score)} onChange={(event) => {
                                            let tmp = lastMatch
                                            tmp[i].team2.score = event.target.value;
                                            if (event.target.validity.valid)
                                                setLastMatch(tmp);
                                        }}/>
                                    </Grid>
                                    <Grid item xs>{lastMatch[i].team2.name}</Grid>
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs container direction={"column"} alignItems={"center"} justifyContent={"center"}>
                            <Grid item><Typography style={{fontWeight: "bold", marginBottom: "1rem"}} variant={"subtitle2"}>Score prochain match</Typography></Grid>
                            { i < nextMatch.length && nextMatch[i].id !== null  &&
                                <Grid item container alignItems={"center"}>
                                    <Grid item xs>{nextMatch[i].team1.name}</Grid>
                                    <Grid item xs={1}>
                                        <input style={{width: "100%"}} type={"number"} pattern={"[0-9]*"} defaultValue={(nextMatch[i].team1.score === null ? null : nextMatch[i].team1.score)} onChange={(event) => {
                                            let tmp = nextMatch
                                            tmp[i].team1.score = event.target.value;
                                            if (event.target.validity.valid)
                                                setNextMatch(tmp);
                                        }}/>
                                    </Grid>
                                    <Grid item style={{margin: "0 5px"}}>-</Grid>
                                    <Grid item xs={1}>
                                        <input style={{width: "100%"}} type={"number"} pattern={"[0-9]*"} defaultValue={(nextMatch[i].team2.score === null ? null : nextMatch[i].team2.score)} onChange={(event) => {
                                            let tmp = nextMatch
                                            tmp[i].team2.score = event.target.value;
                                            if (event.target.validity.valid)
                                                setNextMatch(tmp);
                                        }}/>
                                    </Grid>
                                    <Grid item xs>{nextMatch[i].team2.name}</Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
        return (row);
    }

    return (
        <Popup size={"large"} close={props.close}>
            <PopupHeader>
                <PopupTitle>Mettre à jour les scores</PopupTitle>
            </PopupHeader>

            <PopupBody>
                <Grid container direction={"column"}>
                    {buildContent()}
                </Grid>
            </PopupBody>

            <PopupFooter dismiss={"Annuler"} close={props.close}>
                <input className={"btn btn-primary"} type={"submit"} value={"Valider"}
                       onClick={() => handleSubmit()}/>
            </PopupFooter>
        </Popup>
    );
}