import React, { useEffect, useState } from 'react';
import moment from "moment";

import {Grid, Box, useTheme } from "@mui/material";

import ApiClient from "../../service/ApiClient";
import { Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle } from "../../component/Popup/Popup";
import AddUpdateEventPopup from "./AddUpdateEvenementPopup";
import ReactGA from "react-ga4";

export default function Evenement(props) {
    const theme = useTheme();
    const [evenements, setEvenements] = useState(null);
    const [actualEvent, setActualEvent] = useState(null);
    const [actualId, setActualId] = useState(null);
    const [showPopup, setShowPopup] = useState("");
    const [confirmPopup, setConfirmPopup] = useState(false);

    const styles = {
        addEvent: {
            position: "fixed",
            bottom: "75px",
            right: "0px",
            alignItems: "center",
            display: "block",
            '& button': {
                backgroundColor: "transparent",
                border: "none"
            },
            '& i': {
                color: theme.palette.colors.green,
                fontSize: "60px"
            }
        },
        eventImageContainer: {
            cursor: "pointer",
            height: "350px",
            margin: "1rem",
            backgroundColor: theme.palette.colors.whiteBox,
            '&:hover': {
                background: "black",
                color: "white"
            },
        },
        eventDate: {
            backgroundColor: theme.palette.colors.green,
            color: "white",
            padding: "5px",
            fontSize: "15px",
            fontWeight: "600"
        },
        actionButton: {
            color: theme.palette.colors.green,
            border: "none",
            backgroundColor: "rgba(255, 255, 128, 0)",
            paddingTop: "5px",
            '&:hover': {
                color: "white"
            },
        },
        event: {
            overflow: "hidden",
            height: "150px",
            fontSize: '1rem'
        },
    };

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        (async () => {
            try {
                await loadData();
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    async function loadData() {
        let result = await ApiClient.instance.getAllEvenements();
        setEvenements(result.data);
    }

    function closePopup(type) {
        if (type === "PopupModify")
            setActualEvent(null);
        else
            setShowPopup(false);
    }

    async function addEvenement(evenement) {
        try {
            evenement.date = new moment(evenement.date).format("YYYY-MM-DD HH:mm:ss");
            evenement.year = new moment(evenement.date).format("YYYY").toString();
            await ApiClient.instance.postEvenement(evenement);
            props.createNotification("success", {title: "Succès", message: "Evenement créé", timeout: 3000});
            closePopup();
            await loadData();
        } catch (e) {
            props.createNotification("error", {title: "Erreur", message: "Evenement non créé", timeout: 3000});
            console.log(e);
        }
    }

    async function updateEvenement(data) {
        try {
            await ApiClient.instance.updateEvent(data);
            props.createNotification("success", {title: "Succès", message: "Evenement mis à jour", timeout: 3000});
            setShowPopup("");
            await loadData();
        } catch (e) {
            props.createNotification("error", {title: "Erreur", message: "Evenement non mis à jour", timeout: 3000});
            console.log(e);
        }
    }

    function buildEventImage(data) {
        let encodedUri = encodeURI(process.env.REACT_APP_API_URL + data.picture).replaceAll("'", "\\'").replaceAll("(", "\\(").replaceAll(")", "\\)");
        let date = moment(data.dateEnd).format("D/MM/YY");
        return (
            <Box sx={styles.eventImageContainer} style={{
                background: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(" + encodedUri + ")", backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat'}}>
                <Grid container direction={"column"} alignItems={"center"} justifyContent={"space-between"} style={{height: "350px"}}>
                    <Grid container item direction={"row"} justifyContent={"space-between"}>
                        <Grid item><span style={styles.eventDate}>{date}</span></Grid>
                        <Grid item>
                            <button style={styles.actionButton}
                                    onClick={(event) => {
                                        setConfirmPopup(true);
                                        setActualId(data.id);
                                        event.stopPropagation();
                                    }}><i className="material-icons">delete</i></button>
                            <button style={styles.actionButton}
                                    onClick={(event) => {
                                        setActualEvent(data);
                                        setShowPopup("update");
                                        event.stopPropagation();
                                    }}><i className="material-icons">edit</i></button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    function buildRow(nbEventPerRow) {
        let row = [];
        let tabEvent = [];
        for (let i = 0; i < evenements.length; i++) {
            for (let count = 0; count < nbEventPerRow &&  i < evenements.length; count++) {
                tabEvent.push(<Grid item xs key={evenements[i].Id}>{buildEventImage(evenements[i], nbEventPerRow, i)}</Grid>);
                i++
                if (i === evenements.length && count + 1 !== nbEventPerRow && nbEventPerRow !== 1) {
                    tabEvent.push(<Grid item xs key={-1}/>);
                }
            }
            row.push(<Grid container item direction={"row"} justifyContent={"space-between"} key={i}>{tabEvent}</Grid>);
            tabEvent = [];
            i--;
        }
        return (<Grid container direction={"column"}>{row}</Grid>);
    }

    if (evenements === null)
        return (<Box/>)
    return (<Grid container justifyContent={"center"} alignItems={"stretch"} direction={"column"}>
        <Grid item>
            {buildRow((window.innerWidth >= 768 ? 2 : 1))}
        </Grid>
        {showPopup === "create" && <AddUpdateEventPopup close={() => setShowPopup("")} submit={addEvenement} evenement={null}/>}
        {showPopup === "update" && <AddUpdateEventPopup close={() => setShowPopup("")} submit={updateEvenement} evenement={actualEvent}/>}
        {confirmPopup &&
        <Popup close={() => {
            setConfirmPopup(false);
        }}>
            <PopupHeader>
                <PopupTitle>Supprimer un evenement</PopupTitle>
            </PopupHeader>
            <PopupBody>
                <p>Voulez-vous vraiment supprimer cet evenement ?</p>
            </PopupBody>
            <PopupFooter dismiss={"Non"} close={() => {
                setConfirmPopup(false);
            }}>
                <button className={"btn btn-primary"} onClick={async() => {
                    try {
                        await ApiClient.instance.deleteEvenement(actualId);
                        props.createNotification("success", {title: "Succès", message: "Evenement supprimé", timeout: 3000});
                        setConfirmPopup(false);
                        await loadData();
                    } catch (e) {
                        props.createNotification("error", {title: "Erreur", message: "Evenement non supprimé", timeout: 3000});
                        console.log(e);
                    }
                }}>Oui
                </button>
            </PopupFooter>
        </Popup>}
        <Box sx={styles.addEvent}>
            <button className={"no-button"} onClick={() => {
                setShowPopup("create");
            }}>
                <i className="material-icons">add_circle</i>
            </button>
        </Box>
    </Grid>)
}