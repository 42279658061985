import React, { useState } from "react";
import 'moment/locale/fr';
import { Input, Grid, FormControl, FormControlLabel, Checkbox, useTheme, TextField } from "@mui/material";
import { DayPicker } from "react-day-picker";
import fr from  'date-fns/locale/fr';
import 'react-day-picker/dist/style.css';
import moment from "moment";

import { Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle } from "../../component/Popup/Popup";

export default function AddUpdateEventPopup(props) {
    const theme = useTheme();
    const [evenement, setEvenement] = useState(props.evenement ? {id: props.evenement.id, picture: props.evenement.picture, saleLink: props.evenement.saleLink === 'undefined' ? "" : props.evenement.saleLink, dateEnd: new Date(props.evenement.dateEnd), saleEnd: new Date(props.evenement.saleEnd)} : {dateEnd: new Date(), saleEnd: new Date()})
    const [error, setError] = useState(false);
    const [isInputDefault, setIsInputDefault] = useState(true);
    const [selectedDay, setSelectedDay] = useState(props.evenement ? new Date(props.evenement.dateEnd) : new Date());
    const [selectedDaySale, setSelectedDaySale] = useState(props.evenement ? new Date(props.evenement.saleEnd) : new Date());
    const [isSale, setIsSale] = useState(props.evenement ? props.evenement.saleLink !== 'undefined' : false);

    function handleAddSubmit() {
        if (evenement.picture == null || evenement.dateEnd == null || (isSale && (evenement.saleEnd == null || evenement.saleLink == null))) {
            setError(true);
            return;
        }
        let tmp = evenement;
        if (!isSale) {
            tmp.saleLink = 'undefined';
        }
        tmp.dateEnd = moment(selectedDay).format("YYYY-MM-DD");
        tmp.saleEnd = moment(selectedDaySale).format("YYYY-MM-DD");
        props.submit(tmp);
    }

    function handleUpdateSubmit() {
        if (evenement.dateEnd == null || evenement.picture == null || (isSale && (evenement.saleEnd == null || evenement.saleLink == null))) {
            setError(true);
            return;
        }
        let tmp = evenement;
        if (!isSale) {
            tmp.saleLink = 'undefined';
        }
        tmp.dateEnd = moment(selectedDay).format("YYYY-MM-DD");
        tmp.saleEnd = moment(selectedDaySale).format("YYYY-MM-DD");
        props.submit(tmp);
    }

    function buildPicture() {
        let encodedUri = encodeURI(process.env.REACT_APP_API_URL + evenement.picture);
        if (props.evenement && isInputDefault)
            return (<img alt={"evenement"} src={encodedUri} style={{width: "100%"}}/>);
        else
            return (<img alt={"evenement"} src={URL.createObjectURL(evenement.picture)} style={{width: "100%"}}/>);
    }

    return (
        <Popup size={"large"} close={props.close}>
            <PopupHeader>
                <PopupTitle>{props.evenement ? "Modifier un évenement" : "Créer un nouvel évenement"}</PopupTitle>
            </PopupHeader>

            <PopupBody>
                <Grid container direction={"column"} justifyContent="center" alignItems={"center"}>
                    <Grid container item>
                        <Grid container item direction={"column"} xs={6}>
                            <Grid item> <label className={"text-left"}>Date de l'évenement<i
                                className={"text-danger"}> *</i></label>
                            </Grid>
                            <Grid item>
                                <DayPicker className={"date-picker"} mode={"single"} selected={selectedDay} onSelect={setSelectedDay}
                                   locale={fr}/>
                            </Grid>
                        </Grid>

                        <Grid container item={"column"} xs={6}>
                            <Grid item> <label className={"text-left"}>Affiche / Photo<i
                                className={"text-danger"}> *</i></label>
                            </Grid>
                            <Grid item>
                                <FormControl>
                                    <Input type={"file"} accept=".png, .jpg"
                                        onChange={(e) => {
                                            let tmp = evenement;
                                            tmp.picture = e.target.files[0];
                                            setIsInputDefault(false);
                                            setEvenement({...evenement, tmp});
                                        }}
                                        id="input-with-icon-adornment"
                                    />
                                </FormControl>
                            </Grid>
                            { evenement.picture !== undefined && evenement.picture.length !== 0 &&
                            <Grid item container justifyContent={"center"}>
                                <Grid item xs={6}>
                                    {buildPicture()}
                                </Grid>
                            </Grid>
                            }
                        </Grid>
                    </Grid>

                    <Grid container item>
                        <Grid item xs={12} sx={{borderTop: "1px solid" + theme.palette.colors.greyBorder}}>
                            <FormControlLabel label="Vente en ligne ?" control={
                                <Checkbox checked={isSale}  inputProps={{ 'aria-label': 'controlled', label: "Vente en ligne ?" }} onChange={(event) => {
                                setIsSale(event.target.checked);
                                }} />}
                            />
                        </Grid>
                    </Grid>

                    {isSale &&
                    <Grid container item>
                        <Grid item xs={6}>
                        <TextField inputProps={{min: 0, style: { textAlign: 'center' }}} type={"text"} label={"Lien de la vente"} defaultValue={evenement.saleLink} onChange={(event) => {
                        let tmp = evenement;
                        tmp.saleLink = event.target.value;
                        setEvenement({...evenement, tmp});
                    }}/>
                        </Grid>

                        <Grid item xs={6}>
                            <Grid container item direction={"column"} xs={6}>
                            <Grid item> <label className={"text-left"}>Date de la fin de vente<i
                                className={"text-danger"}> *</i></label>
                            </Grid>
                            <Grid item>
                                <DayPicker className={"date-picker"} mode={"single"} selected={selectedDaySale} onSelect={setSelectedDaySale}
                                   locale={fr}/>
                            </Grid>
                        </Grid>
                        </Grid>
                    </Grid>
                    }
                </Grid>
                {error && props.evenement == null &&<small className={"text-danger col-12"}>Veuillez remplir tous les champs</small>}
            </PopupBody>

            <PopupFooter dismiss={"Annuler"} close={props.close}>
                <input className={"btn btn-primary"} type={"submit"} value={"Valider"}
                       onClick={() => props.evenement ? handleUpdateSubmit() : handleAddSubmit()}/>
            </PopupFooter>
        </Popup>
    );
}