import React from "react";
import {Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle} from "../../component/Popup/Popup";

export default class UpdatePartenairePopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            partner: {
                Id: props.partner.Id,
                Title: props.partner.Title,
                Site: props.partner.Site,
                picture: props.partner.picture,
            }

        };
    }

    handleSubmit = () => {
        if (this.state.partner.Title === "")
            return;
        this.props.modify(this.state.partner.Id, this.state.partner);
    };

    render() {
        return (
            <Popup size={"large"} close={() => this.props.close("PopupModify")}>
                <PopupHeader>
                    <PopupTitle>Modifier un partenaire</PopupTitle>
                </PopupHeader>

                <PopupBody>
                    <div className={"form-container"}>
                        <label className={"text-left"}>Nom du partenaire<i
                            className={"text-danger"}> *</i></label>
                        <input className="form-control" type={"text"} placeholder={""}
                               value={this.state.partner.Title} onChange={(event) => {
                            let partner = this.state.partner;
                            partner.Title = event.target.value;
                            this.setState({partner: partner});
                        }}/>
                        {!this.state.partner.Title &&
                        <small className={"text-danger col-12"}>Ce champ est obligatoire</small>}
                    </div>

                    <div className={"form-container"}>
                        <label className={"text-left"}>Site du partenaire</label>
                        <input className="form-control" type={"text"} placeholder={""}
                               value={this.state.partner.Site} onChange={(event) => {
                            let partner = this.state.partner;
                            partner.Site = event.target.value;
                            this.setState({partner: partner});
                        }}/>
                    </div>


                    {/* <div className={"w-100 text-center row"}>
                        <label className={"w-100 col-12"}>Image</label>
                        <input accept=".png, .jpg" type={"file"} multiple className={"input-image col"}
                               onChange={(event) => {
                                   var article = this.state.article;
                                   for (let i = 0; i < event.target.files.length; i++) {
                                       article.picture.push({
                                           pictureID: i,
                                           file: event.target.files[i],
                                           pictureTitle: ""
                                       });
                                   }
                                   this.setState({article: article});
                               }}/>
                    </div>

                    {this.state.article.picture.length !== 0 &&
                    this.buildImagePreview()
                    }*/}
                </PopupBody>

                <PopupFooter dismiss={"Annuler"} close={() => this.props.close("PopupModify")}>
                    <input className={"btn btn-primary"} type={"submit"} value={"Valider"}
                           onClick={() => this.handleSubmit()}/>
                </PopupFooter>
            </Popup>
        );
    }
}