import React, { useEffect, useState } from 'react';
import 'moment/locale/fr';
import { Grid, Typography, Button, DialogTitle, DialogContent, Dialog, DialogContentText, DialogActions, useTheme, Tooltip } from "@mui/material";

import "../../assets/Classement.scss"
import ApiClient from "../../service/ApiClient";
import Spinner from "../../component/Spinner/Spinner";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function ClassementMonProno(props) {
    const theme = useTheme();
    const [rank, setRank] = useState([]);
    const [lastProno, setLastProno] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showNews, setShowNews] = useState(!localStorage.getItem("devNewsProno290624"));

    const styles = {
        closeButton: {
            color: theme.palette.colors.green
        },
    }

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                await loadRank();
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    useEffect(() => {
            (async () => {
                try {
                    setSelectedUserId(null);
                    setLastProno([]);
                    setIsLoading(true);
                    await loadRank();
                    setIsLoading(false);
                } catch (error) {
                    console.log(error);
                }
            })();
    }, [props.actualSeason]);

    useEffect(() => {
}, [selectedUserId]);

    async function loadRank() {
        try {
            let result= await ApiClient.instance.getRankProno(props.actualSeason);
            setRank(result.data);
        } catch (error) {
            console.log(error);
        }
    }

    async function showLastProno(userId) {
        try {
            if (userId === selectedUserId) {
                setSelectedUserId(null);
                setLastProno([]);
            } else {
                let result= await ApiClient.instance.getLastProno(userId, props.actualSeason);
                setSelectedUserId(userId);
                setLastProno(result.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    function handleClose() {
        setShowNews(false);
        localStorage.setItem("devNewsProno290624", true);
    }

    function buildLastMatch() {
        let row = [];

        for (let i = 0; i < lastProno.length; i++) {
            let encodedUriTeam1 = encodeURI(process.env.REACT_APP_API_URL + lastProno[i].team1.logo).replaceAll("'", "\\'");
            let encodedUriTeam2 = encodeURI(process.env.REACT_APP_API_URL + lastProno[i].team2.logo).replaceAll("'", "\\'");
            row.push(<Grid container item alignItems={"center"} justifyContent={"center"} sx={{marginBottom: "1rem"}}>
                <Grid container item justifyContent={"flex-end"} xs={4}>
                    <Grid item sx={{marginRight: "1rem"}}>
                        <Typography variant={"p"}>{lastProno[i].team1.name}</Typography>
                    </Grid>

                    <Grid item xs={1}  style={{
                            background: "url(" + encodedUriTeam1 + ")", backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat', height: "25px"}}/>
                </Grid>
                

                <Grid container item direction={"column"} xs={2}>
                    <Grid item><Typography variant={"p"}>Pronostic</Typography></Grid>
                    <Grid item><Typography variant={"p"}>{lastProno[i].team1_prono} - {lastProno[i].team2_prono}</Typography></Grid>
                </Grid>

                <Grid container item justifyContent={"flex-start"} xs={4}>
                    <Grid style={{
                            background: "url(" + encodedUriTeam2 + ")", backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat', height: "25px"}} item xs={1}/>

                    <Grid item sx={{marginLeft: "1rem"}}>
                        <Typography variant={"p"}>{lastProno[i].team2.name}</Typography>
                    </Grid>
                </Grid>

                { lastProno[i].usedBonus !== null &&
                
                <Grid item sx={{marginRight: "1rem"}} xs={1}>
                    <Tooltip title={lastProno[i].bonusName}>
                        <img style={{width:"25px", height: "25px"}} src={process.env.REACT_APP_API_URL + "/icon_bonus/" + lastProno[i].bonusPicture} alt="bonus"/>
                    </Tooltip>
                </Grid>
                }

                { lastProno[i].usedBonus === null &&
                    <Grid item sx={{marginRight: "1rem"}} xs={1}/>
                }
            </Grid>);
        }
        return row;
    }

    function buildHeadRank() {
        return (<Grid className={"monProno-classement-header"} container item justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={2}>Rang</Grid>
            <Grid item xs sx={{textAlign: "left"}}>Joueur</Grid>
            <Grid item xs={1}>J.</Grid>
            <Grid item xs={3}>Pts.</Grid>
        </Grid>);
    }

    function buildRowRank() {
        let rankTable = [];
        for (let i = 0; i < rank.length; i++){
            rankTable.push(
                <Grid container item direction={"column"} className={"rank-row "}>
                <Grid container item justifyContent={"center"} alignItems={"center"} className={ props.user?.Pseudo !== undefined && rank[i].User.Pseudo === props.user?.Pseudo ? "classement-user" : ""} key={i} onClick={() => showLastProno(rank[i].User.Id)}>
                    <Grid item xs={2}>
                        <Grid container justifyContent={"center"} alignItems={"center"} style={{height: "50px"}}>
                            <Grid className={rank[i].EvoRank === 0 ? "rank-evo-null" : rank[i].EvoRank < 0 ? "rank-evo-loose" : "rank-evo-win"} container item
                                  direction={"column"} justifyContent={"center"} alignItems={"center"} xs={4} sm={3}>
                                {rank[i].EvoRank > 0 && <Grid item><ArrowDropUpIcon/></Grid>}
                                {rank[i].EvoRank === 0 ? "" : rank[i].EvoRank < 0 ? <Grid item>{rank[i].EvoRank}</Grid> :
                                    <Grid item>{"+" + rank[i].EvoRank}</Grid>}
                                {rank[i].EvoRank < 0 && <Grid item><ArrowDropDownIcon/></Grid>}
                                {rank[i].EvoRank === 0 && <Grid item>=</Grid>}
                            </Grid>
                            <Grid className={"rank-nb"} item xs={5} md={3}>{rank[i].Rank}.</Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs>
                        <Grid item><span style={{height: "100%"}}>{rank[i].User.Pseudo}</span></Grid>
                    </Grid>

                    <Grid item xs={1}>{rank[i].J}</Grid>
                    
                    <Grid item xs={3}>
                    <Grid container justifyContent={"space-evenly"} style={{position: "relative"}}>
                            <Grid item>
                                {rank[i].Pts}
                            </Grid>
                            { rank[i].EvoPts !== 0 &&
                                <Grid item style={{position: "absolute", right: "5px"}}>{"(+" + rank[i].EvoPts + ")"}</Grid> }
                        </Grid>
                    </Grid>
                </Grid>
                { selectedUserId === rank[i].User.Id &&
                <Grid container item>
                    <Grid container item direction={"column"} justifyContent={"center"} alignItems={"center"}>
                        {buildLastMatch()}
                    </Grid>
                </Grid>
                }
                </Grid>)
        }
        return rankTable
    }

    if (isLoading) {
        return (<Grid container item justifyContent={"center"} alignItems={"center"}>
            <Grid item>
                <Spinner/>
            </Grid>
        </Grid>);
    } else {
        return (<Grid className={"monProno-classement-table"} container item direction={"column"} xs={12} lg={8} style={{marginTop: "1rem"}}>
                {buildHeadRank()}
                {buildRowRank()}
                <Dialog
        open={showNews}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Le jeu de pronostic évolue !"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Une nouvelle fonctionnalité a été ajouté sur le jeu des pronostics.<br/><br/>
            Vous pouvez dorénavant utiliser des bonus sur vos pronostics, mais attention chaque bonus a un nombre limité d'utilisations par saison.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus sx={styles.closeButton}>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
        </Grid>);
    }
}
