import React, { useState } from "react";
import { TextField  } from "@mui/material";
import { styled } from "@mui/system";

import { Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle } from "../../component/Popup/Popup";

export default function AddPlayerPopup(props) {
    const [player, setPlayer] = useState({Name: '', Goal: '', GoalCup: ''});

    function handleSubmit() {
        if (player.Name === '' || (player.Goal === '' && player.GoalCup === ''))
            return;
        if (player.GoalCup === '')
            player.GoalCup = 0;
        if (player.Goal === '')
            player.Goal = 0;
        props.add(player);
    }

    return (
        <Popup size={"large"} close={props.close}>
            <PopupHeader>
                <PopupTitle>Créer un nouveau joueur</PopupTitle>
            </PopupHeader>

            <PopupBody>
                <div className={"row justify-content-center"}>
                    <TextField label={"Nom du joueur"} onChange={(event) => {
                        let tmp = player;
                        tmp.Name = event.target.value;
                        setPlayer(tmp);
                    }}/>

                    <TextField className={"ml-4"} type={"number"} label={"But en championnat"} onChange={(event) => {
                        let tmp = player;
                        tmp.Goal = parseInt(event.target.value);
                        setPlayer(tmp);
                    }}/>

                    <TextField className={"ml-4"} type={"number"} label={"But en coupe"} onChange={(event) => {
                        let tmp = player;
                        tmp.GoalCup = parseInt(event.target.value);
                        setPlayer(tmp);
                    }}/>
                </div>
            </PopupBody>

            <PopupFooter dismiss={"Annuler"} close={props.close}>
                <input className={"btn btn-primary"} type={"submit"} value={"Créer"}
                       onClick={() => handleSubmit()}/>
            </PopupFooter>
        </Popup>
    );
}