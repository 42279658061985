import { Grid  } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";

import ClassementMonProno from "./ClassementMonProno";
import ApiClient from "../../service/ApiClient";
import SimpleSelect from "../../component/SelectDropDown/SimpleSelect";
import PronoMonProno from "./PronoMonProno";
import ReglementMonProno from "./ReglementMonProno";
import useQuery from "../../component/UrlQuery/UrlQuery";

import "../../assets/Monprono.scss";

export default function MonProno(props) {
    let query = useQuery();
    const [isRank, setIsRank] = useState(true);
    const [isProno, setIsProno] = useState(false);
    const [isRule, setIsRule] = useState(false);
    const [season, setSeason] = useState(null);
    const [actualSeason, setActualSeason] = useState(null);


    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        (async () => {
            try {
                if (query.get("page") === "prono") {
                    props.handleChangeActiveMenu("dropDownPronoType", "myProno")
                }
                handleActiveMenu();
                await loadData();
            } catch (error) {
                console.log(error);
            }
        })();

    }, []);

    useEffect(() => {
        handleActiveMenu();
    }, [props.dropDownPronoType]);

    async function loadData() {
        try {
            let result = await ApiClient.instance.getSeason();
            setSeason(result.data);
            for (let i = 0; i < result.data.length; i++) {
                if (result.data[i].defaultSeason) {
                    setActualSeason(result.data[i].value);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    function handleActiveMenu() {
        setIsRank(props.dropDownPronoType === "rankProno");
        setIsProno(props.dropDownPronoType === "myProno");
        setIsRule(props.dropDownPronoType === "ruleProno");
    }

    async function handleSeason(idSeason) {
        setActualSeason(idSeason);
    }

    if (actualSeason !== null) {
        return (
            <Grid className={"monProno-container"} container direction={"column"}>
                <Grid item container direction={"row"} justifyContent={props.dimensions > 960 ? "flex-start" : "flex-end"}>
                    { props.dimensions > 960 && <Grid container item xs>
                        <Grid className={"monProno-nav-item " + (isRank ? "active" : "")} item onClick={() => {
                            props.handleChangeActiveMenu("dropDownPronoType", "rankProno");
                        }}>
                            <span className={isRank ? "active" : ""}>CLASSEMENT</span>
                        </Grid>

                        <Grid className={"monProno-nav-item " + (isProno ? "active" : "")} item onClick={() => {
                            props.handleChangeActiveMenu("dropDownPronoType", "myProno");
                        }}>
                            <span className={isProno ? "active" : ""}>MES PRONOSTICS</span>
                        </Grid>
                        <Grid className={"monProno-nav-item " + (isRule ? "active" : "")} item onClick={() => {
                            props.handleChangeActiveMenu("dropDownPronoType", "ruleProno");
                        }}>
                            <span className={isRule ? "active" : ""}>REGLEMENT</span>
                        </Grid>
                    </Grid>}
                    <Grid item>
                        <SimpleSelect data={season} onChange={handleSeason}
                                      actualIndex={actualSeason} textLabel={"Saison"}/>
                    </Grid>
                </Grid>

                <Grid container item justifyContent={"center"}>
                    {isRank && <ClassementMonProno actualSeason={actualSeason} user={props.user}/>}
                    {isProno && <PronoMonProno updateUserData={props.updateUserData} actualSeason={actualSeason} createNotification={props.createNotification} isAuth={props.isAuth} user={props.user} goToRules={() => {
                        props.handleChangeActiveMenu("dropDownPronoType", "ruleProno");
                    }}/>}
                    {isRule && <ReglementMonProno/>}
                </Grid>
            </Grid>
        );
    } else {
        return (<div/>);
    }
}
