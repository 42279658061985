import React, { useState } from 'react';
import {Grid, Typography, useTheme, Link } from "@mui/material";



export default function DropdownEquipe (props) {
    const theme = useTheme();

    const [openTeamA, setOpenTeamA] = useState(false);
    const [openTeamB, setOpenTeamB] = useState(false);
    const [openTeamC, setOpenTeamC] = useState(false);
    const [actualTeam, setActualTeam] = useState(null);

    const styles = {
        container: {
         backgroundColor: "black",
         padding: "1rem 0"
        },
        navItem: {
            cursor: "pointer",
            marginTop: "0.5rem",
            borderBottom: "solid 1px white",
        },
        navSubItem: {
            cursor: "pointer",
            marginTop: "0.5rem",
            borderBottom: "solid 1px rgba(255,255,255,0.6)",
        },
        linkItem: {
            textDecoration: "none!important",
            color: "white",
            paddingLeft: "3rem!important",
            '&:hover': {
                color: theme.palette.colors.green + "!important",
            },

        },
        linkSubItem: {
            width: "100%",
            display: "block",
            textDecoration: "none!important",
            color: "white",
            paddingLeft: "3rem!important",
            '&:hover': {
                color: theme.palette.colors.green + "!important",
            },
        }
    };

    function buildTitleCategory() {
        let column = [];

        props.categories.forEach(function(item, i) {
           column.push(
            <Grid item sx={styles.navItem} onMouseEnter={() => setActualTeam(i)} onMouseLeave={() => setActualTeam(null)} key={i}>
                <Typography className={actualTeam === i && !openTeamA && !openTeamB ? "nav-item-active" : ""} sx={styles.linkItem} variant={"h5"}>{item.name}</Typography>
            </Grid>
           );
        });
        return (column);
    }

    return (
        <Grid container item justifyContent={"center"} xs={6} md={8} sx={styles.container} onMouseEnter={props.showDropdown} onMouseLeave={props.hideDropdown}>
            <Grid container item direction={"column"} sx={styles.column} xs={5}>
                <Grid item sx={styles.navItem} onMouseEnter={() => setOpenTeamA(true)} onMouseLeave={() => setOpenTeamA(false)}>
                    <Typography className={openTeamA ? "nav-item-active" : ""} sx={styles.linkItem} variant={"h5"}>Seniors A</Typography>
                </Grid>

                <Grid item sx={styles.navItem} onMouseEnter={() => setOpenTeamB(true)} onMouseLeave={() => setOpenTeamB(false)}>
                    <Typography className={openTeamB ? "nav-item-active" : ""} sx={styles.linkItem} variant={"h5"}>Seniors B</Typography>
                </Grid>

                <Grid item sx={styles.navItem} onMouseEnter={() => setOpenTeamC(true)} onMouseLeave={() => setOpenTeamC(false)}>
                    <Typography className={openTeamC ? "nav-item-active" : ""} sx={styles.linkItem} variant={"h5"}>Seniors C</Typography>
                </Grid>

                {props.categories !== null && buildTitleCategory()}
            </Grid>
            <Grid container item direction={"column"} xs={5}>
                {openTeamA && 
                    <Grid container item direction={"column"} xs onMouseEnter={() => setOpenTeamA(true)} onMouseLeave={() => setOpenTeamA(false)}>
                        <Grid container item sx={{padding: "10px 0 0 10px"}}>
                            <Grid item sx={styles.navSubItem} xs>
                            {props.buildSubLink("Calendrier / Résultats", "/calendrier/seniorsA", true)}
                            </Grid>
                        </Grid>

                        <Grid container item sx={{padding: "0 10px"}}>
                            <Grid item sx={styles.navSubItem} xs>
                            {props.buildSubLink("Classement", "/classement/general/seniorsA", true)}
                            </Grid>
                        </Grid>
                    </Grid>
                }

                {openTeamB && 
                <Grid container item direction={"column"} xs onMouseEnter={() => setOpenTeamB(true)} onMouseLeave={() => setOpenTeamB(false)}>
                    <Grid container item sx={{padding: "10px 0 0 10px"}}>
                        <Grid item sx={styles.navSubItem} xs>
                        {props.buildSubLink("Calendrier / Résultats", "/calendrier/seniorsB", true)}
                        </Grid>
                    </Grid>

                    <Grid container item sx={{padding: "0 10px"}}>
                        <Grid item sx={styles.navSubItem} xs>
                        {props.buildSubLink("Classement", "/classement/general/seniorsB", true)}
                        </Grid>
                    </Grid>
                </Grid>
                }

                {openTeamC && 
                    <Grid container item direction={"column"} xs onMouseEnter={() => setOpenTeamC(true)} onMouseLeave={() => setOpenTeamC(false)}>
                        <Grid container item sx={{padding: "10px 0 0 10px"}}>
                            <Grid item sx={styles.navSubItem} xs>
                            {props.buildSubLink("Calendrier / Résultats", "/calendrier/seniorsC", true)}
                            </Grid>
                        </Grid>

                        <Grid container item sx={{padding: "0 10px"}}>
                            <Grid item sx={styles.navSubItem} xs>
                            {props.buildSubLink("Classement", "/classement/general/seniorsC", true)}
                            </Grid>
                        </Grid>
                    </Grid>
                }

                {actualTeam !== null && !openTeamA && !openTeamB && !openTeamC &&
                <Grid container item direction={"column"} xs onMouseEnter={() => setActualTeam(actualTeam)} onMouseLeave={() => setActualTeam(null)}>
                    <Grid container item sx={{padding: "10px 0 0 10px"}}>
                        <Grid item sx={styles.navSubItem} xs>
                            {props.buildSubLink("Calendrier / Résultats", props.categories[actualTeam].calendarLink, true, true)}
                        </Grid>
                    </Grid>

                    <Grid container item sx={{padding: "0 10px"}}>
                        <Grid item sx={styles.navSubItem} xs>
                        {props.buildSubLink("Classement", props.categories[actualTeam].rankLink, true, true)}
                        </Grid>
                    </Grid>
                </Grid>
                }
            </Grid>
        </Grid>
    )
}