import {Grid, useTheme } from "@mui/material";
import React from "react";



export default function MentionsLegales() {
    const theme = useTheme();
    const styles = {
        mentionContainer: {
            backgroundColor: theme.palette.colors.whiteBox,
            padding: "1rem"
        },
        mentionLink: {
            color: theme.palette.colors.green
        }
    };

    return (<Grid container justifyContent={"center"}>
        <Grid item sx={styles.mentionContainer} xs={12}>
            <h1>Politique de confidentialité</h1>
            <p>Date d'entrée en vigueur: 04-08-2022</p>
            <p>Mise à jour le: 04-08-2022</p>
            <p>
                Cette Politique de confidentialité explicite les politiques de US Méné Bré Louargat
                relatives à la collecte et l'utilisation des informations que nous recueillons lorsque vous accédez
                à https://www.usmenebre.fr/ (le «Service»). Cette Politique de confidentialité décrit vos droits en
                matière de confidentialité et la façon
                dont vous êtes protégé par les lois sur la confidentialité. </p>
            <p>
                En utilisant notre Service, vous consentez à la collecte et à l'utilisation de vos
                informations conformément à la présente Politique de confidentialité. Veuillez ne pas accéder à notre
                Service ou
                l'utiliser si vous ne consentez pas à la collecte et à l'utilisation de vos informations comme indiqué
                dans la présente
                Politique de confidentialité.</p>
            <p>US Méné Bré Louargat est autorisé à modifier cette Politique de confidentialité à tout
                moment, y compris sans avis préalable. </p>
            <p>
                US Méné Bré Louargat publiera la Politique de confidentialité révisée sur le site Web
                https://www.usmenebre.fr/.</p>

            <h3>Collecte et utilisation de vos informations personnelles</h3>
            <h4>Informations que nous collectons</h4>
            <p>
                Lorsque vous utilisez notre Service, vous serez invité à nous fournir des informations
                personnelles utilisées pour vous contacter ou vous identifier. https://www.usmenebre.fr/ collecte les
                informations suivantes: </p>

            <ul>
                <li>E-mail</li>
                <li>Numéro de téléphone portable</li>
                <li>Nom</li>
                <li>Données d'utilisation</li>
            </ul>

            <p>Les données d'utilisation comprennent les éléments suivants:</p>
            <ul>
                <li>L'adresse de protocole Internet (IP) des ordinateurs accédant au site</li>
                <li>Demandes de pages Web</li>
                <li>Pages Web de renvoi</li>
                <li>Navigateur utilisé pour accéder au site</li>
                <li>Date et heure d'accès</li>
            </ul>

            <h4>Comment nous collectons les informations</h4>
            <p>
                https://www.usmenebre.fr/ collecte et reçoit des informations de votre part de la manière suivante: </p>
            <ul>
                <li>Lorsque vous remplissez un formulaire d'inscription ou soumettez autrement vos informations
                    personnelles.
                </li>
            </ul>
            <p>
                Vos informations seront stockées jusqu'à 30 jour(s) après
                que vous ayez résilié votre compte. Vos informations peuvent être conservées pendant des périodes plus
                longues pour
                l'établissement de rapports ou la tenue d'archives, conformément aux lois applicables. Les informations
                qui ne permettent
                pas de vous identifier personnellement peuvent être conservées indéfiniment. </p>

            <h4>Comment nous utilisons vos informations</h4>
            <p>
                https://www.usmenebre.fr/ peut utiliser vos informations aux fins suivantes: </p>
            <ul>
                <li><b>Fournir et maintenir notre Service,</b> ainsi que surveiller l'utilisation de notre Service.</li>
                <li><b>Gérer votre compte.</b> Vos Données personnelles peuvent permettre l'accès à de multiples
                    fonctions de notre Service mises à la disposition des utilisateurs enregistrés.
                </li>
                <li><b>Vous contacter.</b> US Méné Bré Louargat vous contactera par e-mail, téléphone, SMS ou toute
                    autre forme de communication électronique relative aux fonctions, produits, services ou mises à jour
                    de sécurité lorsque cela est nécessaire ou raisonnable.
                </li>
            </ul>


            <h4>Partage avec des tiers</h4>

            <p>Vos informations peuvent être divulguées pour d'autres raisons, notamment:</p>
            <ul>
                <li>Se conformer aux lois, règlements ou ordonnances judiciaires applicables.</li>
                <li>Répondre aux réclamations selon lesquelles votre utilisation de notre Service viole les droits de
                    tiers.
                </li>
                <li>Faire respecter les accords que vous avez conclus avec nous, y compris la présente Politique de
                    confidentialité.
                </li>
            </ul>

            <h4>Cookies</h4>
            <p>
                Les cookies sont de petits fichiers texte qui
                sont placés sur votre ordinateur par les sites Web que vous visitez. Les sites Web utilisent des cookies
                pour aider les
                utilisateurs à naviguer efficacement et à exécuter certaines fonctions. Les cookies qui sont nécessaires
                au bon
                fonctionnement du site Web peuvent être installés sans votre autorisation. Tous les autres cookies
                doivent être approuvés
                avant de pouvoir être installés dans le navigateur. </p>
            <ul>
                <li><b>Cookies strictement nécessaires.</b> Les cookies strictement nécessaires habilitent des
                    fonctionnalités de base du site Web telles que la connexion des utilisateurs et la gestion des
                    comptes. Le site Web ne peut pas être utilisé correctement sans les cookies strictement nécessaires.
                </li>
                <li><b>Cookies non classifiés.</b> Les cookies non classifiés sont des cookies qui n'appartiennent à
                    aucune autre catégorie ou qui sont en cours de catégorisation.
                </li>
                <li><b>Cookies de fonctionnalité.</b> Les cookies de fonctionnalité sont utilisés pour mémoriser les
                    informations des visiteurs sur le site Web, comme. la langue, le fuseau horaire, le contenu
                    amélioré.
                </li>
                <li><b>Cookies de ciblage.</b> Les cookies de ciblage sont utilisés pour identifier les visiteurs sur
                    différents sites Web, par exemple les partenaires de contenu, les réseaux de bannières. Ces cookies
                    peuvent être utilisés par les entreprises pour créer un profil des intérêts des visiteurs ou
                    afficher des publicités pertinentes sur d'autres sites Web.
                </li>
                <li><b>Cookies de performance.</b> Les cookies de performance sont utilisés pour voir comment les
                    visiteurs utilisent le site Web, par exemple les cookies d'analyse. Ces cookies ne peuvent pas être
                    utilisés pour identifier directement un visiteur spécifique.
                </li>
            </ul>

            <div className="cookie-report-container">
                <p>{"Vous pouvez aussi modifier vos cookies en "}
                    <a style={styles.mentionLink} href="javascript:CookieScript.instance.show()">
                        cliquant ici.
                    </a>
                </p>

            </div>

            <h4>Sécurité</h4>
            <p>
                La sécurité de vos informations nous tient à cœur. https://www.usmenebre.fr/
                utilise une série de mesures de sécurité pour empêcher l'utilisation abusive, la perte ou l'altération
                des informations
                que vous nous avez fournies. Toutefois, comme nous ne pouvons pas garantir la sécurité des informations
                que vous nous
                fournissez, vous devez accéder à notre service à vos propres risques. </p>
            <p>
                US Méné Bré Louargat n'est pas responsable de la performance des sites
                Web exploités par des tiers ou de vos interactions avec eux. Lorsque vous quittez ce site Web, nous vous
                recommandons de
                passer en revue les pratiques de confidentialité des autres sites Web avec lesquels vous interagissez et
                de déterminer
                si ces pratiques sont adéquates. </p>

            <h4>Nous contacter</h4>
            <p>Pour toute question, veuillez nous contacter par les biais suivants:</p>
            <p>Nom: US Méné Bré Louargat</p>
            <p>Adresse: 13 rue du manaty 22540 LOUARGAT</p>
            <p>E-mail: contact@usmenebre.fr</p>
            <p>Site Web: https://www.usmenebre.fr/</p>


        </Grid>
    </Grid>)
}