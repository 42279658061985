import React, {useEffect, useState} from 'react';
import { CssBaseline, Box, Grid, createTheme, ThemeProvider  } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import ApiClient from "../service/ApiClient";
import Navigation from "../scenes/Navigation/Navigation";
import LeftNavigation from "../scenes/Navigation/LeftNavigation";

import "../assets/App.scss"

import Footer from "../scenes/Footer/Footer";
import ReactGA from "react-ga4";
import ScrollToTop from "../component/ScrollToTop/ScrollToTop";
import { ClubSiteRoutes, ShopSiteRoutes } from '../router/router';
import Scrollbar from '../component/Scrollbar/Scrollbar';

const theme = createTheme({
    palette: {
        colors: {
            green: "#048f00",
            white: "#ffffff",
            black: "#000000",
            whiteBox: "#d6d6d6",
            greyBorder: "#c7c7c7",
            test: "#ff0000"
        },
        secondary: {
            main: "#048f00",
        }
    },
    typography: {
        fontFamily: [
            'Poppins',
            'Open-Sans',
            'sans-serif',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

theme.typography.h4 =  {
    fontWeight: "bold",
    marginLeft: "0.5rem",
    fontSize: '1.2rem',
    [theme.breakpoints.up('sm')]: {
        marginLeft: "1rem",
        fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
        marginLeft: "2rem",
        fontSize: '2rem',
    },
}

theme.typography.h5 =  {
    fontSize: '0.9rem',
    fontWeight: "bold",
    [theme.breakpoints.up('sm')]: {
        fontSize: '1.5rem',
        margin: "0.6rem"
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '0.66rem',
        margin: "0.5rem"
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '0.95rem',
    },
}

theme.typography.subitem =  {
    fontSize: '0.7rem',
    fontWeight: "bold",
    [theme.breakpoints.up('sm')]: {
        fontSize: '1.3rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '0.6rem',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '0.9rem',
    },
}

theme.typography.h6 =  {
    fontSize: '1.1rem',
    fontWeight: "bold",
    [theme.breakpoints.up('sm')]: {
        fontSize: '1.1rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.1rem',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '1.3rem',
    },
}

theme.typography.subtitle1 = {
    fontSize: '0.8rem',
    [theme.breakpoints.up('sm')]: {
        fontSize: '1.3rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1rem',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '1.3rem',
    },
}

theme.typography.footerTitle =  {
    fontSize: '0.8rem',
    fontWeight: "bold",
    [theme.breakpoints.up('sm')]: {
        fontSize: '0.9rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '0.9rem',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '0.95rem',
    },
}

theme.typography.p =  {
    fontSize: '0.55rem',
    [theme.breakpoints.up('sm')]: {
        fontSize: '0.7rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '0.7rem',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '0.7rem',
    },
}

ReactGA.initialize('G-12DF9G9V55');

export default function App (props) {
    const [isAuth, setIsAuth] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isAdminShop, setIsAdminShop] = useState(false);
    const [menuActive, setMenuActive] = useState(null);
    const [dropDownCalendar, setDropDownCalendar] = useState(false);
    const [dropDownMedia, setDropDownMedia] = useState(false);
    const [dropDownClub, setDropDownClub] = useState(false);
    const [dropDownCalendarType, setDropDownCalendarType] = useState(null);
    const [dropDownInfo, setDropDownInfo] = useState(false);
    const [dropDownProno, setDropDownProno] = useState(false);
    const [dropDownPronoType, setDropDownPronoType] = useState("rankProno");
    const [dropDownInfoType, setDropDownInfoType] = useState(null);
    const [user, setUser] = useState(null);
    const [isShop, setIsShop] = useState(false);
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })
    const [isLoading, setIsLoading] = useState(true);
    const [itemCartNumber, setItemCartNumber] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        getSubdomain();
        (async () => {
            try {
                let result = await ApiClient.instance.authStatus();
                setIsAuth(result.data.isAuthenticated);
                if (result.data.isAuthenticated) {
                    result.data.user.Role === "ADMIN" || result.data.user.Role === "SUPERADMIN" ? setIsAdmin(true) : setIsAdmin(false);
                    result.data.user.Role === "ADMINSHOP" ?  setIsAdminShop(true) : setIsAdminShop(false);
                    setUser(result.data.user);
                }
            } catch (error) {
                console.log(error);
            }
        })();
        setIsLoading(false);
        window.addEventListener('resize', handleResize);
        return _ => {window.removeEventListener('resize', handleResize);}

    }, []);

    function handleResize() {
        if (window.innerWidth >= 960)
            setMenuActive(false);
        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
        })
    }

    function handleChangeActiveMenu (type, action) {
        if (type === "menu") {
            setMenuActive(!menuActive);
            setDropDownCalendar(false);
            setDropDownInfo(false);
            setDropDownCalendarType(null);
            setDropDownMedia(false);
            setDropDownInfoType(null);
            setDropDownProno(false);
        } else if (type === "dropDownClub") {
            setDropDownClub(!dropDownClub);
            setDropDownMedia(false);
            setDropDownCalendar(false);
            setDropDownCalendarType(null);
            setDropDownInfo(false);
            setDropDownInfoType(null);
            setDropDownProno(false);
        } else if (type === "dropDownCalendar") {
            setDropDownCalendar(!dropDownCalendar);
            setDropDownCalendarType(null);
            setDropDownMedia(false);
            setDropDownInfo(false);
            setDropDownInfoType(null);
            setDropDownProno(false);
        } else if (type === "dropDownMedia") {
            setDropDownMedia(!dropDownMedia);
            setDropDownCalendar(false);
            setDropDownCalendarType(null);
            setDropDownInfo(false);
            setDropDownInfoType(null);
            setDropDownProno(false);
        }
        else if (type === "dropDownCalendarType") {
            setDropDownCalendarType( ((dropDownCalendarType === action) ? null : action))
        }
        else if (type === "dropDownInfo") {
            setDropDownInfo(!dropDownInfo);
            setDropDownInfoType(null);
            setDropDownMedia(false);
            setDropDownCalendar(false);
            setDropDownCalendarType(null);
            setDropDownProno(false);
        }
        else if (type === "dropDownProno") {
            setDropDownProno(!dropDownProno);
            setDropDownInfo(false);
            setDropDownInfoType(null);
            setDropDownMedia(false);
            setDropDownCalendar(false);
            setDropDownCalendarType(null);
        } else if (type === "dropDownPronoType") {
            setDropDownPronoType(action);
        } else if (type === "dropDownInfoType") {
            setDropDownInfoType(((dropDownInfoType === action) ? null : action))
        }
    }

    async function authenticate (newStayLog) {
        let result = await ApiClient.instance.authStatus();
        setUser(result.data.user);
        setIsAuth(result.data.isAuthenticated);
        result.data.user.Role === "ADMIN" || result.data.user.Role === "SUPERADMIN" ? setIsAdmin(true) : setIsAdmin(false);
        result.data.user.Role === "ADMINSHOP" ?  setIsAdminShop(true) : setIsAdminShop(false);
    }

    function signOut () {
        ApiClient.instance.logout();
        window.location.reload();
    }

    function createNotification(type, option) {
        switch (type) {
            case 'info':
                NotificationManager.info(option.message, option.timeout);
                break;
            case 'success':
                NotificationManager.success(option.message, option.title, option.timeout);
                break;
            case 'warning':
                NotificationManager.warning(option.message, option.title, option.timeout);
                break;
            case 'error':
                NotificationManager.error(option.message, option.title, option.timeout);
                break;
            default:
                NotificationManager.error(option.message, option.title, option.timeout);
                break;
        }
    }

    function getSubdomain() {
        const location = window.location.hostname;
        const locationParts = location.split(".");

        setIsShop(locationParts[0] === "boutique");
    }

    function handleCartIcon (data) {
        setItemCartNumber(data);
    }
    
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <BrowserRouter>
                <ScrollToTop />
                <Box className={"background-window" + (menuActive? " show" : "")} onClick={() => handleChangeActiveMenu("menu")}/>
                
                <Scrollbar>
                <Grid container direction={"column"} alignItems={"center"} className={"page-container"}>
                    <Grid item className={(dimensions.width > 900) ? "header md" : "header sm"} sx={{backgroundColor: theme.palette.colors.black}}>
                        <Navigation handleChangeActiveMenu={(type) => handleChangeActiveMenu(type)} menuActive={menuActive} isAdmin={isAdmin} isAuth={isAuth} isAdminShop={isAdminShop} user={user} signOut={signOut} dimensions={dimensions} isShop={isShop} itemCartNumber={itemCartNumber}/>
                    </Grid>
                    
        
                    <Grid container item justifyContent={"center"} className={dimensions.width > 900 ? "body md" : "body sm"} sx={{backgroundColor: theme.palette.colors.white}}>
                        <Grid item lg={10} sm={11} xs={12}>
                            { !isLoading && !isShop &&
                                <ClubSiteRoutes authenticate={authenticate} createNotification={createNotification} dimensions={dimensions} dropDownPronoType={dropDownPronoType} handleChangeActiveMenu={handleChangeActiveMenu} isAdmin={isAdmin} isAdminShop={isAdminShop} isAuth={isAuth} signOut={signOut} user={user} />
                            }

                        { !isLoading && isShop &&
                                <ShopSiteRoutes authenticate={authenticate} createNotification={createNotification} dimensions={dimensions} dropDownPronoType={dropDownPronoType} handleChangeActiveMenu={handleChangeActiveMenu} isAdmin={isAdmin} isAdminShop={isAdminShop} isAuth={isAuth} signOut={signOut} user={user} handleCartIcon={handleCartIcon}/>
                            }
                        </Grid>
                    </Grid>
                    <Grid item className={"footer"} sx={{backgroundColor: theme.palette.colors.black}}>
                        <Footer/>
                    </Grid>
                    
                </Grid>
                </Scrollbar>
                
                
                <LeftNavigation menuActive={menuActive}
                                handleChangeActiveMenu={(type, action) => handleChangeActiveMenu(type, action)}
                                dropDownCalendar={dropDownCalendar}
                                dropDownCalendarType={dropDownCalendarType}
                                dropDownInfo={dropDownInfo}
                                dropDownInfoType={dropDownInfoType}
                                dropDownProno={dropDownProno}
                                dropDownMedia={dropDownMedia}
                                dropDownClub={dropDownClub}
                                signOut={signOut}
                                isAuth={isAuth}
                                user={user}
                                isAdmin={isAdmin}
                                isAdminShop={isAdminShop}
                                isShop={isShop}
                    />
                {/*<Box className={dimensions.width > 960 ? styles.page_md : styles.page_sm}>
                    <Route exact path="/*">
                        <Route exact path="/accueil" name="home" component={Accueil}/>
                        <Redirect to="/accueil"/>
                    </Route>
                </Box>
                <Box className={styles.footer}>
                    <Footer/>
                </Box>*/}
                {/*
                <Box className={dimensions.width > 960 ? styles.page_md : styles.page_sm} overflow={"hidden"}>




                        <LeftNavigation menuActive={menuActive}
                                            handleChangeActiveMenu={(type, action) => handleChangeActiveMenu(type, action)}
                                            dropDownCalendar={dropDownCalendar}
                                            dropDownCalendarType={dropDownCalendarType}
                                            dropDownInfo={dropDownInfo}
                                            dropDownInfoType={dropDownInfoType}/>
                </Box> */}
            </BrowserRouter>
            <NotificationContainer/>
        </ThemeProvider>
    );
}
