import React, { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    Typography
 } from "@mui/material";

import ApiClient from "../../service/ApiClient";
import ReactGA from "react-ga4";

import '../../assets/Inscription.scss'
import {useParams} from "react-router-dom";

export default function UnsubscribePronoReminder(props) {
    const params = useParams();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                await ApiClient.instance.unsubscribePronoReminder(params.bearer);
                setIsLoading(false);
            } catch (e) {
                console.log(e);
            }
        })();
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    if (!isLoading) {
        return (
            <Grid container direction={"row"} justifyContent={"center"} alignItems={"center"} style={{height: "100%"}}>
                <Grid className={"register-container"} container item direction={"column"} justifyContent={"space-between"} alignItems={"center"} xs={11} md={9} lg={6}>
                    <Grid className={"register-title"} item><Typography variant={"h4"} style={{marginLeft: "0"}}>Désinscription mail</Typography></Grid>
                    <Grid className={"auth-register"} item>
                        <Typography variant={"h5"}>
                            Votre mail à bien été retiré de la liste de difusion pour les rappels de pronostics <a href={"/connexion"}>Revenir à la page de connexion</a>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    } else {
        return (<Box/>);
    }
}
